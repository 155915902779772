<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.educationcoursetemplate')"
              ></head-menu>

              <div class="row mt-2">
                <div class="ml-4" />
                <div class="mr-2" v-if="editable">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                </div>
                <div class="mr-2" v-if="editable">
                  <b-dropdown variant="primary">
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-regular fa-cog"
                        class="mr-2"
                      />
                      <em>{{ $t("global.actions") }}</em>
                    </template>
                    <b-dropdown-item @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2 text-danger"
                      />
                      {{ $t("global.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="bildungsgangKopierenModal"
                      :disabled="invalid"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-gem"
                        class="mr-2"
                      />
                      {{ $t("global.createeducationcourse") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="mr-2">
                  <button class="btn btn-primary" @click="abbrechen">
                    {{ $t("global.cancel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12" :class="{ 'col-xl-12': !showNavbar }">
          <bildungsgang-stammdaten
            :lehrgang="lehrgang"
            :isTemplate="true"
            :editable="editable"
          />
        </div>

        <div class="col-xl-12 p-0" :class="{ 'col-xl-12': !showNavbar }">
          <vorlage-promotionsstruktur
            :klassen="klassen"
            :lehrgang="lehrgang"
            :notentypen="notentypen"
            @psChanged="setPromotionsstruktur"
            @enChanged="setEndnotenstruktur"
          />
        </div>

        <!-- REITER -->

        <div v-if="lehrgangid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'template-bildungsgang',
                      params: { lehrgangid: lehrgangid, anzeige: 0 },
                    }"
                    >{{ $t("global.assignment") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <template-klassenliste
                    :lehrgang="lehrgang"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :isTemplate="true"
                    @klasseLoaded="setKlassen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="bildungsgangloeschen"
    />

    <message-box
      id="bildungsgang-kopieren-modal"
      :headerText="$t('global.copyeducationcourse')"
      :text="$t('global.doyouwanttocopyalloronlythefirstsemester')"
      :ok="true"
      :cancel="true"
      :repeat="true"
      :okText="$t('global.semesterone')"
      :repeatText="$t('global.allsemesters')"
      :cancelText="$t('global.cancel')"
      @ok="createBildungsgangOne"
      @repeat="createBildungsgangAll"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import BildungsgangStammdaten from "@/components/Bildung/Bildungsgang/Stammdaten";
import VorlagePromotionsstruktur from "@/components/Bildung/Bildungsgang/VorlagePromotionsstruktur";
import TemplateKlassenliste from "@/components/Bildung/Bildungsgang/TemplateKlassenliste";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MessageBox from "@/components/Modals/Messagebox";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "TemplateBildungsgang",
  components: {
    BildungsgangStammdaten,
    HeadMenu,
    Navbar,
    LoadingOverlay,
    LoeschenModal,
    TemplateKlassenliste,
    MessageBox,
    VorlagePromotionsstruktur,
  },
  metaInfo() {},
  mixins: [page],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      loading: false,
      klassen: [],
      lehrgang: {
        lehrgangleiter: { personName: textNotLinked },
      },
    };
  },
  computed: {
    notentypen: {
      get() {
        return this.$store.state.veranstaltung.notentypen;
      },
    },
  },
  watch: {},
  created() {
    if (this.lehrgangid) {
      Api.get("template/lehrgang/", { params: { id: this.lehrgangid } }).then(
        (response) => {
          this.initializeBildungsgang(response.data);
        }
      );
    } else this.editable = true;

    if (this.notentypen.length == 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }
  },
  mounted: function () {},
  methods: {
    setPromotionsstruktur(val) {
      this.lehrgang.promotionsstruktur = val;
    },

    setEndnotenstruktur(val) {
      this.lehrgang.endnotenstruktur = val;
    },

    abbrechen() {
      this.$router.push({ name: "template-bildungsgang-liste" });
    },
    setKlassen(val) {
      this.klassen = val;
    },

    initializeBildungsgang(lehrgang) {
      if (lehrgang) {
        this.lehrgang = lehrgang;

        if (!this.lehrgang.lehrgangleiter)
          this.lehrgang.lehrgangleiter = { personName: textNotLinked };

        if (!this.lehrgang.lehrgangstatus)
          this.lehrgang.lehrgangstatus = {
            id: this.$CONST("LEHRGANGSTATI").PLANNED,
          };

        this.editable =
          this.lehrgang.lehrgangstatus.id ==
          this.$CONST("LEHRGANGSTATI").PLANNED
            ? true
            : true;
      } else this.editable = true;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.lehrgang);

      // Relationen auf ID reduzieren

      json.lehrgangstatus = this.lehrgang.lehrgangstatus
        ? this.lehrgang.lehrgangstatus.id
        : this.$CONST("LEHRGANGSTATI").PLANNED;

      json.lehrgangleiter = this.lehrgang.lehrgangleiter.id;

      // Defaultwerte

      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").STUDIES;

      if (!this.lehrgangid) {
        Api.post("template/lehrgang/", json)
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.educationcoursesuccessfullysaved"),
            });
            this.$router.replace({
              name: "template-bildungsgang",
              params: { lehrgangid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("template/lehrgang/", json, {
          params: { id: this.lehrgang.id },
        })
          .then((response) => {
            this.initializeBildungsgang(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.educationcoursesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
    },

    bildungsgangloeschen() {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.educationcoursenotyetsaved"),
        });
      } else {
        Api.delete("template/lehrgang/", {
          params: { ids: this.lehrgangid },
        }).then(() => {
          this.$router.push({ name: "template-bildungsgang-liste" });
        });
      }
    },

    bildungsgangKopierenModal() {
      this.$bus.$emit("open-modal", "bildungsgang-kopieren-modal");
    },

    createBildungsgangOne() {
      console.log("Methode aufgerufen", this.lehrgangid);

      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noeducationcourseassigned"),
        });
      } else {
        var json = Object.assign({}, this.lehrgang);
        json.klassen = this.klassen;
        Api.post(
          "template/vontemplate/?id=" + this.lehrgangid + "&all=false",
          json
        ).then(() => {
          this.$router.push({ name: "Bildungsgangliste" });
        });
      }
    },

    createBildungsgangAll() {
      if (this.lehrgangid === null) {
        this.$notify({
          type: "warning",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noeducationcourseassigned"),
        });
      } else {
        var json = Object.assign({}, this.lehrgang);
        json.klassen = this.klassen;
        Api.post(
          "template/vontemplate/?id=" + this.lehrgangid + "&all=true",
          json
        ).then(() => {
          this.$router.push({ name: "Bildungsgangliste" });
        });
      }
    },
  },
};
</script>

<style>
</style>
