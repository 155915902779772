<template>
  <div
    class="list-group-item fade"
    :class="{
      show: shown,
      'd-none': deleteProp,
    }"
  >
    <div v-if="qualifikation">
      <div class="row">
        <div class="col-xl-1">
          <span class="clickable mr-2" @click="editQualifikation">
            <font-awesome-icon icon="fa-regular fa-pencil" />
          </span>
          {{ qualifikation.qualifikationtyp.bezeichnung }}
        </div>
        <div class="col-xl-2">
          {{ qualifikation.niveau ? qualifikation.niveau.bezeichnung : "" }}
        </div>
        <div class="col-xl-1">{{ qualifikation.datum }}</div>
        <div class="col-xl-2">{{ qualifikation.ort }}</div>
        <div class="col-xl-6">{{ qualifikation.bemerkung }}</div>
      </div>
    </div>

    <font-awesome-icon
      icon="fa-regular fa-minus"
      class="delete-position-minus"
    />

    <button
      class="btn btn-danger deletePositionButton"
      @click="deleteQualifikation"
    ></button>
  </div>
</template>

<script>
import { fadeIn } from "@/mixins/FadeIn";

export default {
  name: "Qualifikation",
  components: {},
  mixins: [fadeIn],
  props: {
    qualifikation: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      deleteProp: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editQualifikation() {
      this.$emit("updateQualifikation", this.index);
    },

    deleteQualifikation() {
      this.shown = false;
      setTimeout(() => {
        this.$emit("deleteQualifikation", this.index);
      }, 250);

      this.deleteProp = true;
    },
  },
};
</script>

<style>
</style>
