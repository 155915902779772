var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4"},[(_vm.mail.empfaenger.length > 0)?_c('container-headline',{attrs:{"headline":_vm.$t('global.receiver') + ' (' + _vm.mail.empfaenger.length + ')',"col":3}},[_c('div',{staticClass:"row mt-2 d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneGPSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2"},[(_vm.editable)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1):_vm._e()])])]):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('div',{key:_vm.receiverKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.mail.empfaenger),function(item,index){return _c('div',{key:("empfaenger-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"contextmenu":function($event){$event.preventDefault();return _vm.openReceiver(item)},"click":function($event){return _vm.selectReceiver(item)}}},[_vm._v(" "+_vm._s(item.vorname + " " + item.name)+" ")])])}),0):_vm._e(),(_vm.mail.firma.length > 0)?_c('container-headline',{attrs:{"headline":_vm.$t('global.receiver') + ' (' + _vm.mail.firma.length + ')',"col":3}},[_c('div',{staticClass:"row mt-2 d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneFirmaSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2"},[(_vm.editable)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1):_vm._e()])])]):_vm._e(),(_vm.mail.firma.length > 0)?_c('div',{key:_vm.receiverKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.mail.firma),function(item,index){return _c('div',{key:("firma-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"click":function($event){return _vm.selectReceiverFirma(item)},"contextmenu":function($event){$event.preventDefault();return _vm.openReceiverFirma(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('gpSucheModal',{attrs:{"id":_vm.modalId},on:{"confirmed":_vm.onGPResult}}):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('loeschen-modal',{attrs:{"id":"empfaenger-loeschen-modal","multiple":true},on:{"confirmed":_vm.empfaengerLoeschen}}):_vm._e(),(_vm.mail.firma.length > 0)?_c('firmaSucheModal',{attrs:{"id":_vm.modalId},on:{"confirmed":_vm.onFirmaResult}}):_vm._e(),(_vm.mail.firma.length > 0)?_c('loeschen-modal',{attrs:{"id":"empfaenger-loeschen-modal","multiple":true},on:{"confirmed":_vm.firmaLoeschen}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }