<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.receiversender')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <single-select-firma
                id="empfaengerFirma"
                :firma="mail.firma[0]"
                :label="$t('global.receivercompany')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setFirma"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="empfaengerPerson"
                :person="mail.empfaenger[0]"
                :firma="mail.firma[0]"
                :label="$t('global.receiverperson')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setEmpfaenger"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="absender"
                :person="mail.absender"
                :label="$t('global.sender')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                :rollen="filterMitarbeitende"
                @confirmed="setAbsender"
              />
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="mail.absendermail"
                    v-bind:class="{ 'border-danger': !passed }"
                    class="form-control"
                    :readonly="!editable"
                    placeholder=" "
                  />
                  <label v-bind:class="{ 'text-danger': !passed }"
                    >{{ $t("global.senderemail") }}</label
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

import store from "@/store";

export default {
  name: "MailSenderEmpfaenger",
  components: {
    ContainerHeadline,
    SingleSelectPerson,
    SingleSelectFirma,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
    };
  },
  computed: {},
  watch: {},

  created() {},

  mounted: function () {},

  methods: {
    setEmpfaenger(person) {
      this.mail.empfaenger = [person];
      this.mail.empfaengermail = person.mailadressen[0]?.bezeichnung;

      this.$emit("updated");
    },

    setFirma(firma) {
      this.mail.firma = [firma];

      //if (!this.mail.empfaenger)
      this.mail.empfaengermail = firma.mailadressen[0]?.bezeichnung;

      this.$emit("updated");
    },

    setAbsender(absender) {
      this.mail.absender = absender;

      if (absender.mailadressen.length > 0)
        this.mail.absendermail = absender.mailadressen[0]?.bezeichnung;
    },
  },
};
</script>

<style>
</style>