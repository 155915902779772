<template>
  <div class="form-group">
    <span v-if="editable" class="editIcon" v-on:click="oeffneFirmaSucheModal"
      ><font-awesome-icon icon="fa-duotone fa-pencil-alt"
    /></span>
    <span
      v-if="allowOpen && firmaId != null"
      class="openIcon"
      v-on:click="openFirma"
      ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
    /></span>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      immediate
      v-slot="{ passed }"
      ><input v-model="firmaId" type="hidden" />
      <b-form-input
        v-model="firmaName"
        v-bind:class="{ 'border-danger': !passed }"
        readonly
        placeholder=" "
      />
      <label v-bind:class="{ 'text-danger': !passed }">{{ label }}</label>
    </ValidationProvider>

    <firmaSucheModal
      :id="modalId"
      :filterRollen="rollen"
      @confirmed="onFirmaResult"
      :filterFirma="firma"
      :filterPerson="filterPerson"
      :rechnungstraeger="rechnungstraeger"
      :activeonly="activeonly"
      remove
    ></firmaSucheModal>
  </div>
</template>

<script>
import firmaSucheModal from "@/components/Geschaeftspartner/firmaSucheModal";
import { v4 as uuid } from "uuid";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "SingleSelectFirma",
  components: { firmaSucheModal },
  props: {
    id: {
      type: String,
      default: "firma",
    },
    firma: {
      type: Object,
      required: false,
      default: function () {
        return { name: textNotLinked };
      },
    },
    allowOpen: {
      type: Boolean,
      default: true,
    },
    rechnungstraeger: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    rollen: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    filterPerson: {
      type: Object,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    activeonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    modalId() {
      return "firma-modal-" + this.id + uuid();
    },
    firmaId() {
      return this.firma?.id == null ? null : this.firma.id;
    },
    firmaName() {
      return this.firma?.id == null ? textNotLinked : this.firma.name;
    },
  },
  methods: {
    oeffneFirmaSucheModal() {
      this.$bus.$emit("openFirmaModal", this.modalId);
    },

    onFirmaResult(selectedFirma) {
      if (selectedFirma) {
        this.$emit(
          "confirmed",
          selectedFirma[0] ? selectedFirma[0] : selectedFirma
        );
      }
    },

    openFirma() {
      if (this.firma.id) {
        this.$router.push({
          name: "firma",
          params: { id: this.firma.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>
