<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="2"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="lehrgang.kuerzel"
                  class="form-control"
                  tabindex="1"
                  v-bind:class="{ 'border-danger': !passed }"
                  placeholder=" "
                />

                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.shortdesignation")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="lehrgang.bezeichnung"
                  class="form-control"
                  v-bind:class="{ 'border-danger': !passed }"
                  tabindex="2"
                  placeholder=" "
                />
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.designation")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.bezeichnung_m"
                class="form-control"
                tabindex="3"
                placeholder=" "
              />
              <label>{{ $t("global.designationmale") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.bezeichnung_f"
                class="form-control"
                tabindex="4"
                placeholder=" "
              />
              <label>{{ $t("global.designationfemale") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group" :key="statuselement">
              <v-select
                :disabled="!editable"
                v-model="lehrgang.lehrgangstatus"
                label="bezeichnung"
                :options="lehrgangstati"
                eager="true"
                :tabindex="5"
                :placeholder="$t('global.status')"
              >
                <span slot="no-options">{{
                  $t("notification.taptosearch")
                }}</span>
              </v-select>
              <label>{{ $t("global.status") }}</label>
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="bildungsgangleiter"
                :person="this.lehrgang.lehrgangleiter"
                :label="$t('global.responsible')"
                :allowOpen="true"
                :editable="editable"
                :required="true"
                tabindex="6"
                @confirmed="setBildungsgangleiter"
              />
            </div>
            <div class="col-xl-3 form-group" />
            <div
              class="col-xl-3 form-group"
              v-if="!isTemplate && lehrgang.templatelehrgang"
            >
              <input
                v-model="lehrgang.templatelehrgang.bezeichnung"
                class="form-control"
                readonly
                tabindex="7"
                placeholder=" "
              />
              <label>{{ $t("global.basedontemplate") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <v-select
                  :disabled="!editable"
                  v-model="lehrgang.bildungsgangtyp"
                  label="bezeichnung"
                  :options="bildungsgangtypen"
                  eager="true"
                  :tabindex="5"
                  :placeholder="$t('global.educationcoursetype')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.educationcoursetype")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.themen"
                label="bezeichnung"
                :options="themen"
                :disabled="!editable"
                :placeholder="$t('dashboard.topic')"
                multiple
                :tabindex="12"
              >
                <span slot="no-options">{{
                  $t("notification.taptosearch")
                }}</span>
              </v-select>
              <label>{{ $t("dashboard.topic") }}</label>
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <!-- <div class="col-xl-2 form-group">
              <date-picker
                :disabled="!editable"
                class="mt-2 mb-3"
                label="Gültig ab"
                date-format="dd.mm.yy"
                :initial="lehrgang.gueltig_ab"
                placeholder=" "
                :show-icon="true"
                @update="(val) => (lehrgang.gueltig_ab = val)"
              />
            </div>
            -->
            <div class="col-xl-2 form-group">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="lehrgang.startdatum"
                placeholer=" "
                :show-icon="true"
                :tabindex="8"
                @update="(val) => (lehrgang.startdatum = val)"
              />
              <label>{{ $t("global.start") }}</label>
            </div>
            <div class="col-xl-2 form-group">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="lehrgang.enddatum"
                placeholder=" "
                :show-icon="true"
                :tabindex="9"
                @update="(val) => (lehrgang.enddatum = val)"
              />
              <label>{{ $t("global.end") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.startperiode"
                class="form-control"
                tabindex="10"
                placeholder=" "
              />
              <label>{{ $t("global.startperiod") }}</label>
            </div>
            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.anzahl_perioden"
                class="form-control"
                tabindex="11"
                placeholder=" "
              />
              <label>{{ $t("global.numberofperiods") }}</label>
            </div>
            <div class="col-xl-1 form-group" v-if="!isTemplate">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.aktuelle_periode"
                class="form-control"
                tabindex="12"
                placeholder=" "
              />
              <label>{{ $t("global.currentperiod") }}</label>
            </div>
            <div class="col-xl-1 form-group" v-if="isTemplate"></div>
            <!--
            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.parallelklasse"
                class="form-control"
                placeholder=" "
              />
              <label>Parallelklasse</label>
            </div>
            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.kuerzel_parallelklasse"
                class="form-control"
                placeholder=" "
              />
              <label>Kurzbezeichnung Parallelklasse</label>
            </div>
            -->
            <div class="col-xl-3 form-group"></div>
            <div class="col-xl-6 form-group">
              <textarea
                :readonly="!editable"
                v-model.trim="lehrgang.beschreibung"
                class="form-control"
                tabindex="13"
                placeholder=" "
              ></textarea>
              <label>{{ $t("global.designation") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "BildungsgangStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  props: {
    lehrgang: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statuselement: +new Date(),
    };
  },
  computed: {
    lehrgangstati: {
      get() {
        return this.$store.state.veranstaltung.lehrgangstati;
      },
    },
    bildungsgangtypen: {
      get() {
        return this.$store.state.veranstaltung.bildungsgangtypen;
      },
    },
    themen: {
      get() {
        return this.$store.state.veranstaltung.themen;
      },
    },
  },
  mounted: function () {
    if (this.lehrgangstati.length == 0) {
      this.$store
        .dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`)
        .then(() => {
          this.initializeData();
        });
    } else {
      this.initializeData();
    }
  },
  methods: {
    initializeData() {
      if (!this.lehrgang.lehrgangstatus) {
        let lehrgangStatusPlanung = this.$CONST("LEHRGANGSTATI").PLANNED;

        this.lehrgang.lehrgangstatus = this.lehrgangstati.find(
          ({ id }) => id == lehrgangStatusPlanung
        );
        this.statuselement += 1;
      }
    },

    setBildungsgangleiter(person) {
      this.lehrgang.lehrgangleiter = person;
    },
  },
};
</script>

<style lang="scss">
</style>
