<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.registrations') + ' (' + anmeldungCount + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neueAnmeldung"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-ticket" class="mr-2" />
              {{ $t("global.newregistration") }}
            </button>
            <button
              v-if="showUebertragen && editable"
              class="btn btn-primary mr-2"
              @click="showAnmeldungUebertragen"
            >
              <font-awesome-icon icon="fa-solid fa-sign-out-alt" class="mr-2" />
              {{ $t("global.transfer") }}
            </button>

            <button
              v-if="showRechnung && editable"
              class="btn btn-primary mr-2"
              @click="oeffneRechnungErstellenModal"
            >
              <font-awesome-icon
                icon="fa-duotone fa-file-invoice-dollar"
                class="mr-2"
              />
              {{ $t("global.createinvoice") }}
            </button>

            <b-dropdown variant="primary mr-2">
              <template slot="button-content">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                <em>{{ $t("global.output") }}</em>
              </template>
              <b-dropdown-item @click="ausgebenTeilnehmerliste">
                {{ $t("global.participantlist") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="showZertifikatButton"
                @click="showZertifikatPdfModal"
              >
                Zertifikate
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown variant="primary">
              <template slot="button-content">
                <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                {{ $t("global.communication") }}
              </template>
              <b-dropdown-item @click="neueMail">
                <font-awesome-icon
                  icon="fa-duotone fa-envelope-open-text"
                  class="mr-2"
                />
                {{ $t("global.sendemail") }}
              </b-dropdown-item>
              <b-dropdown-item @click="neueKorrespondenz">
                <font-awesome-icon
                  icon="fa-duotone fa-typewriter"
                  class="mr-2"
                />
                {{ $t("global.createletter") }}
              </b-dropdown-item>
              <b-dropdown-item @click="massenmailErstellen" v-if="!person">
                <font-awesome-icon icon="fa-regular fa-envelope" class="mr-2" />
                {{ $t("global.createmassmail") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="massenkorrespondenzErstellen"
                v-if="!person"
              >
                <font-awesome-icon
                  icon="fa-duotone fa-typewriter"
                  class="mr-2"
                />
                {{ $t("global.createserialletter") }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- massenmutation bildungsprotfolio -->
            <massenmutation-button
              class="mr-2"
              style="display: inline-flex"
              :mutatableDateFields="this.mutatableDateFields"
              :mutatableDateNames="this.mutatableDateNames"
              :mutationPfad="this.mutationPfad"
              :selectedIds="this.selectedIds"
              @mutated="refresh"
            />
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="anmeldungen"
                :fields="computedFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>

                <template
                  v-slot:cell(veranstaltung.veranstaltungstyp.bezeichnung)="row"
                >
                  <div :style="veranstaltungstypHintergrundfarbe(row.item)">
                    {{
                      row.item.veranstaltung.veranstaltungstyp
                        ? row.item.veranstaltung.veranstaltungstyp.bezeichnung
                        : "-"
                    }}
                  </div>
                </template>

                <template v-slot:cell(anmeldetyp.bezeichnung)="row">
                  <div :style="anmeldetypHintergrundfarbe(row.item)">
                    {{ row.item.anmeldetyp.bezeichnung }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="anmeldungen-loeschen-modal"
      @confirmed="anmeldungenLoeschen"
      :selectedIds="selectedIds"
    />

    <anmeldung-uebertragen-modal
      :id="AnmeldungUebertragenModalId"
      :anmeldungen="selectedIds"
      :filterTyp="filterTyp"
      :allowCopy="true"
      @copied="anmeldungGeaendert"
      @changed="anmeldungGeaendert"
    ></anmeldung-uebertragen-modal>

    <rechnung-erstellen-modal
      id="rechnung-erstellen-modal"
      ref="rechnungErstellenModal"
      :anmeldungen="selectedIds"
      :veranstaltung="veranstaltung"
      :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
      @changed="getAnmeldungen"
    />

    <pdf-layer
      id="pdf-layer-anmeldung-reiter"
      ref="pdfLayerAnmeldungReiter"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>
    <loading-overlay v-show="loading"></loading-overlay>
    <zertifikat-pdf-modal
      id="zertifikat-pdf-modal"
      :ausgabeZertifikate="ausgabeZertifikate"
      @dateChanged="updateDate"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import RechnungErstellenModal from "@/components/Rechnungslegung/Modals/RechnungErstellenModal";
import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import ZertifikatPdfModal from "../Modals/ZertifikatPDFModal.vue";

import reiter from "@/mixins/Reiter";
import dashboard from "@/mixins/Dashboard";
import page from "@/mixins/Page";
import server from "@/server";

export default {
  name: "Anmeldungsliste",
  components: {
    ContainerHeadline,
    AnmeldungUebertragenModal,
    RechnungErstellenModal,
    LoeschenModal,
    BSpinner,
    ZertifikatPdfModal,
    MassenmutationButton,
  },
  mixins: [reiter, page, dashboard],
  props: {
    veranstaltung: {
      type: String,
      required: false,
    },
    showUebertragen: {
      type: Boolean,
      default: true,
    },
    showRechnung: {
      type: Boolean,
      default: true,
    },
    person: {
      type: String,
      required: false,
    },
    filterTyp: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    mailadressen: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showZertifikatButton: false,
      page: 0,
      anzahlProPage: 200,
      sortBy: "veranstaltung.kuerzel",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      kategorieProp: this.kategorieProp,
      modus: null,
      modi: [],
      zielklasse: null,
      zielklassen: [],
      status: null,
      anmeldestatus: [],
      anmeldungen: [],
      verrechnungPosKey: 0,
      anmeldungCount: 0,
      personFields: [
        { key: "selected", label: "" },
        {
          key: "veranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "veranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusregistration"),
        },
        {
          key: "anmeldungsdatum",
          sortable: true,
          label: this.$t("global.registration"),
        },
        {
          key: "rechnungsdatum",
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        /*{
          key: "anmeldeposition",
          sortable: true,
          label: "Anmeldeposition",
        },*/
        {
          key: "anmeldetyp.bezeichnung",
          sortable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "veranstaltung.veranstaltungstyp.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },
      ],
      fields: [
        { key: "selected", label: "" },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusregistration"),
        },
        {
          key: "anmeldetyp.bezeichnung",
          sortable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "abschluss.bezeichnung",
          sortable: true,
          label: this.$t("global.degree"),
        },
        {
          key: "anmeldungsdatum",
          sortable: true,
          label: this.$t("global.registration"),
        },
        {
          key: "rechnungsdatum",
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        {
          key: "veranstaltung.veranstaltungstyp.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },
      ],
      //selected: [],
      selectedIds: [],
      AnmeldungUebertragenModalId: "anmeldung-uebertragen-modal",
      pdfPath: "",
      anmeldungObj: {},
      date: new Intl.DateTimeFormat("ch-DE").format(new Date()),
      pdfFiles: [],

      mutatableDateFields: [
        "Zugelassen am",
        "Bestanden am",
        "Zertifizierung",
        "Diplom versendet",
      ],
      mutatableDateNames: [
        "zugelassen_am",
        "bestanden_am",
        "zertifizierung",
        "diplom_versendet",
      ],
      mutationPfad: "anmeldung/",
    };
  },
  watch: {
    shown(val) {
      if (val && this.anmeldungen.length == 0) this.getAnmeldungen();
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    computedFields() {
      if (this.person) return this.personFields;
      else return this.fields;
    },
  },
  mounted() {
    //TODO: Mandantenspezifisches FrontEnd sauber loesen
    if (location.href.includes("healthspot")) {
      this.showZertifikatButton = true;
    }
    if (this.shown && this.anmeldungen.length == 0) this.getAnmeldungen();
    this.setHeaders("global", [
      "",
      "person",
      "statusregistration",
      "registrationtype",
      "degree",
      "registration",
      "invoicedate",
      "",
    ]);
    this.setHeaders("dashboard", ["", "", "", "", "", "", "", "type"]);
  },
  created() {},
  methods: {
    getAnmeldungen() {
      this.isBusy = true;

      Api.get("anmeldung/", {
        params: {
          veranstaltung: this.veranstaltung,
          person: this.person,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.anmeldungen = response.data;
          this.anmeldungCount = this.anmeldungen.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(anmeldungen) {
      if (this.berechtigungen.m_anmeldungen.read)
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: anmeldungen.id, anzeige: 0 },
        });
    },

    ausgebenTeilnehmerliste: function () {
      let ids = this.anmeldungen.map((a) => a.id);
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AL${filename}.pdf&report=/pdfgenerator/teilnehmer.htm&ids=${ids}`;
      this.$refs.pdfLayerAnmeldungReiter.show();
    },

    showZertifikatPdfModal() {
      if (this.selectedIds?.length > 0) {
        this.$bus.$emit("open-modal", "zertifikat-pdf-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.hint"),
          text: this.$t("notification.nopersonsselected"),
        });
      }
    },

    updateDate(selectedDate) {
      this.date = selectedDate;
    },

    ausgabeZertifikate() {
      this.selectedObjects = this.anmeldungen.filter((item) => item.selected);

      this.pdfFiles = [];

      if (this.selectedObjects?.length > 0) {
        this.selectedObjects.forEach((anmeldung) => {
          if (!this.anmeldungObj[anmeldung.person?.id]) {
            this.anmeldungObj[anmeldung.person?.id] = {
              personName: anmeldung.person?.personName,
              personID: anmeldung.person.id,
              anmeldungenIds: anmeldung.id,
              anzahlAnmeldungen: 1,
              veranstaltungName: anmeldung.veranstaltung.bezeichnung,
            };
          } else {
            this.anmeldungObj[anmeldung.person?.id].anmeldungenIds +=
              "," + anmeldung.id;
            this.anmeldungObj[anmeldung.person?.id].anzahlAnmeldungen++;
          }
        });

        //Momentan noch keine Begrenzung fuer Anzahl Zertifikate. Einbauen anhand von anzahlZertifikate und size anpassen, falls gefordert
        Object.values(this.anmeldungObj).forEach((entry, index) => {
          this.generatePDF(index, entry);
        });
        this.$refs.pdfLayerAnmeldungReiter.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nolecturerdeploymentselected"),
        });
      }
    },

    generatePDF(index, anmeldung) {
      let json = {
        idlist: anmeldung.anmeldungenIds,
      };
      let personID = anmeldung.personID;
      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = anmeldung?.personName?.trim();
        filename += new Intl.DateTimeFormat("ch").format(new Date());
        filename = filename.trim();
        let size = anmeldung?.personName;
        size = size.trim();

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Zertifikat" +
          filename +
          ".pdf&report=/api/pdfgenerator/zertifikat.htm&cache=" +
          cacheId +
          "&date=" +
          this.date;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: Object.entries(this.anmeldungObj).length,
            size: size,
          };

          this.pdfFiles.push(retValue);
          let zertifikatName = "Zertifikat " + anmeldung.veranstaltungName;
          Api.post("dokument/", json, {
            params: {
              id: personID,
              fileID: response.data.fileID,
              fileName: zertifikatName,
            },
          }).then(() => {});
        });
      });
    },

    veranstaltungstypHintergrundfarbe(item) {
      let veranstaltungstyp = item.veranstaltung.veranstaltungstyp;

      if (veranstaltungstyp) {
        //Fach:
        if (veranstaltungstyp.id == "174D91487D4") {
          return { backgroundColor: "#EAE8AC", textAlign: "center" };
        }
        //Bildungsgang (Semester):
        else if (veranstaltungstyp.id == "174D91487DA") {
          return { backgroundColor: "#84D0E2", textAlign: "center" };
        }
        //Kurs:
        else if (veranstaltungstyp.id == "174D91487E8") {
          return { backgroundColor: "#C3C4E2", textAlign: "center" };
        } else {
          return { backgroundColor: "#DEEDF6", textAlign: "center" };
        }
      } else {
        return { textAlign: "center" };
      }
    },

    anmeldetypHintergrundfarbe(item) {
      let anmeldetyp = item.anmeldetyp;

      if (anmeldetyp) {
        if (anmeldetyp.id == "18346A7BFB5") {
          return { backgroundColor: "#F2D585", textAlign: "center" };
        } else {
          return { textAlign: "center" };
        }
      }
    },

    neueMail() {
      //let index = this.mailadressen.findIndex((mailadresse) => )
      if (this.selectedIds && this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.oneregistrationselected"),
        });
      } else {
        let json = {
          anmeldung: this.selectedIds[0],
          inhalt: "",
          betreff: this.$t("global.emailprepared"),
          zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
        };

        if (this.veranstaltung) {
          let anmeldung = this.anmeldungen.find(
            (anmeldung) => anmeldung.id == this.selectedIds[0]
          );

          json.empfaenger = [anmeldung.person];
        } else {
          json.empfaenger = [this.person];
        }

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      }
    },

    neueKorrespondenz() {
      if (this.selectedIds && this.selectedIds.length == 0) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.oneregistrationselected"),
        });
      } else {
        let json = {
          anmeldung: this.selectedIds[0],
          inhalt: "",
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          },
          betreff: this.$t("global.correspondenceprepared"),
        };

        if (this.veranstaltung) {
          let anmeldung = this.anmeldungen.find(
            (anmeldung) => anmeldung.id == this.selectedIds[0]
          );

          json.empfaenger = [anmeldung.person.id]; // Unsauberkeit im IO
          json.person = [anmeldung.person.id];
        } else {
          json.empfaenger = [this.person]; // Unsauberkeit im IO
          json.person = [this.person];
        }

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "korrespondenz",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      }
    },

    massenkorrespondenzErstellen() {
      if (this.selectedIds.length > 0) {
        let json = {
          empfaenger: [],
          person: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter,
            datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
            ort: "",
            betreff: this.$t("global.newserialletter"),
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.anmeldungen.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        json.person = json.empfaenger;

        if (json.empfaenger.length == 0) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.oneregistrationselected"),
          });
          return;
        }

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          this.loading = true;
          Api.post("korrespondenz/empfaenger/", json, {
            params: {
              korrespondenz: response.data.id,
              isPerson: true,
              isMassCorr: true,
            },
          }).then((empfresponse) => {
            this.loading = false;
            this.$router.push({
              name: "korrespondenz",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nopersonselected"),
        });
    },

    massenmailErstellen() {
      if (this.selectedIds.length > 0) {
        let json = {
          empfaenger: [],
          isMassMail: true,
          anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Array.from(new Set(this.selectedIds)).forEach((id) => {
          json.empfaenger.push(
            this.anmeldungen.find((anmeldung) => anmeldung.id == id).person.id
          );
        });

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showAnmeldungUebertragen() {
      this.$bus.$emit("openAnmeldungUebertragenModal", this.selectedIds);
    },

    anmeldungGeaendert() {
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.registrationstransferred"),
      });
    },

    oeffneRechnungErstellenModal() {
      if (this.selectedIds.length > 0) {
        this.verrechnungPosKey++;
        this.$bus.$emit("open-modal", "rechnung-erstellen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
      }
    },

    neueAnmeldung() {
      if (this.person) {
        this.$router.push({
          name: "anmeldung.person",
          params: {
            personid: this.person,
          },
        });
      } else {
        this.$router.push({
          name: "anmeldung.veranstaltung",
          params: {
            veranstaltungid: this.veranstaltung,
          },
        });
      }

      /*let now = new Date();

      Api.post("anmeldung/", {
        veranstaltung: this.veranstaltung,
        person: this.person,
        anmeldestatus: anmeldungStatusAngemeldet,
        anmeldetyp: anmeldungTypRegulaer,
        zahlungsplan: zahlungsPlanEinmalig,
        anmeldungsdatum:
          now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear(),
        anmeldungsdatum_uhrzeit: now.getHours() + ":" + now.getMinutes(),
      }).then((response) => {
        this.$router.push({
          name: "anmeldung",
          params: {
            anmeldungid: response.data.id,
            anzeige: 0,
          },
        });
      });*/
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "anmeldungen-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },

    anmeldungenLoeschen() {
      this.isBusy = true;

      Api.delete("anmeldung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getAnmeldungen();
      });
    },

    refresh() {
      this.resetLoading(this);
    },
  },
};
</script>
