<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-notenausweisPdf">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span style="color: #c8d9e2" class="fa fa-times"></span>
          </button>
          <h4 class="modal-title">Zertifikate drucken</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-6">
            <div class="form-group">
              <ValidationProvider rules="required" immediate>
                <date-picker
                  date-format="dd.mm.yy"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => setDate(val)"
                />
                <label>Datum</label>
              </ValidationProvider>
            </div>
          </div>
          <!--
          <div class="col-xl-10">
            <div class="form-group">
              <ValidationProvider rules="required" immediate>
                <label>Bildungsgangleiter</label>
                <textarea v-model="funktion" placeholder="Bildungsgangleiter" />
              </ValidationProvider>
            </div>
          </div>
          -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            v-if="
              !showLeistungsausweis && !showVordiplomausweis && !showNotenblatt
            "
            @click="notenausweiseAusgeben"
          >
            Notenausweise anzeigen
          </button>
          <button
            type="button"
            class="btn btn-default"
            v-if="showLeistungsausweis"
            @click="leistungsausweiseAusgeben"
          >
            Leistungsausweise anzeigen
          </button>
          <button
            type="button"
            class="btn btn-default"
            v-if="showVordiplomausweis"
            @click="vordiplomausweiseAusgeben"
          >
            Vordiplomausweise anzeigen
          </button>
          <button
            type="button"
            class="btn btn-default"
            v-if="
              !showLeistungsausweis &&
              !showVordiplomausweis &&
              this.semesternummer == 6
            "
            @click="diplomeAusgeben"
          >
            Diplome anzeigen
          </button>
          <button
            type="button"
            class="btn btn-default"
            v-if="showNotenblatt"
            @click="notenblaetterAusgeben"
          >
            Notenblatt anzeigen
          </button>
          <button type="button" class="btn btn-default" @click="closeModal">
            Schliessen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/Datepicker";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

import { modal } from "@/mixins/Modal";
import store from "@/store";

export default {
  name: "NotenausweisPdfModal",
  mixins: [modal],
  components: {
    DatePicker,
  },
  store,
  props: {
    notenausweisAusgeben: {
      type: Function,
      required: false,
    },
    leistungsausweisAusgeben: {
      type: Function,
      required: false,
    },
    vordiplomausweisAusgeben: {
      type: Function,
      required: false,
    },
    diplomAusgeben: {
      type: Function,
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
    semesternummer: {
      required: false,
    },
    klassenid: {
      type: String,
      default: null,
    },
    lehrgangid: {
      type: String,
      default: null,
    },
    showLeistungsausweis: {
      type: Boolean,
      default: null,
    },
    showVordiplomausweis: {
      type: Boolean,
      default: null,
    },
    showNotenblatt: {
      type: Boolean,
      default: null,
    },
    notenblattAusgeben: {
      type: Function,
      required: false,
    },
    id: {
      type: String,
      required: false,
      default: "notenausweis-pdf-modal",
    },
  },
  data() {
    return {
      selectedDate: new Intl.DateTimeFormat("ch").format(new Date()),
      /*klasse: [],
      lehrgangLeiterId: "",
      funktion: "",
      funktionParameter: "",*/
    };
  },
  computed: {
    /*unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },*/
  },
  /*watch: {
    klassenid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        }
      },
      immediate: true,
    },
  },*/
  mounted() {
    this.$bus.$on("openNotenausweisPdfModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });

    this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },
  methods: {
    closeModal() {
      this.shown = false;
      $("#notenausweis-pdf-modal").modal("hide");
    },
    notenausweiseAusgeben() {
      //this.formatFunktion();
      this.$emit("dateChanged", this.selectedDate);
      //this.$emit("funktionChanged", this.funktionParameter);
      this.closeModal();
      this.notenausweisAusgeben();
    },
    leistungsausweiseAusgeben() {
      this.$emit("dateChanged", this.selectedDate);
      this.closeModal();
      this.leistungsausweisAusgeben();
    },
    vordiplomausweiseAusgeben() {
      this.$emit("dateChanged", this.selectedDate);
      this.closeModal();
      this.vordiplomausweisAusgeben();
    },
    diplomeAusgeben() {
      this.$emit("dateChanged", this.selectedDate);
      this.closeModal();
      this.diplomAusgeben();
    },
    notenblaetterAusgeben() {
      this.$emit("dateChanged", this.selectedDate);
      this.closeModal();
      this.notenblattAusgeben();
    },
    setDate(val) {
      this.selectedDate = val;
    },

    /*
    ladeVeranstaltung(klassenid) {
      this.loading = true;
      Api.get("veranstaltungen/", { params: { id: klassenid } })
        .then((response) => {
          this.initializeKlasse(response.data);
          this.initializeFunktion();
        })
        .finally(() => {
          this.loading = false;
        });
    },
        initializeKlasse(val) {
      this.klasse = val;
      this.lehrgangLeiterId = this.klasse.lehrgang.lehrgangleiter.id;
    },
    initializeFunktion() {
      var unterschrift = this.unterschriften.find(
        (element) => element.person.id == this.lehrgangLeiterId
      );
      this.funktion = unterschrift.funktion;
      this.funktion = this.funktion.replace("Bildungsgangleiter", "");
      this.funktion = this.funktion.replace(/(\r\n|\n|\r)/gm, "");
    },
    formatFunktion() {
      this.funktionParameter = this.funktion;
      this.funktionParameter = this.funktion.replace(/&/g, "5AMP");
    },
    */
  },
};
</script>

<style>
</style>
