<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.educationalcoursesemester')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="erstelleKlasse"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>

          <div class="mr-2">
            <b-dropdown class="mt-0" variant="primary" v-if="editable">
              <template slot="button-content">
                <font-awesome-icon icon="fa-regular fa-cog" class="mr-2" />
                <em class="mr-2">{{ $t("global.actions") }}</em>
              </template>
              <b-dropdown-item class="col-12" @click="kopiereKlasse">
                <font-awesome-icon icon="fa-duotone fa-copy" class="mr-2" />
                {{ $t("global.Copy") }}
              </b-dropdown-item>

              <b-dropdown-item class="col-12" @click="oeffneLoeschenModal">
                <font-awesome-icon icon="fa-solid fa-trash-alt" class="mr-2" />
                {{ $t("global.delete") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="klassen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="openKlasse"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="klasse-loeschen-modal"
      @confirmed="klasseloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  name: "Klassenliste",
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    lehrgang: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      klassePeriode: null,
      klassen: [],
      sortBy: "periodennummer",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      fields: [
        { key: "selected", label: "" },
        { key: "periodennummer", label: "#" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.abbreviation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designationclassperiod"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "startdatum",
          sortable: true,
          label: this.$t("global.start"),
        },
        {
          key: "enddatum",
          sortable: true,
          label: this.$t("global.end"),
        },
        {
          key: "verantwortlicher.personName",
          sortable: true,
          label: this.$t("global.responsible"),
        },
      ],
      selected: [],
      selectedIds: [],
      sortierung: null,
    };
  },
  watch: {
    shown(val) {
      if (val && this.klassen.length == 0) this.getKlassen();
    },
    lehrgang(val) {
      if (val) this.getKlassen();
    },
  },
  mounted() {
    if (this.shown && this.klassen.length == 0) this.getKlassen();
    this.$bus.$on("update-klassen-liste", () => {
      this.getKlassen();
    });
    this.setHeaders("global", [
      "",
      "",
      "abbreviation",
      "designationclassperiod",
      "status",
      "status",
      "start",
      "end",
      "responsible",
    ]);
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  methods: {
    getKlassen() {
      if (this.lehrgang.id) {
        this.isBusy = true;
        this.$emit("ladeKlassen", this.ladeFehler);

        Api.get("veranstaltungen/", {
          params: {
            lehrgang: this.lehrgang.id,
            page: this.page,
            count: this.anzahlProPage,
            veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").STUDIES,
            hfptyp: this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_HFP,
            ndstyp: this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_NDS,
          },
        })
          .then((response) => {
            //response.data.shift();
            this.klassen = response.data;
            this.$emit("klasseLoaded", this.klassen);
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
            this.$emit("ladeKlassen", this.ladeFehler);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    openKlasse(klasse) {
      this.$router.push({
        name: "klasse",
        params: { klassenid: klasse.id, anzeige: 0 },
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    erstelleKlasse() {
      if (this.isBusy) return;

      var json = {
        lehrgang: this.lehrgang.id,
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").STUDIES,
        verantwortlicher: this.lehrgang.lehrgangleiter.id,
        startdatum: this.lehrgang.startdatum,
        enddatum: this.lehrgang.enddatum,
      };

      Api.post("veranstaltungen/", json)
        .then((response) => {
          this.openKlasse(response.data);
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("global.educationsemestersuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "klasse-loeschen-modal");
    },

    kopiereKlasse() {
      if (this.selectedIds.length == 0) return;

      if (this.loading) return;
      this.loading = true;

      Api.get("veranstaltungen/", {
        params: { id: this.selectedIds[0] },
      }).then((response) => {
        response.data.bezeichnung =
          "Kopie von [" + response.data.bezeichnung + "]";

        response.data.veranstaltungsstatus =
          response.data.veranstaltungsstatus.id;
        response.data.veranstaltungstyp = response.data.veranstaltungstyp.id;
        response.data.lehrgang = response.data.lehrgang.id;
        response.data.verantwortlicher = response.data.verantwortlicher.id;

        response.data.themen = response.data.themen.map((thema) => thema.id);
        response.data.qualifikationen = response.data.qualifikationen.map(
          (qualifikation) => qualifikation.id
        );

        Api.post("veranstaltungen/", response.data).then((response) => {
          this.openKlasse(response.data);
        });
      });
    },

    klasseloeschen() {
      Api.delete("veranstaltungen/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getKlassen();
      });
    },
  },
};
</script>
