<template>
  <div>
    <container-headline
      :headline="$t('global.additionalinformation')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="firma.versand"
                  multiple
                  label="bezeichnung"
                  :options="versandlisten"
                  :reduce="(an) => an.id"
                  :disabled="!editable"
                  :tabindex="111"
                  :placeholder="$t('global.dispatch')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.dispatch") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="firma.interesse"
                  multiple
                  label="bezeichnung"
                  :options="interessen"
                  :reduce="(uf) => uf.id"
                  :disabled="!editable"
                  :tabindex="112"
                  :placeholder="$t('global.interests')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.interests") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="firma.bemerkung"
                  class="form-control"
                  placeholder=" "
                  :tabindex="113"
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.comment") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ENDE Zusatzangaben -->
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_VERSANDLISTEN } from "@/store/korrespondenz/actions.type";

export default {
  name: "Firma-Zusatzangaben",
  components: { ContainerHeadline },
  mixins: [],
  props: {
    firma: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    versandlisten: {
      get() {
        return this.$store.state.korrespondenz.versandlisten;
      },
    },
    interessen: {
      get() {
        return this.$store.state.geschaeftspartner.interessen;
      },
    },
  },
  watch: {},
  mounted: function () {
    if (this.versandlisten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_VERSANDLISTEN}`);
  },
  methods: {},
};
</script>

<style>
</style>

