<template>
  <div
    class="list-group-item fade"
    :class="{
      show: shown,
      'd-none': deleteProp,
    }"
  >
    <div v-if="adresse">
      <div class="row">
        <div class="col-xl-3">
          <span
            v-if="editable"
            class="clickable mr-2"
            @click="$emit('updateAdresse', index)"
          >
            <font-awesome-icon icon="fa-regular fa-pencil" />
          </span>
          <span
            v-if="adresse.isKorrespondenz"
            class="clickable mr-2 text-success"
          >
            <font-awesome-icon icon="fa-regular fa-house" />
          </span>
          <span
            v-if="!adresse.isKorrespondenz"
            class="clickable mr-2"
            @click="markiereKorrespondenzAdresse"
          >
            <font-awesome-icon icon="fa-regular fa-house" />
          </span>
          <span v-if="adresse.isRechnung" class="mr-4 text-success">
            <font-awesome-icon icon="fa-duotone fa-file-invoice-dollar" />
          </span>
          <span
            v-if="!adresse.isRechnung"
            class="clickable mr-4"
            @click="markiereRechnungsAdresse"
          >
            <font-awesome-icon icon="fa-duotone fa-file-invoice-dollar" />
          </span>
          <span v-if="adresse.adresse">{{ adresse.adresse.firma }}</span>
        </div>
        <div class="col-xl-2">
          <span v-if="adresse.adresse">{{ adresse.adresse.abteilung }}</span>
        </div>
        <div class="col-xl-2">
          <span v-if="adresse.adresse">{{ adresse.adresse.adresszusatz }}</span>
        </div>
        <div class="col-xl-2">
          <span v-if="adresse.adresse">{{ adresse.adresse.strasse_nr }}</span>
        </div>
        <div class="col-xl-1">
          <span v-if="adresse.adresse">{{ adresse.adresse.plz }}</span>
        </div>
        <div class="col-xl-2">
          <span v-if="adresse.adresse">{{ adresse.adresse.ort }}</span>
        </div>
      </div>
    </div>
    <font-awesome-icon
      icon="fa-regular fa-minus"
      class="delete-position-minus"
      v-if="editable"
    />
    <button
      class="btn btn-danger deletePositionButton"
      v-if="editable"
      @click="deleteAdresse"
    ></button>
  </div>
</template>

<script>
import { fadeIn } from "@/mixins/FadeIn";

export default {
  name: "GeschaeftspartnerAdresse",
  components: {},
  mixins: [fadeIn],
  props: {
    adresse: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      deleteProp: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    deleteAdresse() {
      this.shown = false;
      setTimeout(() => {
        this.$emit("deleteAdresse", this.index);
      }, 250);

      this.deleteProp = true;
    },

    markiereKorrespondenzAdresse() {
      if (this.editable) this.$emit("korrespondenzAdresse", this.index);
    },

    markiereRechnungsAdresse() {
      if (this.editable) this.$emit("rechnungsAdresse", this.index);
    },
  },
};
</script>

<style>
</style>
