<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Notenberechnung"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>

              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{ $t("global.tolist")}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline :headline="$t('global.masterdata')" :col="6"></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12 mb-3">
                  <div class="form-group">
                    <multi-select-veranstaltung
                      :veranstaltungen="notenberechnung.veranstaltung"
                      :allowOpen="true"
                      label="Klasse"
                      route="klasse"
                      :editable="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="id" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li
                v-for="(veranstaltung, index) in notenberechnung.veranstaltung"
                v-bind:key="veranstaltung.id"
                class="nav-item"
              >
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === index }"
                  :to="{
                    name: 'noten-berechnung',
                    params: { id: id, anzeige: index },
                  }"
                  >{{ veranstaltung.bezeichnung }}</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div
                v-for="(veranstaltung, index) in notenberechnung.veranstaltung"
                v-bind:key="veranstaltung.id"
              >
                <div v-show="anzeige === index">
                  <div class="col-xl-12">
                    <notenpromotionliste
                      :veranstaltung="veranstaltung.id"
                      :shown="anzeige == index ? true : false"
                      :editable="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import MultiSelectVeranstaltung from "@/components/global/MultiSelectVeranstaltung.vue";
import Notenpromotionliste from "@/components/Reiter/Notenpromotionliste.vue";

export default {
  name: "Notenberechnung",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    MultiSelectVeranstaltung,
    Notenpromotionliste,
  },
  metaInfo() {},
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      notenberechnung: { veranstaltung: [] },

      parent_notenberechnung: null,
      notenstruktur: null,
      notenstrukturen: [],
      veranstaltung: null,
      veranstaltungen: [],
      kuerzel: null,
      bezeichnung: null,
      bezeichnung_m: null,
      bezeichnung_f: null,
      minimum: null,
      maximum: null,
      notendurchschnitt: null,
      loading: false,
    };
  },
  computed: {},
  created() {},
  mounted: function () {
    if (this.id) {
      Api.get("notenberechnung/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeNotenberechnung(response.data);
        }
      );
    }
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "Notenberechnungsliste" });
    },
    initializeNotenberechnung(notenberechnung) {
      this.notenberechnung = notenberechnung;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = {
        kuerzel: this.kuerzel,
      };

      if (!this.notenberechnung) {
        Api.post("notenberechnung/", json)
          .then((response) => {
            this.initializeNotenberechnung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "Notenberechnung erfolgreich gespeichert.",
            });

            this.$router.replace({
              name: "NotenberechnungMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("notenberechnung/", json, {
          params: { id: this.notenberechnung.id },
        })
          .then((response) => {
            this.initializeNotenberechnung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "Notenberechnung erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("notenberechnung/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Notenberechnungsliste" });
      });
    },
  },
};
</script>

<style>
</style>
