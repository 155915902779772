<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-pdf">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Excel-Datei einlesen</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <div class="form-group text-center">
              <vue-2-dropzone
                id="dropzone"
                ref="dokumenteUpload"
                class="
                  dropzone-white dropzone-small
                  vue-dropzone
                  dropzone
                  dz-clickable
                "
                :options="dropzoneOptions"
                @vdropzone-success="fileUploaded"
                :key="dropzoneKey"
              ></vue-2-dropzone>
              <button class="btn btn-primary mt-2" @click="uploadFiles">
                {{ $t("global.upload") }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="close">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import store from "@/store";
import Api from "@/Api";
import vue2Dropzone from "vue2-dropzone";
import { dropzoneOptionsExcel } from "@/config/Dropzone";

export default {
  name: "ExcelUploadModal",
  components: {
    vue2Dropzone,
  },
  mixins: [modal],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropzoneKey: 0,
      numPages: 1,
      actualPage: 1,
      dropzoneOptions: dropzoneOptionsExcel(Api.defaults.baseURL + this.path),
    };
  },
  computed: {},
  methods: {
    close() {
      $("#" + this.id).modal("hide");
    },
    uploadFiles() {
      this.$refs.dokumenteUpload.processQueue();
    },
    fileUploaded(file, response) {
      console.log(response);
      this.dropzoneKey++;
      this.$parent.schliesseExcelUploadModal(this.id, response);
    },
  },
};
</script>

<style>
</style>
