<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="
                  $t('global.course') +
                  (kurs.bezeichnung ? ': ' + kurs.bezeichnung : '')
                "
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_bildung.delete"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <generisch-stammdaten
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
            :veranstaltungstypen="kurstypen"
          />

          <generisch-lektionen
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
          />

          <generisch-teilnehmer
            :v-if="kursid"
            :veranstaltung="kurs"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <kurs-details
            :v-if="kursid"
            :kurs="kurs"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <!-- ENDE Rechte Seite -->
        
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />

    <!--<dozenteneinsatz-administrieren-modal
      v-if="kursid"
      :id="DozenteneinsatzAdministrierenModalId"
      :veranstaltung="kursid"
      :bildungsbezeichnung="kurs.bezeichnung"
      @dozentChanged="terminlisteKey++"
    ></dozenteneinsatz-administrieren-modal>-->

    <messagebox-enhanced
      :headerText="$t('global.startpromotion')"
      :ok="true"
      @ok="promotionStart"
    />
    <loeschen-modal id="kurs-loeschen-modal" @confirmed="kursloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import GenerischStammdaten from "@/components/Bildung/Generisch/Stammdaten";
import GenerischLektionen from "@/components/Bildung/Generisch/Lektionen";
import GenerischTeilnehmer from "@/components/Bildung/Generisch/Teilnehmer";
import KursDetails from "@/components/Bildung/Kurs/KursDetails.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { textNotLinked } from "@/defaultTexts.js";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "TemplateKurs",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    GenerischStammdaten,
    GenerischLektionen,
    GenerischTeilnehmer,
    KursDetails,
    MessageboxEnhanced,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],

  props: {
    kursid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      anmeldungslisteKey: 0,
      terminlisteKey: 0,
      kurs: {
        lehrgang: {
          bezeichnung: textNotLinked,
          lehrgangstatus: { bezeichnung: textNotLinked },
        },
        verantwortlicher: { personName: textNotLinked },
        veranstaltungstyp: {
          bezeichnung: "Kurs",
          id: "174D91487E8",
        },
      },
      DozenteneinsatzAdministrierenModalId:
        "dozenteneinsatz-administrieren-modal",
    };
  },
  computed: {
    kurstypen: function () {
      let kurstypen = [];
      kurstypen.push({ bezeichnung: "Kurs", id: "174D91487E8" }); // kurstypen.push(this.$CONST("VERANSTALTUNGSTYPEN").PRECOURSE);
      kurstypen.push({ bezeichnung: "Vorkurs", id: "174D91487E0" }); // kurstypen.push(this.$CONST("VERANSTALTUNGSTYPEN").COURSE);
      kurstypen.push({ bezeichnung: "Pruefung", id: "174D91487D6" }); // k
      kurstypen.push({ bezeichnung: "Weiterbildungskurs", id: "174D91487E4" }); // k
      return kurstypen;
    },
  },
  watch: {
    kursid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        } else {
          this.editable = this.berechtigungen.m_bildung.update;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("setKurstyp", (kurstyp) => {
      this.kurs.veranstaltungstyp = kurstyp;
    });
  },
  methods: {
    ladeVeranstaltung(kursid) {
      this.loading = true;
      Api.get("template/veranstaltung/", { params: { id: kursid } })
        .then((response) => {
          this.initializeKurs(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initializeKurs(kurs) {
      if (kurs) {
        this.kurs = kurs;

        if (!this.kurs.verantwortlicher)
          this.kurs.verantwortlicher = { personName: textNotLinked };

        if (!this.kurs.lehrgang)
          this.kurs.lehrgang = {
            bezeichnung: textNotLinked,
            lehrgangstatus: { bezeichnung: textNotLinked },
          };
        
        if (!this.kurs.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.kurs.lehrgang.lehrgangstatus = { bezeichnung: textNotLinked };

        this.initChangeWatcher(this.kurs);

        this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
          this.kurs.veranstaltungsstatus.id
        )
          ? true
          : false;
      } else {
        console.error("kurs ist null");
      }

      this.editable = !this.berechtigungen.m_bildung.update
        ? false
        : this.editable;
    },

    promotion() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("global.shouldthepromotionbestarted"),
      });
    },

    promotionStart() {
      Api.put(
        "noten/promotion",
        {},
        {
          params: { kurs: this.kurs.id },
        }
      ).then(() => {});
    },

    abbrechen() {
      this.$router.push({ name: "template-kurs-liste" });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.kurs);
      //console.log(json);
      json.veranstaltungstyp = this.kurs.veranstaltungstyp?.id
      json.lehrmethode = this.kurs.lehrmethode?.id
      if (this.kurs.qualifikationen)
        json.qualifikationen = this.kurs.qualifikationen.map((qa) => {
          return qa.id;
        });

      if (this.kurs.themen)
        json.themen = this.kurs.themen.map((t) => {
          return t.id;
        });

      json.lehrgang = this.kurs.lehrgang?.id;
      json.veranstaltungsstatus = this.kurs.veranstaltungsstatus?.id;
      json.verantwortlicher = this.kurs.verantwortlicher?.id;
      json.periode = this.kurs.periode?.id;

      if (!this.kursid) {
        Api.post("template/veranstaltung/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.coursesuccessfullysaved"),
            });
            this.$router.replace({
              name: "template-kurs",
              params: { kursid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("template/veranstaltung/", json, { params: { id: this.kurs.id } })
          .then((response) => {
            this.initializeKurs(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.coursesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "kurs-loeschen-modal");
    },
    anmeldungslisteUpdate() {
      this.anmeldungslisteKey++;
    },
    kursloeschen() {
      let selectedId = [this.kursid];
      Api.delete("template/veranstaltung/", {
        params: { ids: selectedId.join(",") },
      }).then(() => {
        this.$router.replace({ name: "kurs-liste" });
      });
    },
    showDozenteneinsatzAdministrieren() {
      $("#" + this.DozenteneinsatzAdministrierenModalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.$bus.$emit("openDozenteneinsatzAdministrierenModal", null);
    },
  },
};
</script>

<style>
</style>
