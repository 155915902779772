<template>
  <div id="kurs-vorlage-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">Vorlage wählen</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <span
                  >Wählen Sie die Vorlage, die Sie für den aktuellen Kurs
                  verwenden wollen.</span
                >
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <v-select
                      v-model="vorlage"
                      class="mt-2 mb-3"
                      v-bind:class="{ 'border-danger': !passed }"
                      label="titel"
                      :options="vorlagen"
                      placeholder="Vorlage"
                    >
                      <span slot="no-options">{{
                        $t("notification.taptosearch")
                      }}</span>
                    </v-select>
                    <label v-bind:class="{ 'text-danger': !passed }"
                      >Vorlage</label
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              Übernehmen
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import { LADE_KURSVORLAGEN } from "@/store/veranstaltung/actions.type";

export default {
  name: "KursVorlageModal",
  components: {
    ModalCloseButton,
  },
  props: {},
  data() {
    return {
      shown: false,
      vorlage: null,
    };
  },
  computed: {
    vorlagen: {
      get() {
        return this.$store.state.veranstaltung.kursvorlagen;
      },
    },
  },
  watch: {},
  created() {
    this.$store.dispatch(`veranstaltung/${LADE_KURSVORLAGEN}`);
  },
  mounted() {
    this.$bus.$on("kurs-vorlage-modal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      // if (this.vorlagen.length == 0)

      this.shown = true;

      $("#kurs-vorlage-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      this.shown = false;
      $("#kurs-vorlage-modal").modal("hide");
    },
    uebernehmen() {
      this.$emit("confirmed", this.vorlage);
      this.closeModal();
    },
  },
};
</script>

<style>
</style>
