<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.receiversender')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <single-select-person
                id="empfaenger"
                :person="korrespondenz.person[0]"
                :firma="korrespondenz.firma[0]"
                :editable="editable"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :label="$t('global.person')"
                @confirmed="setEmpfaenger"
              />
            </div>
            <div class="col-xl-3">
              <single-select-firma
                id="empfaenger"
                :firma="korrespondenz.firma[0]"
                :filterPerson="korrespondenz.person[0]"
                :editable="editable"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :label="$t('global.company')"
                @confirmed="setEmpfaengerFirma"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="ersteller"
                :person="korrespondenz.versand.erstelltvon"
                :label="$t('global.creator')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setErsteller"
              />
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <v-select
                  v-model="korrespondenz.verarbeitungsstatus"
                  label="bezeichnung"
                  :options="verarbeitungsstati"
                  :disabled="!editable"
                  :placeholder="$t('global.processingstatus')"
                  :reduce="(vs) => vs.id"
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.processingstatus") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import store from "@/store";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

export default {
  name: "KorrespondenzSenderEmpfaenger",
  components: {
    ContainerHeadline,
    SingleSelectPerson,
    SingleSelectFirma,
  },
  mixins: [],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    verarbeitungsstati: {
      get() {
        return this.$store.state.korrespondenz.verarbeitungsstati;
      },
    },
    archivierungsstati: {
      get() {
        return this.$store.state.korrespondenz.archivierungsstati;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.verarbeitungsstati.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    setEmpfaenger(person) {
      //this.korrespondenz.person[0] = person;
      console.log("person_"+this.korrespondenz.person[0])
      this.$emit("updated", [person], null);
    },
    setEmpfaengerFirma(firma) {
      //this.korrespondenz.firma[0] = firma;
      this.$emit("updated", null, [firma]);
    },

    setErsteller(person) {
      this.korrespondenz.versand.erstelltvon = person;
      this.$emit("updated");
    },
  },
};
</script>

<style>
</style>
