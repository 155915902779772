<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button class="btn btn-danger" @click="oeffneLoeschenModal">
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <korrespondenz-vorlage-stammdaten
          :navbarTitel="navbarTitel"
          :korrespondenz="korrespondenz"
        />

        <korrespondenz-vorlage-inhalt
          :navbarTitel="navbarTitel"
          :korrespondenz="korrespondenz"
          @unterschrift1updated="unterschrift1Updated"
          @unterschrift2updated="unterschrift2Updated"
        />
      </div>
    </ValidationObserver>

    <loeschen-modal
      id="korrespondenz-vorlage-loeschen-modal"
      @confirmed="korrespondenzLoeschen"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import KorrespondenzVorlageInhalt from "@/components/Kommunikation/KorrespondenzVorlageInhalt.vue";
import KorrespondenzVorlageStammdaten from "@/components/Kommunikation/KorrespondenzVorlageStammdaten.vue";
import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "Korrespondenz",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    KorrespondenzVorlageInhalt,
    KorrespondenzVorlageStammdaten,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      korrespondenz: {
        korrespondenzkategorie: {},

        unterschrift1: { person: { personName: textNotLinked } },
        unterschrift2: { person: { personName: textNotLinked } },
      },
      loading: false,

      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.correspondencetemplate");
      } else {
        return this.$t("global.newcorrespondencetemplate");
      }
    },
  },

  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.ladeKorrespondenz(val);
        }
      },
      immediate: true,
    },
  },

  created() {
    if (!this.editable) this.editable = true;
  },

  mounted: function () {},

  methods: {
    unterschrift1Updated(u1) {
      console.log(u1);
      if (u1) {
        if (!u1.id) return;

        this.korrespondenz.unterschrift1 = u1;
        console.log(u1);
      } else {
        this.korrespondenz.unterschrift1 = {};
      }
    },
    unterschrift2Updated(u2) {
      if (u2) {
        if (!u2.id) return;

        this.korrespondenz.unterschrift2 = u2;
      } else {
        this.korrespondenz.unterschrift2 = {};
      }
    },
    ladeKorrespondenz(id) {
      this.loading = true;
      Api.get("korrespondenz/vorlagen/", { params: { id: id } })
        .then((response) => {
          this.initializeKorrespondenz(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.korrespondenz);

      if (!this.id) {
        Api.post("korrespondenz/vorlagen/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t(
                "notification.correspondencetemplatesavedsuccessfully"
              ),
            });

            this.$router.replace({
              name: "korrespondenz-vorlage",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("korrespondenz/vorlagen/", json, {
          params: { id: this.korrespondenz.id },
        })
          .then((response) => {
            this.initializeKorrespondenz(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t(
                "notification.correspondencetemplatesavedsuccessfully"
              ),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    abbrechen() {
      this.$router.push({ name: "korrespondenz-vorlagen-liste" });
    },

    initializeKorrespondenz(korrespondenz) {
      if (korrespondenz) {
        this.korrespondenz = korrespondenz;
      } else {
        console.error("korrespondenz ist null");
      }
    },

    korrespondenzLoeschen() {
      let selectedIds = [this.id];
      Api.delete("korrespondenz/vorlagen/", {
        params: { ids: selectedIds.join(",") },
      }).then(() => {
        this.$router.replace({ name: "korrespondenz-vorlagen-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit(
        "openLoeschenModal",
        "korrespondenz-vorlage-loeschen-modal"
      );
    },
  },
};
</script>

<style>
</style>
