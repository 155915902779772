<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- Start Mailadressen -->
      <div class="row">
        <div class="col-xl-12">
          <container-headline
            class="col-xl-12"
            :headline="$t('global.email')"
            :col="3"
          >
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="showMailAdresseHinzufuegen"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />{{
                $t("global.addemail")
              }}
            </button>
          </container-headline>
          <div class="row col-xl-12">
            <div class="col-xl-12 block br-t-l-0">
              <div :key="mailadressenkey" class="list-group">
                <mail-adresse
                  v-for="(ma, indexMA) in mailadressen"
                  :key="ma.key"
                  :mailadresse="ma"
                  :index="indexMA"
                  :editable="editable"
                  @deleteMailAdresse="mailAdresseDeleted"
                  @updatemailadresse="showMailAdresseAendern"
                  @setMailAdresseHaupt="markiereHauptadresse"
                ></mail-adresse>

                <div
                  v-if="mailIsBusy && !mailLadeFehler"
                  class="text-center text-primary my-2"
                >
                  <b-spinner class="align-middle"></b-spinner>
                </div>

                <div
                  class="text-center text-primary my-2"
                  v-if="
                    mailadressen.length == 0 && !mailIsBusy && !mailLadeFehler
                  "
                >
                  <strong>{{ $t("global.noemailadress") }}</strong>
                </div>

                <div
                  class="text-center text-primary my-2"
                  v-if="!mailIsBusy && mailLadeFehler"
                >
                  <strong>{{ $t("global.errorloadingemailadress") }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ende Mailadressen -->

      <div class="row mt-5" v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
        <div class="col-xl-12">
          <container-headline
            class="col-xl-12"
            :headline="$t('global.addresses')"
            :col="3"
          >
            <!--<button
              v-if="editable"
              class="btn btn-primary mr-2"
              @click="showAdresseVerknuepfen"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />Bestehende Adresse
              verknüpfen
            </button>-->
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="showAdresseHinzufuegen"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />{{
                $t("global.addaddress")
              }}
            </button>
          </container-headline>
          <!-- Adress Anzeige mit Komponente -->
          <div class="row col-xl-12">
            <div class="col-xl-12 block br-t-l-0">
              <div :key="adressenkey" class="list-group">
                <geschaeftspartner-adresse
                  v-for="(a, index) in adressen"
                  :key="a.key"
                  :adresse="a"
                  :index="index"
                  :editable="editable"
                  @deleteAdresse="adresseDeleted"
                  @updateAdresse="showAdresseAendern"
                  @korrespondenzAdresse="setKorrespondenzAdresse"
                  @rechnungsAdresse="setRechnungAdresse"
                ></geschaeftspartner-adresse>

                <div
                  v-if="adresseIsBusy && !adresseLadeFehler"
                  class="text-center text-primary my-2"
                >
                  <b-spinner class="align-middle"></b-spinner>
                </div>

                <div
                  class="text-center text-primary my-2"
                  v-if="
                    adressen.length == 0 && !adresseIsBusy && !adresseLadeFehler
                  "
                >
                  <strong>{{ $t("global.noaddressesavailable") }}</strong>
                </div>

                <div
                  class="text-center text-primary my-2"
                  v-if="!adresseIsBusy && adresseLadeFehler"
                >
                  <strong>{{ $t("global.errorloadingadress") }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <adresse-modal
      v-if="$CONST('CONFIG').APPTYPE != 'DESKA'"
      :neueAdresse="neueAdresse"
      :firma="firma"
      :standorte="standorte"
      @adressChanged="adresseAendern"
      @adressCreated="adresseHinzufuegen"
    ></adresse-modal>

    <mail-adresse-modal
      @mailAdressChanged="mailAdresseAendern"
      @mailAdressCreated="mailAdresseHinzufuegen"
    ></mail-adresse-modal>
    <!--<adresse-suche-modal @confirmed="adresseVerknuepfen" />-->
  </div>
</template>

<script>
import Api from "@/Api";
import { uuidv4 } from "@/utils/Uuid";
import ContainerHeadline from "@/components/ContainerHeadline";
import MailAdresse from "@/components/Geschaeftspartner/MailAdresse";
import MailAdresseModal from "@/components/Geschaeftspartner/MailAdresseModal";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
//import AdresseSucheModal from "@/components/Geschaeftspartner/adresseSucheModal.vue";
import GeschaeftspartnerAdresse from "@/components/Geschaeftspartner/GeschaeftspartnerAdresse";
import AdresseModal from "@/components/Geschaeftspartner/AdresseModal";

export default {
  components: {
    ContainerHeadline,
    MailAdresse,
    MailAdresseModal,
    //AdresseSucheModal,
    GeschaeftspartnerAdresse,
    AdresseModal,
    BSpinner,
  },
  props: {
    person: {
      type: String,
      required: true,
    },
    firma: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    standorte: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dataID: null,
      adressen: [],
      mailadressen: [],
      adresseIsBusy: false,
      adresseLadeFehler: false,
      mailIsBusy: false,
      mailLadeFehler: false,

      adressenkey: uuidv4(),
      mailadressenkey: uuidv4(),
      neueAdresse: true,
    };
  },
  watch: {
    shown(val) {
      if (val) {
        if (
          this.dataID == null ||
          (this.dataID != this.person && this.dataID != this.firma)
        ) {
          this.getDaten();
        }
      }
    },
  },
  computed: {},
  mounted() {
    if (this.shown) {
      this.getDaten();
    }
  },
  created() {},
  methods: {
    getDaten() {
      this.mailIsBusy = true;
      this.dataID = this.person ? this.person : this.firma; // ID der Person oder Firma merken, da wir Caching Issues hatten bei Sprung von Datensatz zu Datensatz

      Api.get("mailadresse/", {
        params: { gpid: this.person, fid: this.firma },
      })
        .then((response) => {
          this.mailadressen = response.data;
          this.$emit("added", this.mailadressen);
          this.mailLadeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.mailLadeFehler = true;
        })
        .finally(() => {
          this.mailIsBusy = false;
        });

      this.adresseIsBusy = true;

      Api.get("geschaeftspartner/adressverbindung/", {
        params: { gpid: this.person },
      })
        .then((response) => {
          this.adressen = response.data;

          this.adressen.forEach((adresse) => {
            adresse.isKorrespondenz = false;
            adresse.isRechnung = false;

            adresse.adressverwendungen.forEach((verwendung) => {
              adresse.isKorrespondenz =
                verwendung.id ==
                this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE
                  ? true
                  : adresse.isKorrespondenz;
              adresse.isRechnung =
                verwendung.id == this.$CONST("ADRESSVERWENDUNGSTYPEN").INVOICE
                  ? true
                  : adresse.isRechnung;
            });
          });
          this.$emit("adressenGeladen", this.adressen);
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.adresseLadeFehler = true;
        })
        .finally(() => {
          this.adresseIsBusy = false;
        });
    },

    /*** Mailadressen ***/

    showMailAdresseHinzufuegen() {
      this.$bus.$emit("mailadresse-modal-show", {});
    },

    showMailAdresseAendern(index) {
      if (this.mailadressen[index]) {
        this.$bus.$emit("mailadresse-modal-show", this.mailadressen[index]);
      }
    },

    mailAdresseHinzufuegen(mailadresse) {
      if (this.mailadressen.length === 0)
        mailadresse.istMailHauptadresse = true;

      mailadresse.key = uuidv4();
      this.mailadressen.push(mailadresse);
      this.$emit("added", this.mailadressen);
      this.updateParent();
    },

    mailAdresseAendern(updateMailAdresse) {
      let pos = this.mailadressen.findIndex(
        (madr) => madr.id === updateMailAdresse.id
      );

      if (pos != -1) {
        this.$set(this.mailadressen, pos, updateMailAdresse);
        this.$emit("added", this.mailadressen);
        this.updateParent();
      }
    },

    mailAdresseDeleted(indexMA) {
      let hauptAdresseRemoved = this.mailadressen[indexMA].istHauptadresse;

      if (this.mailadressen[indexMA].id) {
        this.mailadressen[indexMA].delete = true;
      } else {
        this.mailadressen.splice(indexMA, 1);
      }

      if (hauptAdresseRemoved) {
        this.markiereHauptadresse(0);
        this.$emit("added", this.mailadressen);
      } else this.updateParent();
    },

    markiereHauptadresse(index) {
      this.mailadressen.forEach((a, i) => {
        a.hauptadresse = i == index;
      });

      let copy = Object.assign({}, this.mailadressen[index]);
      this.$set(this.mailadressen, index, copy);
      this.$emit("added", this.mailadressen);
      this.updateParent();
    },

    /*** Adressen ***/

    /*showAdresseVerknuepfen() {
      this.$bus.$emit("adresse-suchen-modal-show", null);
    },*/

    showAdresseHinzufuegen() {
      this.neueAdresse = true;
      this.$bus.$emit("adresse-modal-show", {});
    },

    showAdresseAendern(index) {
      this.neueAdresse = false;
      this.$bus.$emit("adresse-modal-show", this.adressen[index]);
    },

    adresseVerknuepfen(addAdresse) {
      let newAdress = {
        adresszusatz: "",
        key: uuidv4(),
        adressverwendungen: [],
        adresse: addAdresse[0],
      };

      this.adressen.push(newAdress);
      this.updateParent();
    },

    adresseHinzufuegen(addAdresse) {
      let newAdress = {
        adresszusatz: "",
        key: uuidv4(),
        adressverwendungen: [],
      };

      // Flag Korrespondezadresse verarbeiten

      if (addAdresse.isKorrespondenz) {
        newAdress.adressverwendungen.push({
          bezeichnung: "Korrespondenzadresse",
          id: this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE,
        });
        newAdress.isKorrespondenz = true;
        this.resetKorrespondenzFlag();
      } else {
        newAdress.isKorrespondenz = false;
      }

      // Flag Rechnungsadresse verarbeiten

      if (addAdresse.isRechnung) {
        newAdress.adressverwendungen.push({
          bezeichnung: "Rechnungsadresse",
          id: this.$CONST("ADRESSVERWENDUNGSTYPEN").INVOICE,
        });

        newAdress.isRechnung = true;
        this.resetRechnungFlag();
      } else {
        newAdress.isRechnung = false;
      }

      newAdress.adresse = addAdresse;
      newAdress.standort = addAdresse.standort;
      newAdress.adresse.key = newAdress.key;
      this.$set(this.adressen, this.adressen.length, newAdress);
      this.updateParent();
    },

    adresseAendern(updateAdresse) {
      /*** Die geänderte Adresse identifizieren */
      let pos = this.adressen.findIndex(
        (adr) =>
          adr.adresse.id === updateAdresse.id && adr.adresse.id != undefined
      );
      if (pos == -1) {
        pos = this.adressen.findIndex(
          (adr) => adr.adresse.key === updateAdresse.key
        ); // Neue Adresse mit Änderung vor Speichern
      }
      let adressTypKorrespondenz = this.$CONST(
        "ADRESSVERWENDUNGSTYPEN"
      ).CORRESPONDENCE;
      let adressTypRechnung = this.$CONST("ADRESSVERWENDUNGSTYPEN").INVOICE;
      if (pos != -1) {
        let advposKorrespondenz = this.adressen[
          pos
        ].adressverwendungen.findIndex(
          (adv) => adv.id == adressTypKorrespondenz
        ); // Ist nicht gleich -1 falls die Adresse VORHER eine Korrespondenz war

        if (updateAdresse.isKorrespondenz && advposKorrespondenz == pos) {
          // Ist Korrespondenzadresse und war Korrespondenzadresse
        } else if (
          !updateAdresse.isKorrespondenz &&
          advposKorrespondenz != pos
        ) {
          // War keine Korrespondenzadresse und ist weiterhin keine Korrespondenzadresse
        } else if (
          !updateAdresse.isKorrespondenz &&
          advposKorrespondenz == pos
        ) {
          // War Korrespondenzadresse und ist nicht mehr Korrespondenzadresse

          this.resetKorrespondenzFlag();
        } else if (
          updateAdresse.isKorrespondenz &&
          advposKorrespondenz != pos
        ) {
          // War keine Korrespondenzadresse und ist neu Korrespondenzadresse

          this.setKorrespondenzAdresse(pos);
        }

        let advposRechnung = this.adressen[pos].adressverwendungen.findIndex(
          (adv) => adv.id == adressTypRechnung
        );

        if (updateAdresse.isRechnung && advposRechnung != -1) {
          // Ist Rechnungsadresse und war bereits Rechnungsadresse
        } else if (!updateAdresse.isRechnung && advposRechnung == -1) {
          // War keine Rechnungsadresse und ist weiterhin keine Rechnungsadresse
        } else if (!updateAdresse.isRechnung && advposRechnung != -1) {
          // War Rechnungsadresse und ist nicht mehr Rechnungsadresse

          this.resetRechnungFlag();
        } else if (updateAdresse.isRechnung && advposRechnung == -1) {
          // War keine Rechnungsadresse und ist neu Rechnungsadresse

          this.setRechnungAdresse(pos);
        }
        let copy = Object.assign({}, this.adressen[pos]);
        copy.standort = updateAdresse.standort;
        copy.adresse = updateAdresse;
        this.$set(this.adressen, pos, copy);
        this.updateParent();
      } else {
        console.log("FEHLER: Adresse nicht gefunden");
      }
    },

    adresseDeleted(index) {
      if (this.adressen[index].id) {
        this.adressen[index].delete = true;
      } else {
        this.adressen.splice(index, 1);
      }

      for (let i = 0; i < this.adressen.length; i++) {
        let copy = Object.assign({}, this.adressen[i]);
        this.$set(this.adressen, i, copy);
        this.updateParent();
      }
    },

    resetKorrespondenzFlag() {
      for (let i = 0; i < this.adressen.length; i++) {
        let copy = Object.assign({}, this.adressen[i]);
        this.$set(this.adressen, i, copy);
        this.updateParent();
      }

      let adressTypKorrespondenz = this.$CONST(
        "ADRESSVERWENDUNGSTYPEN"
      ).CORRESPONDENCE;

      this.adressen.forEach((adresse) => {
        let pos = adresse.adressverwendungen.findIndex(
          (adv) => adv.id == adressTypKorrespondenz
        );

        if (pos > -1) adresse.adressverwendungen.splice(pos, 1);

        adresse.isKorrespondenz = false;
      });
    },

    resetRechnungFlag() {
      for (let i = 0; i < this.adressen.length; i++) {
        let copy = Object.assign({}, this.adressen[i]);
        this.$set(this.adressen, i, copy);
        this.updateParent();
      }
      let adressTypRechnung = this.$CONST("ADRESSVERWENDUNGSTYPEN").INVOICE;

      this.adressen.forEach((adresse) => {
        let pos = adresse.adressverwendungen.findIndex(
          (adv) => adv.id == adressTypRechnung
        );

        if (pos > -1) adresse.adressverwendungen.splice(pos, 1);

        adresse.isRechnung = false;
      });
    },

    setKorrespondenzAdresse(index) {
      this.resetKorrespondenzFlag();

      let copy = Object.assign({}, this.adressen[index]);
      copy.adressverwendungen.push({
        bezeichnung: "Korrespondenzadresse",
        id: this.$CONST("ADRESSVERWENDUNGSTYPEN").CORRESPONDENCE.toString(),
      });
      copy.isKorrespondenz = true;
      this.$set(this.adressen, index, copy);
      this.updateParent();

      /*let copy = Object.assign({}, this.adressen[pos]);
        copy.adresse = updateAdresse;
        this.$set(this.adressen, pos, copy);
        this.updateParent();*/
    },

    setRechnungAdresse(index) {
      this.resetRechnungFlag();

      let copy = Object.assign({}, this.adressen[index]);
      copy.adressverwendungen.push({
        bezeichnung: "Rechnungsadresse",
        id: this.$CONST("ADRESSVERWENDUNGSTYPEN").INVOICE.toString(),
      });
      copy.isRechnung = true;
      this.$set(this.adressen, index, copy);
      this.updateParent();
    },

    updateParent() {
      this.$emit("updatekontakte", {
        adressen: this.adressen,
        mailadressen: this.mailadressen,
      });

      //this.$forceUpdate();
    },
  },
};
</script>
