<template>
  <div class="col-xl-2">
    <div class="tile-frame left-aligned h-1">
      <router-link
        :to="{
          name: this.routerName,
        }"
      >
        <div class="tile inverted" :class="color">
          <div class="icon-container fa-container">
            <font-awesome-icon
              :icon="icon"
              class="mr-2"
              fixed-width
              transform="right-2 shrink-3"
            />
          </div>
          <div class="title">
            <div>{{ this.auswahlwerte }}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardEinstellungen",
    props: {
        auswahlwerte: {
            type: String,
        },
        routerName: {
          type: String
        },
        icon: {
          type: String
        },
        color: {
          type: String
        }
    }
}
</script>
