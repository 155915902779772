<template>
  <div id="termin-raum-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>{{ $t("global.changeroom") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="raumliste"
                  label="bezeichnung"
                  :options="raeume"
                  :reduce="(r) => r.id"
                  :placeholder="$t('global.room')"
                  multiple
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.room") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">{{ $t("global.cancel") }}</button>
          <button class="btn btn-primary" @click="raeumeSpeichern">
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import store from "@/store";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "TerminRaumAendernModal",
  components: {
    ModalCloseButton,
  },
  metaInfo() {},
  mixins: [],
  props: {
    termine: {
      type: Array,
      required: true,
    },
  },
  store,
  data() {
    return {
      shown: false,
      raumliste: [],
    };
  },
  computed: {
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },
  watch: {},
  mounted: function () {
    if (this.raeume.length == 0)
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);

    this.$bus.$on("openTerminRaumModal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    raeumeSpeichern() {
      let json = {
        entityname: "Termin",
        fields: ["Raumliste"],
        values: [this.raumliste],
        ids: this.termine,
      };

      if(json.ids.length > 0) {
      Api.put("tools/massupdate", json)
        .then(() => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.roomssuccessfullychange")
            });
          
          this.$emit("updated");
        })
        .catch((e) => {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.roomsnotchanged"),
          });
        });} else {
          this.$notify({
              type: "warn",
              title: this.$t("notification.actionfailed"),
              text: this.$t("notification.noappointmentsselected")
            });
        }
      this.closeModal();
    },

    openModal() {
      this.raumliste = [];
      $("#termin-raum-modal").modal({
        backdrop: "static",
        keyboard: false,
      });

      this.shown = false;
    },

    closeModal() {
      $("#termin-raum-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>
