<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.receipts') + ' (' + belegCount + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              :disabled="isDisabled"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="belege"
                :fields="computedFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="beleg-loeschen-modal"
      @confirmed="belegLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import numeral from "numeral";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    debitor: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    triggerRefresh: {
      type: Number,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      kategorieProp: this.kategorieProp,
      modus: null,
      modi: [],
      status: null,
      belege: [],
      belegCount: 0,
      isDisabled: true,
      betraege: { zahlungseingaenge: 0, minderungen: 0 },
      fields: [
        { key: "selected", label: "" },
        { key: "belegnummer", sortable: true, label: this.$t("global.num") },
        {
          key: "belegtyp.bezeichnung",
          sortable: true,
          label: this.$t("global.receipttype"),
        },
        {
          key: "date",
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "sollkonto.bezeichnung",
          sortable: true,
          label: this.$t("global.debitaccount"),
        },
        {
          key: "habenkonto.bezeichnung",
          sortable: true,
          label: this.$t("global.creditaccount"),
        },
        /*
        {
          key: "kostenstelle",
          sortable: true,
          label: "Kostenstelle",
        },
        */
        {
          key: "netto",
          sortable: true,
          label: this.$t("global.net"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "brutto",
          sortable: true,
          label: this.$t("global.gross"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "waehrung.bezeichnung",
          sortable: true,
          label: this.$t("global.currency"),
        },
        {
          key: "buchungstext",
          sortable: true,
          label: this.$t("global.bookingtext"),
        },
        {
          key: "exportdatum",
          sortable: true,
          label: this.$t("global.exportdate"),
        },
      ],
      selected: [],
      selectedIds: [],
      selectedObjects: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.belege.length == 0) this.getBelege();
    },
    triggerRefresh: function () {
      this.$emit("deleted");
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    computedFields() {
      return this.fields;
    },
  },
  mounted() {
    if (this.shown && this.belege.length == 0) this.getBelege();
    this.setHeaders("global", [
      "",
      "num",
      "receipttype",
      "date",
      "debitaccount",
      "creditaccount",
      "net",
      "gross",
      "currency",
      "bookingtext",
      "exportdate",
    ]);
  },
  created() {},
  methods: {
    getBelege() {
      this.isBusy = true;

      Api.get("finanzen/beleg/", {
        params: {
          debitor: this.debitor,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.belege = response.data;
          this.belegCount = this.belege.length;
          this.belege.forEach((beleg) => {
            if (beleg.belegtyp.id == "17926E20183" /*Zahlungseingang*/) {
              this.betraege.zahlungseingaenge += parseFloat(beleg.betrag);
            }
            if (
              beleg.belegtyp.id == "17926E2017D" &&
              beleg.sollkonto.bezeichnung != "Debitoren" /*Korrekturbuchung*/
            ) {
              this.betraege.minderungen += parseFloat(beleg.betrag);
            }
          });
          this.$emit("betraegeUpdated", this.betraege);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(belege) {
      this.$router.push({
        name: "BelegMitIdUndAnzeige",
        params: { id: belege.id, anzeige: 0 },
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
            this.selectedObjects.push(v);
          }
          if (
            this.selectedObjects.find((obj) => obj.exportdatum != null) ==
            undefined
          ) {
            this.isDisabled = false;
          } else this.isDisabled = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
        this.selectedObjects = [];
        if (
          this.selectedObjects.find((obj) => obj.exportdatum != null) ==
          undefined
        ) {
          this.isDisabled = false;
        } else this.isDisabled = true;
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
        this.selectedObjects.push(row.item);
        this.selectedObjects = Array.from(new Set(this.selectedObjects));
        if (
          this.selectedObjects.find((obj) => obj.exportdatum != null) ==
          undefined
        ) {
          this.isDisabled = false;
        } else this.isDisabled = true;
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
        this.selectedObjects = this.selectedObjects.filter(
          (object) => object != row.item
        );
        if (
          this.selectedObjects.find((obj) => obj.exportdatum != null) ==
          undefined
        ) {
          this.isDisabled = false;
        } else this.isDisabled = true;
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length != 0) {
        this.$bus.$emit("openLoeschenModal", "beleg-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noreceiptselected"),
        });
      }
    },

    belegLoeschen() {
      Api.delete("finanzen/beleg/", {
        data: [this.selectedIds],
      }).then(() => {
        this.getBelege();
        this.$emit("deleted");
        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("notification.receiptsdeleted"),
        });
      });
    },
  },
};
</script>
