<template>
  <div
    class="list-group-item fade"
    :class="{
      show: shown,
      'd-none': deleteProp,
    }"
  >
    <div v-if="mailadresse">
      <div class="row">
        <div class="col-xl-6">
          <span
            v-if="editable"
            class="clickable mr-2"
            @click="$emit('updatemailadresse', index)"
          >
            <font-awesome-icon icon="fa-regular fa-pencil" />
          </span>
          <span v-if="mailadresse.hauptadresse" class="mr-4 text-success">
            <font-awesome-icon icon="fa-regular fa-house" />
          </span>
          <span
            v-if="!mailadresse.hauptadresse"
            class="clickable mr-4"
            @click="markiereHauptAdresse"
          >
            <font-awesome-icon icon="fa-regular fa-house" />
          </span>
          {{ mailadresse.bezeichnung }}
        </div>
        <div class="col-xl-3" v-if="mailadresse.mailtyp">
          {{ mailadresse.mailtyp.bezeichnung }}
        </div>
      </div>
    </div>
    <font-awesome-icon
      icon="fa-regular fa-minus"
      class="delete-position-minus"
      v-if="editable"
    />
    <button
      class="btn btn-danger deletePositionButton"
      @click="deleteMailAdresse"
      v-if="editable"
    ></button>
  </div>
</template>

<script>
import { fadeIn } from "@/mixins/FadeIn";

export default {
  name: "MailAdresse",
  components: {},
  mixins: [fadeIn],
  props: {
    mailadresse: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      deleteProp: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    deleteMailAdresse() {
      this.shown = false;
      setTimeout(() => {
        this.$emit("deleteMailAdresse", this.index);
      }, 250);

      this.deleteProp = true;
    },

    markiereHauptAdresse() {
      if (this.editable) this.$emit("setMailAdresseHaupt", this.index);
    },

    openContextMenu() {
      this.$refs.ctxMenu.open();
    },
  },
};
</script>

<style>
</style>
