<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("global.transferregistration") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <single-select-veranstaltung
                  id="anmeldung-uebertragen-veranstaltung"
                  :label="$t('global.event')"
                  :veranstaltung="veranstaltung"
                  :allowOpen="false"
                  :required="true"
                  editable
                  @confirmed="setVeranstaltung"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <v-select
                      v-model="anmeldestatus"
                      v-bind:class="{ 'border-danger': !passed }"
                      label="bezeichnung"
                      :options="anmeldestati"
                      :reduce="(asl) => asl.id"
                      :placeholder="$t('global.status')"
                    >
                      <span slot="no-options">{{
                        $t("notification.taptosearch")
                      }}</span>
                    </v-select>
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.status")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row" v-if="faecher.length > 0">
              <div class="col-xl-12">
                <div class="form-group">
                  <label>Fächer</label>
                  <div v-for="(item, index) in faecher" :key="index">
                    <b-form-checkbox
                      v-model="item.selected"
                      class="form-checkbox-input"
                    >
                      {{ item.kuerzel }} {{ item.bezeichnung }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6" v-if="allowCopy">
                <div class="form-group">
                  <b-form-checkbox v-model="copy" class="form-checkbox-input">
                    {{ $t("global.copyregistration") }}
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-xl-6" v-if="allowDelete">
                <div class="form-group">
                  <b-form-checkbox
                    v-model="loeschen"
                    class="form-checkbox-input"
                  >
                    {{ $t("global.deleteoutputdata") }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="invalid"
              @click="uebertragen"
            >
              {{ $t("global.transfer") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "AnmeldungUebertragenModal",
  components: { ModalCloseButton, SingleSelectVeranstaltung },
  props: {
    id: {
      type: String,
      required: true,
    },
    warteSchlange: {
      type: Boolean,
      default: false,
    },
    allowCopy: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    filterTyp: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      shown: false,
      anmeldestatus: null,
      veranstaltung: { bezeichnung: textNotLinked },
      anmeldungen: [],
      faecher: [],
      copy: false,
      loeschen: false,
    };
  },
  computed: {
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
  },
  watch: {
    anmeldestati: {
      handler: function (val) {
        if (val.length > 0) {
          if (!this.anmeldestatus || !this.anmeldestatus.id) {
            let anmeldungStatusWarteliste =
              this.$CONST("ANMELDESTATI").WAITINGLIST;

            this.anmeldestatus = this.anmeldestati.find(
              (anmeldestatus) => anmeldestatus.id == anmeldungStatusWarteliste
            );
          }
        }
      },
    },
    veranstaltung: {
      deep: true,
      handler(veranstaltung) {
        if (!veranstaltung.id) return;

        this.getFaecher();
      },
    },
  },
  mounted() {
    this.$bus.$on("openAnmeldungUebertragenModal", (data) => {
      if (!data || data.length == 0) return;
      this.anmeldungen = data;

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      if (this.anmeldestati.length == 0)
        this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);

      if (this.allowCopy) this.copy = true;

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.leereEingaben();
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    getFaecher() {
      Api.get("veranstaltungfach/", {
        params: {
          veranstaltung: this.veranstaltung.id,
          veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        },
      }).then((response) => {
        ////response.data.shift();
        this.faecher = response.data;
      });
    },

    uebertragen() {
      if (this.warteSchlange) this.warteschlangeAnmeldung();
      else this.regulaereAnmeldung();
    },

    warteschlangeAnmeldung() {
      var json = {
        ids: this.anmeldungen,
        anmeldestatus: this.anmeldestatus.id
          ? this.anmeldestatus.id
          : this.anmeldestatus,
        veranstaltung: this.veranstaltung,
      };

      Api.put("anmeldung/anmeldeliste/move", json).then((response) => {
        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: "Anmeldung erfolgreich gespeichert.",
        });

        $("#" + this.id).modal("hide");
        this.$emit("changed", response.data.id);
      });
    },

    regulaereAnmeldung() {
      var json = {
        ids: this.anmeldungen,
        copy: this.copy ? this.copy : false,
        anmeldestatus: this.anmeldestatus.id
          ? this.anmeldestatus.id
          : this.anmeldestatus,
        veranstaltung: this.veranstaltung,
        faecher: [],
      };

      this.faecher.forEach((fach) => {
        if (fach.selected) {
          json.faecher.push(fach.id);
        }
      });

      Api.put("/anmeldung/move/", json).then((response) => {
        $("#" + this.id).modal("hide");

        if (this.anmeldungen.length > 1) {
          // Multiple übertragen
          if (this.copy) {
            this.$emit("copied", null);
          } else {
            this.$emit("changed", null);
          }
        } else {
          if (this.copy) {
            this.$emit("copied", response.data.id);
          } else {
            this.$emit("changed", response.data.id);
          }
        }
      });
    },

    setVeranstaltung(veranstaltung) {
      this.veranstaltung = veranstaltung;
    },

    leereEingaben() {},
  },
};
</script>

<style>
</style>
