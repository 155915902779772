<template>
  <div>
    <container-headline :headline="$t('dashboard.educationcourse')" :col="6"></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-5">
              <div class="form-group">
                <span
                  class="openIcon"
                  v-on:click="oeffneBildungsgang"
                  v-if="berechtigungen.b_bildung_bildungsgang.read"
                  ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </span>
                <input
                  v-model="fach.lehrgang.bezeichnung"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("dashboard.educationcourse") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
            <div class="col-xl-5">
              <div class="form-group">
                <input
                  v-model="fach.lehrgang.lehrgangstatus.bezeichnung"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("global.status") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
                /></label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group" v-if="!isTemplate">
                <input
                  v-model="fach.periodennummer"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("dashboard.semester") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "FachBildungsgang",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    fach: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    oeffneBildungsgang() {
      if (this.fach.lehrgang) {
        this.$router.push({
          name: this.isTemplate ? "template-bildungsgang" : "bildungsgang",
          params: { lehrgangid: this.fach.lehrgang.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
