<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <container-headline
      :headline="$t('global.content')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="korrespondenz.betreff"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.subject") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="korrespondenz.ort"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift1"
                  label="person.personName"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift1Updated"
                  :placeholder="$t('global.signature')"
                >
                  <template #option="{ person }">
                    {{ person.personName }}
                  </template>
                  <template #selected-option="{ person }">
                    {{ person.id ? person.personName : $t("global.notlinked") }}
                  </template>
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.signature1") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift2"
                  label="person.personName"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift2Updated"
                  :placeholder="$t('global.signature')"
                >
                  <template #option="{ person }">
                    {{ person.personName }}
                  </template>
                  <template #selected-option="{ person }">
                    {{ person.id ? person.personName : $t("global.notlinked") }}
                  </template>
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.signature2") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6"></div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="korrespondenz.beilagen"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.Attachments") }}</label>
              </div>
            </div>
          </div>

          <!-- ENDE Korrespondenzversand -->

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="korrespondenz.inhalt"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import store from "@/store";

import page from "@/mixins/Page";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";
import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "Korrespondenz",
  components: {
    ContainerHeadline,
  },
  metaInfo() {},
  mixins: [page],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      unterschrift1: { person: { personName: textNotLinked } },
      unterschrift2: { person: { personName: textNotLinked } },
      editable: true,
      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {
    unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },
    unterschrift1Label() {
      if (this.unterschrift1?.person?.personName)
        return this.unterschrift1.person.personName;
      else return "";
    },
  },
  watch: {
    korrespondenz: {
      deep: true,
      handler(val) {
        if (val.unterschrift1 && val.unterschrift1.person) {
          this.unterschrift1 = val.unterschrift1;
        } else {
          this.unterschrift1 = { person: { personName: textNotLinked } };
        }

        if (val.unterschrift2 && val.unterschrift2.person) {
          this.unterschrift2 = val.unterschrift2;
        } else {
          this.unterschrift2 = { person: { personName: textNotLinked } };
        }
      },
    },
  },

  created() {
    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift1 &&
      this.korrespondenz.unterschrift1.person
    ) {
      this.unterschrift1 = this.korrespondenz.unterschrift1;
    } else {
      this.unterschrift1 = { person: { personName: textNotLinked } };
    }

    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift2 &&
      this.korrespondenz.unterschrift2.person
    ) {
      this.unterschrift2 = this.korrespondenz.unterschrift2;
    } else {
      this.unterschrift2 = { person: { personName: textNotLinked } };
    }
  },

  mounted: function () {
    if (this.unterschriften.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    unterschrift1Updated(val) {
      if (val && val.id) this.$emit("unterschrift1updated", val);
      else this.$emit("unterschrift1updated", null);
    },

    unterschrift2Updated(val) {
      if (val && val.id) this.$emit("unterschrift2updated", val);
      else this.$emit("unterschrift2updated", null);
    },
  },
};
</script>

<style>
</style>
