  <template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.campaign')"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_kampagne.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="kampagne.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <single-select-person
                      id="ansprechpartner"
                      :person="kampagne.verantwortlicher"
                      :label="$t('global.primarycontact')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setVerantwortlicher"
                      :tabindex="6"
                    />
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="kampagne.status"
                          label="bezeichnung"
                          v-bind:class="{ 'border-info': !passed }"
                          :options="kampagnestati"
                          :disabled="!editable"
                          :placeholder="$t('global.status')"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.status")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Zordnung -->

          <container-headline
            :headline="$t('global.planning')"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="kampagne.erstelltdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (kampagne.erstelltdatum = val)"
                      />
                      <label>{{ $t("global.recorded") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="kampagne.startdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (kampagne.startdatum = val)"
                      />
                      <label>{{ $t("global.launched") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="kampagne.enddatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (kampagne.enddatum = val)"
                      />
                      <label>{{ $t("global.finished") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Zuordnung -->
          </div>

          <!-- ENDE Rechte Seite -->
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.description')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="kampagne.beschreibung"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      ></textarea>
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <div v-if="kampagne.id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'kampagne',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.participants") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'kampagne',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.entries") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <kampagne-teilnehmer-liste
                    :kampagne="id"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                  />
                </div>

                <div v-show="anzeige === 1">
                  <kampagne-eintraege-liste
                    :kampagne="id"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="kampagne-loeschen-modal"
      @confirmed="kampagneloeschen"
    />

    <change-watcher-alert />
  </div>
</template>

  <script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import KampagneTeilnehmerListe from "@/components/crm/KampagneTeilnehmerListe.vue";
import KampagneEintraegeListe from "@/components/crm/KampagneEintraegeListe.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";

export default {
  name: "Kampagne",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    LoeschenModal,
    SingleSelectPerson,
    KampagneTeilnehmerListe,
    KampagneEintraegeListe,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$t("global.campaign");
      },
    };
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      kampagne: {
        verantwortlicher: null,
      },
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    kampagnestati: {
      get() {
        return this.$store.state.crm.kampagnestati;
      },
    },
  },
  watch: {},
  created() {
    this.loading = true;

    this.loadKampagne();
  },
  mounted: function () {
    if (this.kampagnestati.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);
  },
  methods: {
    loadKampagne() {
      if (this.id) {
        Api.get("crm/kampagne/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeKampagne(response.data);

            this.loading = false;
          }
        );
      } else {
        let today = new Date(Date.now());

        this.kampagne.verantwortlicher = this.angemeldeterMitarbeiter.person;
        this.kampagne.erstelltdatum = today.toLocaleDateString();
        this.kampagne.status = this.kampagnestati.find(
          (status) => status.id === this.$CONST("CONFIG").KAMPAGNESTATUS
        );

        this.editable = true;
        this.loading = false;
      }
    },

    abbrechen() {
      this.$router.push({ name: "kampagne-liste" });
    },

    initializeKampagne(kampagne) {
      if (kampagne) {
        this.kampagne = kampagne;

        this.editable = true;

        if (this.berechtigungen.m_kampagne.update == false)
          this.editable = false;

        this.initChangeWatcher(this.kampagne);
      } else {
        console.error("kampagne ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;
      var json = this.kampagne;

      if (!this.id) {
        Api.post("crm/kampagne/", json)
          .then((response) => {
            this.initializeKampagne(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.campaignsavedsuccessfully"),
            });

            this.$router.replace({
              name: "kampagne",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("crm/kampagne/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeKampagne(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.campaignsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "kampagne-loeschen-modal");
    },

    kampagneloeschen() {
      Api.delete("crm/kampagne/", { data: [this.id] }).then(() => {
        this.$router.push({ name: "kampagne-liste" });
      });
    },

    setVerantwortlicher(person) {
      this.kampagne.verantwortlicher = person;
    },
  },
};
</script>

<style></style>
