<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            {{ "Subventionen mutieren" }}
          </h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      headline="Zu mutierende Felder"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row"></div>
                          <div class="col-xl-5">
                            <div class="form-group">
                              <input
                                class="form-control"
                                placeholder="Neueintritt entfernen"
                                readonly
                              />
                              <label>Neueintritt</label>
                            </div>
                          </div>
                          <div class="row"></div>
                          <div class="row">
                            <div class="col-xl-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="speichern"
              :disabled="invalid"
            >
              Speichern
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "MassenmutationModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
  },
  props: {
    ids: {
      type: Array,
    },
    objects: {
      type: Array,
    },
  },
  data() {
    return {
      beitragId: null,
      subventionsbeitrag: {},
    };
  },
  computed: {},
  watch: {},
  async created() {},
  methods: {
    inititalizeBeitrag(beitrag) {
      if (beitrag) {
        this.beitragId = beitrag.id;
        this.subventionsbeitrag = beitrag;
      } else this.clear();
    },
    clear() {
      console.log("clear");
      this.subventionsbeitrag = {};
    },

    beitragToJson() {
      return this.subventionsbeitrag;
    },

    speichern() {
      Api.put("bildung/subvention/massenmutation/", {
        params: { ids: this.ids },
      }).then(() => {
        this.closeModal();
      });
    },

    closeModal() {
      this.shown = false;
      this.clear();
      // this.$emit("beitragChanged");
      $("#massenmutation-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>
