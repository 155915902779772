<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.item')"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>
              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="ml-auto mr-4">
                <button
                  :disabled="!artikelid"
                  class="btn btn-danger"
                  @click="loeschen"
                >
                  <font-awesome-icon icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="artikel.artikelnummer"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.itemnum") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="artikel.kurzbezeichnung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.shortdesignation") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model="artikel.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      rows="3"
                    />
                    <label>{{ $t("global.designation") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="artikel.artikeltyp"
                      :options="artikeltypen"
                      label="bezeichnung"
                      placeholder=" "
                    >
                      <span slot="no-options">{{
                        $t("global.noitemtypefound")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.itemtype") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="artikel.einheit"
                      :options="artikeleinheiten"
                      label="bezeichnung"
                      placeholder=" "
                    >
                      <span slot="no-options">{{
                        $t("global.noitemunitfound")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.itemunit") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Finanzbuchhaltung -->
        <container-headline
          :headline="$t('global.financialaccounting')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="artikel.mwstcode"
                      :options="mwstCodes"
                      label="bezeichnung"
                      :placeholder="$t('global.vatcode')"
                    >
                      <span slot="no-options">{{
                        $t("global.novatcodefound")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.vatcode") }}</label>
                  </div>
                </div>
                <div class="col-xl-6"></div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="artikel.ertragskonto"
                      :options="konten"
                      :get-option-label="
                        (k) => `${k.kontonummer} / ${k.bezeichnung}`
                      "
                      label="bezeichnung"
                      placeholder=" "
                    >
                      <span slot="no-options">{{
                        $t("global.norevenueaccountfound")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.revenueaccount") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="artikel.ertragskostenstelle"
                      :options="kostenstellen"
                      :get-option-label="
                        (k) => `${k.kostenstellennummer} / ${k.bezeichnung}`
                      "
                      label="bezeichnung"
                      placeholder=" "
                    >
                      <span slot="no-options">{{
                        $t("global.noincomecostcenterfound")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.incomecostcenter") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Zuordnung -->
        <!-- START Subventionsdaten -->

        <!-- ENDE Subventionsdaten -->
      </div>
      <!--ENDE rechte Seite -->
      <!-- START Preise -->

      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <container-headline :headline="$t('global.prices')" :col="6"
          ><button class="btn btn-success" @click="addPreis">
            <font-awesome-icon icon="fa-regular fa-plus" /><span class="pl-2">{{
              $t("global.new")
            }}</span>
          </button></container-headline
        >

        <div class="row mb-3">
          <div class="col-xl-12 block belegliste br-t-l-0">
            <div>
              <b-table
                ref="preistable"
                tbody-tr-class="item"
                :items="preise"
                :fields="fields"
                sort-icon-left
                small
                responsive
                class="d-flex justify-content-between text-center"
                fixed
              >
                <template #cell(nr)="data">
                  <div class="col-7 text-center mx-auto">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template #cell(delete_Preis)="data">
                  <div
                    class="clickable col-1 ml-auto"
                    @click="deletePreis(data.index)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      :style="{ color: '#b5cfdc' }"
                    />
                  </div>
                </template>
                <template #cell(input_Preis)="data">
                  <div class="col-4 mx-auto">
                    <b-form-input
                      v-model="data.item.preis"
                      number
                      required
                      type="number"
                      size="sm"
                    >
                    </b-form-input>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>

      <!-- ENDE Preise -->
    </div>
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />{{ $t("global.deletereceipt") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("global.doyourwantdeletereceipts") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import { LADE_AUSWAHLWERTE_ARTIKEL } from "@/store/artikel/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Artikel",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    ChangeWatcherAlert,
  },
  metaInfo() {
    return {};
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    artikelid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      artikel: { einheit: { id: "17522328C93", bezeichnung: "Anzahl" } },
      subvention: {},
      preise: [],
      fields: [
        "nr",

        {
          key: "input_Preis",
          label: this.$t("global.price"),
        },
        {
          key: "delete_Preis",
          label: "",
        },
      ],
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.beleg) {
        if (this.kategorieObj)
          return `${this.kategorieObj.bezeichnung} ${this.beleg.nummer}`;
        return `${this.$t("global.receipt")} ${this.beleg.nummer}`;
      } else {
        if (this.kategorieObj)
          return `${this.$t("global.New")} ${this.kategorieObj.bezeichnung}`;
        return this.$t("global.newreceipt");
      }
    },
    artikeltypen: {
      get() {
        return this.$store.state.artikel.artikeltypen;
      },
    },
    artikeleinheiten: {
      get() {
        return this.$store.state.artikel.artikeleinheiten;
      },
    },

    mwstCodes: {
      get() {
        return this.$store.state.artikel.mehrwertsteuercodes;
      },
    },

    konten: {
      get() {
        return this.$store.state.artikel.konten;
      },
    },
    kostenstellen: {
      get() {
        return this.$store.state.artikel.kostenstellen;
      },
    },
  },

  created() {
    if (this.artikelid) {
      Api.get("artikel/", { params: { id: this.artikelid } }).then(
        (response) => {
          this.initializeArtikel(response.data);
        }
      );
      Api.get("artikel/preis/", {
        params: { artikel: this.artikelid },
      }).then((response) => {
        if (response.data.length != 0) {
          this.preise = response.data;
        } else {
          this.preise = [];
        }
      });
    }
    if (this.artikeltypen.length == 0)
      this.$store.dispatch(`artikel/${LADE_AUSWAHLWERTE_ARTIKEL}`);
  },
  mounted: function () {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "artikelliste" });
    },
    initializeArtikel(artikel) {
      if (artikel) {
        this.artikel = artikel;

        this.initChangeWatcher(this.artikel);
      } else {
        console.error("artikel ist null");
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.artikel);

      if (this.artikel.artikeltyp) json.artikeltyp = this.artikel.artikeltyp;
      if (this.artikel.einheit) json.einheit = this.artikel.einheit.id;
      if (this.artikel.ertragskostenstelle)
        json.ertragskostenstelle = this.artikel.ertragskostenstelle;
      if (this.artikel.ertragskonto)
        json.ertragskonto = this.artikel.ertragskonto;

      if (this.preise) json.preise = this.preise;

      if (!this.artikelid) {
        Api.post("artikel/", json)
          .then((response) => {
            this.initializeArtikel(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.receiptsuccessfullysaved"),
            });

            this.$router.replace({
              name: "artikel",
              params: { artikelid: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("artikel/", json, { params: { id: this.artikelid } })
          .then((response) => {
            this.initializeArtikel(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.itemsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("artikel/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "artikelliste" });
      });
    },
    addPreis() {
      this.preise.push({ preis: 0 });
    },
    deletePreis(index) {
      this.preise.splice(index, 1);
    },
  },
};
</script>

<style>
</style>
