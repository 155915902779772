import {
  SET_FILTER,
  SET_INFO_STUDIERENDE,
  SET_INFO_MITARBEITENDE,
  SET_INFO_KUNDEN,
  SET_INFO_OPENBALANCE,
  SET_INFO_INVOICEDRAFT,
} from "./mutations.type";

import { SET_LISTEN_FILTER, LOAD_DASHBOARD_INFO } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    list: {},
    dashboardInfo: {
      studierende: 0,
      mitarbeitende: 0,
      kunden: 0,
      openbalance: 0,
    },
  },
  mutations: {
    [SET_FILTER](state, val) {
      state.list[val.list] = val.state;
    },
    [SET_INFO_STUDIERENDE](state, val) {
      state.dashboardInfo.studierende = val;
    },
    [SET_INFO_MITARBEITENDE](state, val) {
      state.dashboardInfo.mitarbeitende = val;
    },
    [SET_INFO_KUNDEN](state, val) {
      state.dashboardInfo.kunden = val;
    },
    [SET_INFO_OPENBALANCE](state, val) {
      state.dashboardInfo.openbalance = val;
    },
    [SET_INFO_INVOICEDRAFT](state, val) {
      state.dashboardInfo.invoicedraft = val;
    },
  },
  actions: {
    async [SET_LISTEN_FILTER](context, payload) {
      context.commit(SET_FILTER, payload);
    },
    async [LOAD_DASHBOARD_INFO](context) {
      let response = await Api.get("dashboard/");
      context.commit(SET_INFO_STUDIERENDE, response.data.studierende);
      context.commit(SET_INFO_MITARBEITENDE, response.data.mitarbeitende);
      context.commit(SET_INFO_KUNDEN, response.data.kunden);
      context.commit(SET_INFO_OPENBALANCE, response.data.openbalance);
      context.commit(SET_INFO_INVOICEDRAFT, response.data.invoicedraft);
    },
  },
};
