<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            {{ "Beiträge erstellen" }}
          </h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      headline="Suchkriterien"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.subventionsstatus"
                                  label="bezeichnung"
                                  :options="subventionsstati"
                                  placeholder="Subventionsstatus"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>Subventionsstatus</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.ausbildungstatus"
                                  label="bezeichnung"
                                  :options="ausbildungsstati"
                                  placeholder="Bildung Status"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>Status Bildung</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <date-picker
                                  date-format="dd.mm.yy"
                                  :initial="beitragskriterium.stichtag"
                                  placeholder=" "
                                  :show-icon="true"
                                  @update="
                                    (val) => (beitragskriterium.stichtag = val)
                                  "
                                />
                                <label>Stichtag</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.beitragsintervall"
                                  label="bezeichnung"
                                  :options="beitragsintervalle"
                                  :placeholder="$t('global.subsidycontributionstatus')"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>{{ $t("global.subsidycontributionstatus") }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <container-headline
                      headline="Beitrag"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <date-picker
                                  date-format="dd.mm.yy"
                                  :initial="beitragskriterium.antragsdatum"
                                  placeholder=" "
                                  :show-icon="true"
                                  @update="
                                    (val) =>
                                      (beitragskriterium.antragsdatum = val)
                                  "
                                />
                                <label>Antragsdatum</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <date-picker
                                  date-format="dd.mm.yy"
                                  :initial="beitragskriterium.abrechnungsdatum"
                                  placeholder=" "
                                  :show-icon="true"
                                  @update="
                                    (val) =>
                                      (beitragskriterium.abrechnungsdatum = val)
                                  "
                                />
                                <label>Abrechnungsdatum</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-4">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.antragsstatus"
                                  label="bezeichnung"
                                  :options="antragsstati"
                                  placeholder="Antragsstatus"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>Antragsstatus</label>
                              </div>
                            </div>
                            <div class="col-xl-4">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.abrechnungsstatus"
                                  label="bezeichnung"
                                  :options="abrechnungsstati"
                                  placeholder="Abrechnungsstatus"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>Abrechnungsstatus</label>
                              </div>
                            </div>
                            <div class="col-xl-4">
                              <div class="form-group">
                                <v-select
                                  v-model="beitragskriterium.abrechnungsperiode"
                                  label="bezeichnung"
                                  :options="abrechnungsperioden"
                                  placeholder="Abrechnungsperiode"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>Abrechnungsperiode</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="speichern"
              :disabled="invalid"
            >
              Erstellen
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";

export default {
  name: "SubventionsbeitragErstellenModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    DatePicker,
  },
  props: {
    subventionsstati: {
      type: Array,
    },
    ausbildungsstati: {
      type: Array,
    },
  },
  data() {
    return {
      count: 0,
      beitragskriterium: {
        subventionsstatus: {},
        ausbildungstatus: {},
        stichtag: "",
        beitragsintervall: {},
        antragsdatum: "",
        antragsstatus: {},
        abrechnungsdatum: "",
        abrechnungsperiode: {},
        abrechnungsstatus: {},
      },
    };
  },
  computed: {
    beitragsintervalle: {
      get() {
        return this.$store.state.subventionbeitrag.beitragsintervalle;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsstati;
      },
    },
    antragsstati: {
      get() {
        return this.$store.state.subventionbeitrag.antragsstati;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsperioden;
      },
    },
  },
  watch: {},
  async created() {},
  mounted() {
    if (this.beitragsintervalle.length == 0) {
      this.$store.dispatch(
        `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
      );
    }
  },
  methods: {
    clear() {
      console.log("clear");
      this.beitragskriterium = {
        subventionsstatus: {},
        ausbildungstatus: {},
        stichtag: "",
        beitragsintervall: {},
        antragsdatum: "",
        antragsstatus: {},
        abrechnungsdatum: "",
        abrechnungsperiode: {},
        abrechnungsstatus: {},
      };
    },

    speichern() {
      var json = Object.assign({}, this.beitragskriterium);

      console.log(json);

      if (json) {
        Api.post("subvention/beitrag/", json).then((response) => {
          this.count = response.count;
          this.closeModal();
        });
      } /*else {
      }
      */
    },

    closeModal() {
      this.shown = false;
      this.clear();
      $("#subventionsbeitrag-erstellen-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>
