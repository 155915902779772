var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kopf",class:{ 'bg-turkis': _vm.einkauf }},[_c('notifications'),_c('div',{staticClass:"container-fluid",style:(_vm.systemColor())},[_c('div',{staticClass:"left-buttons",staticStyle:{"z-index":"5"}},[_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":"/"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-home"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.home")))])],1)],1),_c('div',{staticClass:"navbar-button"},[_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-left","size":"lg"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.back")))])],1)]),_c('div',{staticClass:"navbar-button"},[_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.$router.go(1)}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-right","size":"lg"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.forward")))])],1)]),_c('div',{staticClass:"navbar-button"},[_c('a',{attrs:{"href":"#","onclick":"window.location.reload(true);"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-sync"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.refresh")))])],1)])]),_c('div',{staticClass:"HeaderTitle text-left ml-5",staticStyle:{"padding-left":"150px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$CONST("CONFIG").APPNAME))]),_c('span',{staticStyle:{"font-size":"14px","padding-left":"10px"}},[_vm._v(_vm._s(_vm.$CONST("CONFIG").APPVERSION)+" ")])]),_c('div',{staticClass:"right-buttons"},[(
          _vm.berechtigungen.m_geschaeftspartner.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":"/geschaeftspartner/personenliste"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-user-friends"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.people")))])],1)],1):_vm._e(),(
          _vm.berechtigungen.m_geschaeftspartner.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":"/geschaeftspartner/firmenliste"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-building"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.companies")))])],1)],1):_vm._e(),(
          _vm.berechtigungen.m_finanzen.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":{ name: 'auftraege' }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-file-invoice-dollar"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.invoices")))])],1)],1):_vm._e(),(
          _vm.berechtigungen.m_korrespondenz.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":{ name: 'korrespondenz-liste' }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-print"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.correspondence")))])],1)],1):_vm._e(),(
          _vm.berechtigungen.m_korrespondenz.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":{ name: 'mail-liste' }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-envelope-open-text"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.emails")))])],1)],1):_vm._e(),(
          _vm.berechtigungen.m_anmeldungen.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":{ name: 'anmeldungen-liste' }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-sign-in-alt"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.registrations")))])],1)],1):_vm._e(),_c('div',{staticClass:"usersign",staticStyle:{"padding-left":"10px","top":"11px"}}),_c('SwitchLanguage'),(
          _vm.berechtigungen.g_einstellungen.read &&
          _vm.$CONST('CONFIG').APPTYPE != 'DESKA'
        )?_c('div',{staticClass:"navbar-button"},[_c('router-link',{attrs:{"to":"/einstellungen/dashboard"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-sliders-v-square"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.settings")))])],1)],1):_vm._e(),_c('div',{staticClass:"navbar-button"},[_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.logout()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-door-open"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.logout")))])],1)]),_c('div',{staticClass:"usersign",staticStyle:{"padding-left":"25px","top":"11px"}},[_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"none-decoration",attrs:{"href":"","title":"Profil","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('font-awesome-icon',{staticStyle:{"margin-bottom":"0.35rem"},attrs:{"aria-hidden":"true","icon":"fa-duotone fa-user","size":"2x"}}),_c('span',[_vm._v(_vm._s(_vm.name))])],1),_c('div',{staticClass:"dropdown-menu",staticStyle:{"background-color":"#124862","margin-left":"0px","margin-top":"5px","border-radius":"0 0 5px 5px"},attrs:{"aria-labelledby":"dropdownMenuLink"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"","data-toggle":"modal","data-target":"#passwort-aendern-modal"}},[_c('span',[_vm._v(_vm._s(_vm.$t("navbar.passwordchange")))])])])])])],1)]),_c('passwort-aendern-modal',{attrs:{"id":"passwort-aendern-modal"},on:{"success":_vm.showSuccessNotification}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }