<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.journalentries')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neuerJournalEintrag"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-ticket" class="mr-2" />
              {{ $t("global.newjournalentry") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="listData"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #cell(edit)="row" class="d-flex align-items-center">
                  <div
                    class="ml-5"
                    role="button"
                    @click="details(row.item)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pencil" size="sm" />
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="journaleintrag-loeschen-modal"
      @confirmed="journalEintragLoeschen"
      :selectedIds="selectedIds"
    />

    <journal-eintrag-modal
      @journalEintragCreated="journalEintragErstellt"
      @journalEintragChanged="journalEintragGeaendert"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import JournalEintragModal from "@/components/Journal/JournalEintragModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    JournalEintragModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    ftable: {
      type: String,
      required: true,
    },
    fkey: {
      type: String,
      required: true,
    },
    personID: {
      type: String,
      required: false,
    },
    person: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      listData: [],
      sortBy: "verantwortlicher.personName",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "datum",
          sortable: true,
          label: this.$t("global.date"),
          formatter: (value) => {
            return value ? value : "-";
          },
        },
        {
          key: "zeit",
          sortable: true,
          label: this.$t("global.Time"),
          formatter: (value) => {
            return value ? value : "-";
          },
        },
        {
          key: "typ.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },
        {
          key: "status.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "person",
          sortable: true,
          label: this.$t("global.person"),
          formatter: (value) => {
            return value ? (value ? value.personName : "") : "generell";
          },
        },
        {
          key: "verantwortlicher",
          sortable: true,
          label: this.$t("global.Responsible"),
          formatter: (value) => {
            return value ? value.personName : "-";
          },
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        { key: "edit", sortable: false, label: this.$t("global.edit") },
      ],
    };
  },
  watch: {
    shown(val) {
      if (val && this.listData.length == 0) this.getJournalEintraege();
    },
  },
  computed: {},
  mounted() {
    if (this.shown && this.listData.length == 0) this.getJournalEintraege();
    this.setHeaders("global", [
      "",
      "date",
      "Time",
      "",
      "status",
      "person",
      "Responsible",
      "designation",
    ]);
    this.setHeaders("dashboard", [
      "",
      "",
      "",
      "type",
    ]);
  },
  created() {},
  methods: {
    getJournalEintraege() {
      this.isBusy = true;

      Api.get("journal/journaleintrag/", {
        params: {
          pers: this.personID,
          ftable: this.ftable,
          fkey: this.fkey,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.listData = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(journalEintrag) {
      this.$bus.$emit("journaleintrag-modal-show", journalEintrag);
    },

    neuerJournalEintrag() {
      let json = {
        verantwortlicher: this.angemeldeterMitarbeiter.person,
        person: this.person,
        neu: true,
      };
      this.$bus.$emit("journaleintrag-modal-show", json);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "journaleintrag-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nojournalfordeletion"),
        });
      }
    },

    journalEintragLoeschen() {
      console.log("hossa");
      Api.delete("journal/journaleintrag/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getJournalEintraege();
      });
    },

    journalEintragErstellt(journalEintrag) {
      this.isBusy = true;

      let json = journalEintrag;
      console.log(json);
      json.foreigntable = this.ftable;
      json.foreignkey = this.fkey;

      Api.post("journal/journaleintrag/", json)
        .then(() => {
          this.getJournalEintraege();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.journalsuccessfullysaved"),
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    journalEintragGeaendert(journalEintrag) {
      this.isBusy = true;

      let json = journalEintrag;

      Api.put("journal/journaleintrag/", json, {
        params: { id: journalEintrag.id },
      })
        .then(() => {
          this.getJournalEintraege();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.journalsuccessfullysaved"),
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>