<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- AKTIONEN -->

      <container-headline
        class="col-xl-12"
        :headline="$t('global.exams')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              v-if="editable"
              class="btn btn-success"
              @click="pruefungenSpeichern"
            >
              {{ $t("global.saveexam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="addPruefung"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.exam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="
                showAlertModal(
                  'Prüfung sperren',
                  'Wollen sie die ausgewählte Prüfung wirklich sperren?',
                  null
                )
              "
            >
              {{ $t("global.lockexam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="pruefungWiederoeffnen"
            >
              {{ $t("global.unlockexam") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              v-if="editable"
              class="btn btn-danger"
              @click="pruefungLoeschen"
            >
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>

        <!-- INHALT -->
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="pruefungentable"
              tbody-tr-class="item"
              :items="pruefungen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              selectable
              select-mode="single"
              @row-selected="onRowSelected"
              responsive
              small
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">{{ $t("global.selected") }}</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">{{ $t("global.notselected") }}</span>
                </template>
              </template>

              <template v-slot:cell(bezeichnung)="row">
                <b-form-input
                  v-model="row.item.bezeichnung"
                  :on-change="checkBezeichnung(row.index)"
                  :readonly="!row.item.editable || !editable"
                  size="sm"
                ></b-form-input>
              </template>

              <template v-slot:cell(datum)="row">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="row.item.datum"
                  :disabled="!row.item.editable || !editable"
                  :show-icon="true"
                  @update="(val) => (row.item.datum = val)"
                />
              </template>

              <template v-slot:cell(gewichtung)="row">
                <b-form-input
                  v-model="row.item.gewichtung"
                  :on-change="checkGewichtung(row.index)"
                  :readonly="!row.item.editable || !editable"
                  size="sm"
                ></b-form-input>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <alert-modal
      id="modal-alert"
      :title="alertModal.title"
      :text="alertModal.text"
      :color="alertModal.color"
      :eventName="'abschliessenConfirmed'"
      @abschliessenConfirmed="pruefungAbschliessen"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import AlertModal from "@/components/Modals/AlertModal";
import reiter from "@/mixins/Reiter";

import { textNoValue } from "@/defaultTexts.js";

export default {
  components: {
    ContainerHeadline,
    DatePicker,
    BSpinner,
    AlertModal,
  },
  mixins: [reiter],
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "nummer",
      sortDesc: false,
      pruefungen: [],
      invalidFields: {},
      selectedId: null,
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
      fields: [
        { key: "selected", label: "" },
        {
          key: "nummer",
          class: "text-center",
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id == closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          sortable: true,
          label: this.$t("global.number"),
        },
        {
          key: "datum",
          class: "text-center",
          sortable: true,
          label: this.$t("global.date"),
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id == closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
        },
        {
          key: "bezeichnung",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id == closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.designation"),
        },
        {
          key: "durchschnitt",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id == closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.average"),
        },
        {
          key: "gewichtung",
          class: "text-center",
          sortable: true,
          tdClass: function (value, key, item) {
            if (item.status) {
              return item.status.id == closed ? "pruefungClosed" : "";
            } else {
              return { id: "" };
            }
          },
          label: this.$t("global.weighting"),
        },
      ],
    };
  },
  watch: {
    shown(val) {
      if (val && this.pruefungen.length == 0) this.getPruefungen();
    },
  },
  computed: {
    closed: function () {
      return this.$CONST("PRUEFUNGSTATI").CLOSED;
    },

    planned: function () {
      return this.$CONST("PRUEFUNGSTATI").PLANNED;
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("pruefungSpeichern", () => {
      console.log("pruefungen speichern wird aufgerufen.");
      this.pruefungenSpeichern();
    });
    if (this.shown && this.pruefungen.length == 0) this.getPruefungen();
    this.setHeaders("global", [
      "",
      "number",
      "date",
      "designation",
      "average",
      "weighting",
    ]);
  },
  methods: {
    showAlertModal(title, text, color) {
      if (this.pruefungen.length > 0) {
        this.alertModal.title = title;
        this.alertModal.text = text;
        this.alertModal.color = color;
        $("#modal-alert").modal("show");
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noexamselected"),
        });
    },
    checkGewichtung(index) {
      let val = this.pruefungen[index].gewichtung;
      this.invalidFields["gewichtung" + index] = false;
      if (val == undefined || val == "") this.pruefungen[index].gewichtung = 1;
      else if (val <= 0 || val > 10) {
        this.pruefungen[index].invalid = false;
        this.invalidFields["gewichtung" + index] = true;
      }
    },
    checkBezeichnung(index) {
      let val = this.pruefungen[index].bezeichnung;
      if (val == undefined || val == "")
        this.pruefungen[index].bezeichnung = textNoValue;
    },

    getPruefungen() {
      this.isBusy = true;

      let plannedId = this.planned;

      Api.get("noten/pruefung/", {
        params: {
          noteneingabe: this.noteneingabe,
          page: this.page,
          count: 9999,
        },
      })
        .then((response) => {
          this.pruefungen = response.data;
          let that = this;
          this.pruefungen.forEach(function (pruefung, index) {
            $;
            console.log("index " + index);
            pruefung.durchschnitt = that.formatNotenWert(pruefung.durchschnitt);
            pruefung.nummer = index + 1;
            if (!pruefung.status) pruefung.status = { id: plannedId };
            pruefung.status.id == plannedId
              ? (pruefung.editable = true)
              : (pruefung.editable = false);
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    formatNotenWert(value) {
      return value == undefined || value == ""
        ? ""
        : parseFloat(value).toFixed(1);
    },

    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },
    pruefungAbschliessen() {
      if (!this.selectedId) {
        return;
      }

      let pruefungIndex = this.pruefungen.findIndex(
        (p) => p.id === this.selectedId
      );

      if (pruefungIndex.length == -1) {
        console.log("pruefung nicht gefunden");
        return;
      }

      this.pruefungen[pruefungIndex].status = {
        id: this.$CONST("PRUEFUNGSTATI").CLOSED,
      };
      this.pruefungen[pruefungIndex].editable = false;

      this.pruefungenSpeichern();
    },
    pruefungWiederoeffnen() {
      if (!this.selectedId) return;

      let pruefungIndex = this.pruefungen.findIndex(
        (p) => p.id === this.selectedId
      );

      if (pruefungIndex == -1) {
        console.log("pruefung nicht gefunden");
        return;
      }

      this.pruefungen[pruefungIndex].status = {
        id: this.$CONST("PRUEFUNGSTATI").PLANNED,
      };

      this.pruefungen[pruefungIndex].editable = true;

      this.pruefungenSpeichern();
    },
    pruefungenSpeichern() {
      if (!this.validateForm()) return;

      this.isBusy = true;

      Api.put("noten/pruefung/", this.pruefungen, {
        params: {
          noteneingabe: this.noteneingabe,
          page: this.page,
          count: 9999,
        },
      })
        .then(() => {
          console.log("jetzt wird table refresh gemacht");
          this.$refs.pruefungentable.refresh();
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    onRowSelected(items) {
      this.selectedId = items.length == 0 ? null : items[0].id;
    },

    addPruefung() {
      /*** Triggert Erstellen und Speichern Prüfung auf Reiter Prüfungen ***/
      this.$bus.$emit("addPruefungRemote", this.noteneingabe);
    },
    pruefungLoeschen() {
      this.isBusy = true;

      Api.delete("noten/notenerfassung/", {
        params: {
          pruefung: this.selectedId,
          noteneingabe: this.noteneingabe,
        },
      })
        .then(() => {
          this.$bus.$emit("deletePruefungRemote");
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style>
.pruefungClosed {
  color: lightgray;
}

.pruefungClosed input {
  color: lightgray;
}
</style>