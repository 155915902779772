<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>{{ this.lastValidBereich }}</h4>
        </div>
        <div class="modal-body">
          <div class="row p-0">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model="selectedItem.bezeichnung"
                  class="form-control"
                  placeholder=" "
                  ref="inputEinstellungen"
                />
                <label>{{ $t("global.designation") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button
            class="btn btn-primary"
            :key="buttonReset"
            v-shortkey.once="['ctrl', 's']"
            @click.once="uebernehmen"
            @shortkey="uebernehmen"
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import Api from "@/Api";

export default {
  name: "EinstellungenModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    lastValidBereich: {
      type: String,
    },
    selectedItem: {
      type: Object,
    },
    path: {
      type: String,
    },
  },
  data() {
    return {
      shown: false,
      buttonReset: 1,
    };
  },
  components: {
    ModalCloseButton,
  },
  computed: {},
  mounted() {
    this.$bus.$on("openEinstellungenModal", (id) => {
      if (!this.shown) {
        this.openModal(id);
      }
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;

      $("#" + id).modal({
        backdrop: "static",
        keyboard: false,
      });
      setTimeout(() => {
        if (this.$refs.inputEinstellungen) {
          this.$refs.inputEinstellungen.focus();
        }
      }, 500);
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
      this.buttonReset++;
    },

    uebernehmen() {
      this.isBusy = true;
      var json = Object.assign({}, this.selectedItem);

      if (this.selectedItem.id) {
        Api.put(this.path, json, {})
          .then((response) => {
            this.ladeFehler = false;
            this.closeModal();
            this.$emit("confirmed", response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text:
                this.lastValidBereich +
                " " +
                this.$t("notification.successfullyedited"),
            });
          })
          .catch((e) => {
            this.ladeFehler = true;
            this.isBusy = false;
          });
      } else {
        Api.post(this.path, json)
          .then((response) => {
            this.ladeFehler = false;
            this.closeModal();
            this.$emit("confirmed", response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text:
                this.lastValidBereich +
                " " +
                this.$t("notification.successfullysaved"),
            });
          })
          .catch((e) => {
            this.ladeFehler = true;
            this.isBusy = false;
          });
      }
    },
  },
};
</script>

<style>
</style>