<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.content')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-2">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="notiz.datum"
                      :disabled="!editable"
                      placeholder=" "
                      :show-icon="true"
                      @update="(val) => (notiz.datum = val)"
                    />
                    <label>{{ $t("global.date") }}</label>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="form-group">
                    <v-select
                      v-model="notiz.kontaktkategorie"
                      label="bezeichnung"
                      :options="kontaktkategorien"
                      :reduce="(kk) => kk.id"
                      :disabled="!editable"
                      :placeholder="$t('global.contactCategory')"
                    >
                      <span slot="no-options">{{
                        $t("notification.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.contactCategory") }}</label>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="form-group">
                    <v-select
                      v-model="notiz.kontaktart"
                      label="bezeichnung"
                      :options="kontaktarten"
                      :reduce="(ka) => ka.id"
                      :disabled="!editable"
                      :placeholder="$t('global.contacttype')"
                    >
                      <span slot="no-options">{{
                        $t("notification.taptosearch")
                      }}</span>
                    </v-select>
                    <label>{{ $t("global.contacttype") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-model.trim="notiz.bezeichnung"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.designation") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  :disabled="!editable"
                  v-model="notiz.beschreibung"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";
import DatePicker from "@/components/Datepicker";

import page from "@/mixins/Page";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { LADE_AUSWAHLWERTE_NOTIZEN } from "@/store/korrespondenz/actions.type";

export default {
  name: "NotizInhalt",
  components: {
    ContainerHeadline,
    DatePicker,
  },
  mixins: [],
  store,
  props: {
    notiz: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    navbarTitel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {
    kontaktkategorien: {
      get() {
        return this.$store.state.korrespondenz.kontaktkategorien;
      },
    },
    kontaktarten: {
      get() {
        return this.$store.state.korrespondenz.kontaktarten;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.kontaktarten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_NOTIZEN}`);
  },

  methods: {},
};
</script>

<style>
</style>
