import { SET_PRAESENZ_STATI } from "./mutations.type";

import { LADE_AUSWAHLWERTE_PRAESENZ } from "./actions.type";

import Api from "@/Api";

export default {
  namespaced: true,
  state: {
    praesenzstati: []
  },
  mutations: {
    [SET_PRAESENZ_STATI](state, val) {
      state.praesenzstati = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_PRAESENZ]({ commit }) {
      let response = await Api.get("praesenz/auswahlwerte/");
      commit(SET_PRAESENZ_STATI, response.data.praesenzstati);
    }
  }
};
