<template>
  <div class="col-xl-12 pr-4">
    <!--Start PERSON -->
    <container-headline
      v-if="mail.empfaenger.length > 0"
      :headline="$t('global.receiver') + ' (' + mail.empfaenger.length + ')'"
      :col="3"
    >
      <div class="row mt-2 d-flex">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneGPSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2">
          <button
            class="btn btn-danger"
            @click="oeffneLoeschenModal"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>
      </div>
    </container-headline>
    <div
      v-if="mail.empfaenger.length > 0"
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="receiverKey"
    >
      <div
        v-for="(item, index) in mail.empfaenger"
        v-bind:key="`empfaenger-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @contextmenu.prevent="openReceiver(item)"
          @click="selectReceiver(item)"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
          class="p-1"
        >
          {{ item.vorname + " " + item.name }}
        </div>
      </div>
    </div>
    <!--ENDE PERSON -->
    <!--Start Firma -->
    <container-headline
      v-if="mail.firma.length > 0"
      :headline="$t('global.receiver') + ' (' + mail.firma.length + ')'"
      :col="3"
    >
      <div class="row mt-2 d-flex">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneFirmaSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2">
          <button
            class="btn btn-danger"
            @click="oeffneLoeschenModal"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>
      </div>
    </container-headline>
    <div
      v-if="mail.firma.length > 0"
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="receiverKey"
    >
      <div
        v-for="(item, index) in mail.firma"
        v-bind:key="`firma-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @click="selectReceiverFirma(item)"
          @contextmenu.prevent="openReceiverFirma(item)"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
          class="p-1"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!--ENDE Firma -->
    <gpSucheModal
      v-if="mail.empfaenger.length > 0"
      :id="modalId"
      @confirmed="onGPResult"
    ></gpSucheModal>
    <loeschen-modal
      v-if="mail.empfaenger.length > 0"
      id="empfaenger-loeschen-modal"
      @confirmed="empfaengerLoeschen"
      :multiple="true"
    />

    <firmaSucheModal
      v-if="mail.firma.length > 0"
      :id="modalId"
      @confirmed="onFirmaResult"
    ></firmaSucheModal>
    <loeschen-modal
      v-if="mail.firma.length > 0"
      id="empfaenger-loeschen-modal"
      @confirmed="firmaLoeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import firmaSucheModal from "@/components/Geschaeftspartner/firmaSucheModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import { v4 as uuid } from "uuid";
import store from "@/store";

export default {
  name: "MassenMailSenderEmpfaenger",
  components: {
    ContainerHeadline,
    gpSucheModal,
    LoeschenModal,
    firmaSucheModal,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      sortBy: "personName",
      receiverKey: uuid(),
      sortDesc: false,
      selected: [],
      selectedIds: [],
      selectedEmpfaenger: [],
      recipients: [],
      modalId: "EmpfaengerHinzufuegen",

      fields: [
        { key: "selected", label: "" },
        {
          key: "personName",
          sortable: true,
          label: this.$t("global.receiver"),
        },
        {
          key: "mailadressen[0].bezeichnung",
          sortable: true,
          label: this.$t("global.mailaddress"),
        },
      ],
    };
  },
  computed: {},
  watch: {},

  created() {
    this.mail.empfaengermail = null;
  },

  mounted() {},

  methods: {
    openReceiver(receiver) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: receiver.id, anzeige: 0 },
      });
    },
    selectReceiver(receiver) {
      let realItem = this.mail.empfaenger.find(
        (item) => item.id == receiver.id
      );
      realItem.selected = receiver.selected ? false : true;

      console.log("real" + receiver.mailadressen[0].bezeichnung);

      this.selectedIds = this.mail.empfaenger
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.receiverKey = uuid();

      /*var id = this.selectedIds;
    var temp = this.mail.empfaenger.filter(function(temp){ return temp.id == id;} ).pop();

    this.mail.selectedEmpfaenger = temp;
    
    //this.mail.empfaenger = temp;
    this.recipients.push(temp.mailadressen[0].bezeichnung)
    console.log(temp.mailadressen[0].bezeichnung);*/
      if (!this.recipients.includes(receiver.mailadressen[0].bezeichnung)) {
        this.recipients.push(receiver.mailadressen[0].bezeichnung);
      } else {
        for (var i = 0; i < this.recipients.length; i++) {
          if (this.recipients[i] === receiver.mailadressen[0].bezeichnung) {
            this.recipients.splice(i, 1);
          }
        }
      }
      this.mail.empfaengermail = this.recipients.join(", ");
      console.log(this.mail.empfaengermail);
      this.$emit("updated");
    },

    openReceiverFirma(receiver) {
      this.$router.push({
        name: "firma",
        params: { id: receiver.id, anzeige: 0 },
      });
    },

    selectReceiverFirma(receiver) {
      let realItem = this.mail.firma.find((item) => item.id == receiver.id);
      realItem.selected = receiver.selected ? false : true;

      console.log("real" + receiver.mailadressen[0]?.bezeichnung);

      this.selectedIds = this.mail.firma
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.receiverKey = uuid();

      /*var id = this.selectedIds;
    var temp = this.mail.empfaenger.filter(function(temp){ return temp.id == id;} ).pop();

    this.mail.selectedEmpfaenger = temp;
    
    //this.mail.empfaenger = temp;
    this.recipients.push(temp.mailadressen[0].bezeichnung)
    console.log(temp.mailadressen[0].bezeichnung);*/
      if (!this.recipients.includes(receiver.mailadressen[0]?.bezeichnung)) {
        this.recipients.push(receiver.mailadressen[0]?.bezeichnung);
      } else {
        for (var i = 0; i < this.recipients.length; i++) {
          if (this.recipients[i] === receiver.mailadressen[0]?.bezeichnung) {
            this.recipients.splice(i, 1);
          }
        }
      }
      this.mail.empfaengermail = this.recipients.join(", ");
      console.log(this.mail.empfaengermail);
      this.$emit("updated");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "empfaenger-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noreceiverselected"),
        });
      }
    },
    empfaengerLoeschen() {
      this.selectedIds.forEach((id) => {
        this.mail.empfaenger = this.mail.empfaenger.filter(
          (empfaenger) => empfaenger.id != id
        );
      });
      this.receiverKey = uuid();
    },

    firmaLoeschen() {
      this.selectedIds.forEach((id) => {
        this.mail.firma = this.mail.firma.filter((firma) => firma.id != id);
      });
      this.receiverKey = uuid();
    },

    oeffneGPSucheModal() {
      this.$bus.$emit("openGeschaeftspartnerModal", this.modalId);
    },

    oeffneFirmaSucheModal() {
      this.$bus.$emit("openFirmaModal", this.modalId);
    },

    onGPResult(selectedPerson) {
      if (selectedPerson) {
        this.mail.empfaenger.push(selectedPerson[0]);

        this.mail.empfaenger.sort((a, b) => {
          return a.personName > b.personName;
        });

        this.receiverKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("notification.receiversuccessfullyadded"),
        });
      }
    },

    onFirmaResult(selectedFirma) {
      if (selectedFirma) {
        this.mail.firma.push(selectedFirma[0]);

        this.mail.firma.sort((a, b) => {
          return a.name > b.name;
        });

        this.receiverKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("notification.receiversuccessfullyadded"),
        });
      }
    },
    setAbsender(absender) {
      this.mail.absender = absender;

      if (absender.mailadressen.length > 0)
        this.mail.absendermail = absender.mailadressen[0].bezeichnung;
    },
  },
};
</script>

<style>
</style>
