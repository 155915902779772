<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-fluid modal-lernzielvereinbarungBewertungModal"
    >
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 v-if="istInhalt" class="modal-title">Lektionsinhalt</h4>
          <h4 v-if="istPlanung" class="modal-title">Anschlussplanung</h4>
        </div>
        <div class="modal-body m-3">
          <container-headline
            :headline="$t('global.lessoncontent')"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div
                      v-for="(bereich, bereichKey) in selectedFachMatrix"
                      v-bind:key="bereich.id"
                      class="row"
                    >
                      <div class="col-xl-12">
                        <div class="row">
                          <div
                            class="col-xl-12"
                            style="font-weight: 600; font-size: 14pt"
                          >
                            {{ bereiche[bereichKey] }}
                          </div>
                        </div>
                        <div class="row mt-2" style="font-size: 12pt">
                          <div
                            v-for="(sektion, sektionKey) in bereich"
                            v-bind:key="sektion.id"
                            class="col-xl-12"
                          >
                            <div class="row col-xl-12">
                              <div
                                class="col-xl-12"
                                style="
                                  background-color: #b5cfdc;
                                  border-top: 2px solid white;
                                  border-left: 2px solid white;
                                  font-weight: 600;
                                "
                              >
                                {{ sektionen[sektionKey] }}
                              </div>
                              <div
                                class="col-xl-2"
                                style="
                                  font-weight: 600;
                                  background-color: #bfbfbf;
                                  border-top: 2px solid white;
                                  border-left: 2px solid white;
                                "
                              >
                                Subsektion
                              </div>
                              <div
                                v-for="headerstufe in stufen"
                                v-bind:key="headerstufe.id"
                                class="col-xl-2 text-center"
                                style="
                                  font-weight: 600;
                                  background-color: #bfbfbf;
                                  border-top: 2px solid white;
                                  border-left: 2px solid white;
                                "
                              >
                                {{ headerstufe }}
                              </div>

                              <div
                                v-for="(subsektion, subsektionKey) in sektion"
                                v-bind:key="subsektion.id"
                                class="col-xl-12"
                              >
                                <div class="row">
                                  <div
                                    class="col-xl-2"
                                    style="
                                      background-color: #eaeaea;
                                      border-top: 2px solid white;
                                      border-left: 2px solid white;
                                    "
                                  >
                                    {{ subsektionen[subsektionKey] }}
                                  </div>

                                  <div
                                    v-for="stufe in subsektion"
                                    v-bind:key="stufe.id"
                                    class="col-xl-2"
                                    style="background-color: #e2f0d9"
                                  >
                                    <!--{{ stufen[stufekey] }}-->
                                    <div class="row">
                                      <div
                                        :class="
                                          sektionenList.includes(
                                            definition.definition.id
                                          )
                                            ? 'bg-green'
                                            : 'bg-medium-blue'
                                        "
                                        @click="
                                          selectInhalt(definition.definition.id)
                                        "
                                        v-for="definition in stufe"
                                        v-bind:key="definition.id"
                                        class="col-xl-12"
                                        style="
                                          border-top: 2px solid white;
                                          border-left: 2px solid white;
                                          padding: 10px;
                                        "
                                      >
                                        {{ definition.definition.beschreibung }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="(istInhalt || istPlanung || istPerson) && praesenzData">
                  <div
                    class="col-xl-12"
                    style="
                      background-color: rgb(181, 207, 220);
                      border-top: 2px solid white;
                      border-left: 2px solid white;
                      font-weight: 600;
                    "
                  >
                    {{ $t("global.comment") }}
                  </div>
                  <div
                    class="p-2"
                    style="
                      background-color: rgb(234, 234, 234);
                      border-top: 2px solid white;
                      border-left: 2px solid white;
                    "
                  >
                    <div class="form-group p-0 m-0">
                      <textarea
                        v-if="istInhalt && praesenzData"
                        v-model="termin.bemerkung_unterricht"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                      />
                      <textarea
                        v-if="istPlanung && praesenzData"
                        v-model="termin.bemerkung_planung"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                      />
                      <textarea
                        v-if="istPerson && praesenzData"
                        v-model="praesenz.unterrichtsinhalt_person"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div v-if="!istInhalt && !istPlanung">
                  <div
                    class="col-xl-12"
                    style="
                      background-color: rgb(181, 207, 220);
                      border-top: 2px solid white;
                      border-left: 2px solid white;
                      font-weight: 600;
                    "
                  >
                    {{ $t("global.comment") }}
                  </div>
                  <div
                    class="p-2"
                    style="
                      background-color: rgb(234, 234, 234);
                      border-top: 2px solid white;
                      border-left: 2px solid white;
                    "
                  >
                    <div class="form-group p-0 m-0">
                      <textarea
                        v-model="termin.bemerkung_unterricht"
                        class="form-control"
                        placeholder=" "
                        disabled="true"
                        rows="5"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="speichern">
              {{ $t("global.save") }}
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              {{ $t("global.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import { modal } from "@/mixins/Modal";

import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "LektionsInhaltModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    ModalCloseButton,
  },
  store,
  props: {
    id: {
      type: String,
      required: false,
      default: "lektionsInhalt-modal",
    },
    anmeldungId: {
      type: String,
      default: null,
    },
    termin: {
      type: Object,
      default: null,
    },
    veranstaltungID: {
      type: String,
      default: null,
    },
    veranstaltungNiveau: {
      type: String,
      default: null,
    },
    istInhalt: {
      type: Boolean,
      default: false,
    },
    istPlanung: {
      type: Boolean,
      default: false,
    },
    istPerson: {
      type: Boolean,
      default: false,
    },
    rasterID: {
      type: String,
      default: null,
    },
    praesenzProp: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      reverseMatrix: {},
      raster: {},
      matrix: [],
      faecher: {},
      bereiche: {},
      sektionen: {},
      definitionen: {},
      praesenzData: {
        termin: { bemerkung_unterricht: "", bemerkung_planung: "" },
      },
      praesenz: { termin: { bemerkung_unterricht: "" } },
      selectedFach: [],
      praesenzID: "",
      subsektionen: {},
      stufen: {},
      sektionenList: [],
      bemerkung_unterricht: null,
      lernzielvereinbarungBewertung: {
        id: null,
        bezeichnung: "",
        info: {
          datum: "",
          faecher: [
            {
              verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "" },
              bezeichnung: "",
            },
          ],
        },
        fortschritte: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
        lernziele: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
      },
    };
  },
  computed: {
    selectedFachMatrix: function () {
      return this.reverseMatrix[this.selectedFach];
    },
  },
  watch: {},
  mounted() {
    if (this.praesenzProp) this.praesenz = this.praesenzProp;
    this.$bus.$on("openLektionsInhaltModal", (ids) => {
      if (ids.modalId == this.id && !this.shown) {
        this.openModal();
        if (ids.praesenzID) {
          this.getPraesenz(ids.praesenzID);
        } else {
          this.getPraesenzData();
        }

        this.loadRaster();
      }
    });
  },
  methods: {
    getPraesenz(praesenzID) {
      this.praesenzID = praesenzID;
      this.isBusy = true;
      Api.get("bildung/praesenzverwaltung/", {
        params: { praesenzID: praesenzID },
      })
        .then((response) => {
          this.initializePraesenz(response);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getPraesenzData() {
      this.isBusy = true;

      Api.get("bildung/praesenzverwaltung/", {
        params: { veranstaltung: this.veranstaltungID, termin: this.termin.id },
      })
        .then((response) => {
          this.initializePraesenzData(response);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    initializePraesenz(response) {
      this.praesenz = response.data;
      this.sektionenList = [];
      this.praesenz.lektionsinhalt?.forEach((element) =>
        this.sektionenList.push(element.id)
      );
    },
    initializePraesenzData(response) {
      this.praesenzData = response.data;
      this.sektionenList = [];
      if (this.istInhalt == true) {
        this.praesenzData.termin.unterrichtsinhalt?.forEach((element) =>
          this.sektionenList.push(element.id)
        );
      }

      if (this.istPlanung == true) {
        this.praesenzData.termin.anschlussplanung?.forEach((element) =>
          this.sektionenList.push(element.id)
        );
      }
    },
    loadRaster() {
      this.loading = true;

      Api.get("bildung/kompetenzraster/", {
        params: { id: this.rasterID },
      }).then((response) => {
        this.initializeRaster(response.data);
      });
    },
    initializeRaster(raster) {
      if (raster) {
        this.raster = raster;
        Api.get("bildung/kompetenzraster/kompetenzdefinition/", {
          params: {
            rasterid: this.raster.id,
            niveauid: this.veranstaltungNiveau,
          },
        }).then((response) => {
          this.initializeMatrix(response.data);
        });
      } else {
        console.error("kompetenzraster ist null");
      }

      this.loading = false;
    },
    initializeMatrix(matrix) {
      if (matrix) {
        this.matrix = matrix;

        this.selectedFach = null;

        this.matrix.forEach((raster) => {
          let subsektion = raster.definition.subsektion;
          let sektion = subsektion.sektion;
          let bereich = sektion.bereich;
          let fach = raster.definition.fach;
          let stufe = raster.definition.stufe;
          let definition = raster.definition;

          if (!this.faecher[fach?.id]) this.faecher[fach?.id] = fach;
          if (!this.selectedFach) this.selectedFach = fach?.id;

          if (!this.reverseMatrix[fach?.id]) this.reverseMatrix[fach?.id] = {};

          if (!this.reverseMatrix[fach?.id][bereich?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id] = {};
            this.bereiche[bereich?.id] = bereich?.bezeichnung;
          }

          if (!this.reverseMatrix[fach?.id][bereich?.id][sektion?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id] = {};
            this.sektionen[sektion?.id] = sektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ] = {};
            this.subsektionen[subsektion?.id] = subsektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ][stufe?.id]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ][stufe?.id] = {};
            this.stufen[stufe?.id] = stufe?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ][stufe?.id][definition?.id]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
              subsektion?.id
            ][stufe?.id][definition?.id] = {};
            this.definitionen[definition?.id] = definition?.beschreibung;
          }

          this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][
            subsektion?.id
          ][stufe?.id][definition?.id] = raster; //[raster.id]
        });

        this.fachSelect++;
      } else {
        console.error("kompetenzmastrix ist null");
      }
    },
    selectInhalt(sektionObj) {
      if (this.sektionenList.includes(sektionObj)) {
        let indexOfObject = this.sektionenList.findIndex((object) => {
          return object == sektionObj;
        });
        this.sektionenList.splice(indexOfObject, 1);
      } else {
        this.sektionenList.push(sektionObj);
      }
      if (!this.istInhalt && !this.istPlanung) {
        this.praesenz.lektionsinhalt = this.sektionenList;
      } else if (this.istInhalt) {
        this.praesenzData.unterrichtsinhalt = this.sektionenList;
      } else if (this.istPlanung) {
        this.praesenzData.anschlussplanung = this.sektionenList;
      }
    },
    setDate(date) {
      this.lernzielvereinbarungBewertung?.fortschritte?.forEach(
        (fortschritt) => {
          fortschritt.bewertung.datum = date;
        }
      );

      this.lernzielvereinbarungBewertung?.lernziele?.forEach((ziel) => {
        ziel.bewertung.datum = date;
      });
    },
    closeModal() {
      this.shown = false;
      this.$emit("changed");
      $("#lektionsInhalt-modal").modal("hide");
    },
    speichern() {
      this.isBusy = true;
      if (this.praesenzID) {
        this.praesenz.lektionsinhalt = this.sektionenList;
        var json = Object.assign({}, this.praesenz);

        Api.put("bildung/praesenzverwaltung/", json, {
          params: { praesenzID: this.praesenzID, istPerson: this.istPerson },
        })
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t(
                "notification.learningobjectiveagreementsuccessfullysaved"
              ),
            });
            this.ladeFehler = false;
            this.initializePraesenz(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        if (this.istInhalt) {
          json = {
            unterrichtsinhalt: this.sektionenList,
            praesenzen: this.praesenzData.praesenzliste,
            termin: this.termin,
          };
        } else if (this.istPlanung) {
          json = {
            anschlussplanung: this.sektionenList,
            praesenzen: this.praesenzData.praesenzliste,
            termin: this.termin,
          };
        } else if (this.istPerson) {
          json = {
            unterrichtsinhalt_person: this.unterrichtsinhalt_person,
            praesenzen: this.praesenzData.praesenzliste,
            termin: this.termin,
          };
        }

        Api.put("/bildung/praesenzverwaltung/", json, {
          params: {
            veranstaltung: this.veranstaltungID,
            termin: this.termin.id,
            istInhalt: this.istInhalt,
            istPlanung: this.istPlanung,
          },
        })
          .then((response) => {
            if (!response.data.error) {
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: "Lektionsinhalte erfolgreich gespeichert.",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$notify({
              type: "danger",
              title: "Aktion nicht erfolgreich",
              text: "Fehler beim speichern.",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
.lernzielbewertungFaecher > div:last-child > div {
  border-bottom: 0 !important;
}
</style>
