<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.content')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row" v-if="mail.gesendet || mail.testmail_gesendet">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.sent") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.testmail_gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.testmailsent") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.absendermail"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.senderemail") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3">
              <single-select-person
                id="absender"
                :person="mail.absender"
                :label="$t('global.sender')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setAbsender"
              />
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="mail.absendermail"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.senderemail") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="mail.zeitpunkt"
                  :disabled="!editable"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => (mail.zeitpunkt = val)"
                />
                <label>{{ $t("global.date") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="mail.betreff"
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                    class="form-control"
                    placeholder=" "
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.subject")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <v-select
                  v-model="mail.mailbanner"
                  label="bezeichnung"
                  :options="mailbanner"
                  :disabled="!editable"
                  @input="mailBannerUpdated"
                  placeholder=" "
                  :clearable="true"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.mailbanner") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  :disabled="!editable"
                  v-model="mail.inhalt"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";
import DatePicker from "@/components/Datepicker";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import { LADE_AUSWAHLWERTE_MAIL } from "@/store/mail/actions.type";

import Api from "@/Api";
import { dropzoneOptionsAttachments } from "@/config/Dropzone";

export default {
  name: "MailInhalt",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      dropzoneOptions: dropzoneOptionsAttachments(
        Api.defaults.baseURL,
        this.id
      ),
    };
  },
  computed: {
    mailbanner: {
      get() {
        return this.$store.state.mail.mailbanner;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.mailbanner.length == 0)
      this.$store.dispatch(`mail/${LADE_AUSWAHLWERTE_MAIL}`);
  },

  methods: {
    uploadFiles() {
      this.$refs.dokumenteUpload.processQueue();
    },

    mailBannerUpdated(val) {
      if (val && val.id) this.$emit("mailbannerupdated", val);
      else this.$emit("mailbannerupdated", null);
    },

    setAbsender(absender) {
      this.mail.absender = absender;

      if (absender.mailadressen.length > 0)
        this.mail.absendermail = absender.mailadressen[0]?.bezeichnung;
    },
  },
};
</script>

<style>
</style>
