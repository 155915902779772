<template>
  <div>
    <container-headline
      :headline="$t('global.professionandeducation')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row" v-if="roles.isStudent || roles.isMitarbeiter">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.arbeitspensum"
                  :readonly="!editable"
                  class="form-control"
                  placeholder=" "
                  :tabindex="200"
                />
                <label>{{ $t("global.workload") }}</label>
              </div>
            </div>
            <div class="col-xl-6" v-if="roles.isStudent">
              <div class="form-group">
                <input
                  v-model.trim="person.aktuelle_taetigkeit"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                  :tabindex="201"
                />
                <label>{{ $t("global.currentactivity") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.funktion"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                  :tabindex="205"
                />
                <label>{{ $t("global.function") }}</label>
              </div>
            </div>

            <div class="col-xl-6">
              <div
                v-if="
                  roles.isDozent ||
                  roles.isMitarbeiter ||
                  roles.isDiplomarbeiten ||
                  roles.isReferent
                "
                class="form-group"
              >
                <v-select
                  v-model="person.funktionsbereich"
                  multiple
                  label="bezeichnung"
                  :options="funktionsbereiche"
                  :disabled="!editable"
                  :reduce="(fb) => fb.id"
                  :placeholder="$t('global.functionalarea')"
                  :tabindex="207"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.functionalarea") }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="
              !roles.isLieferant &&
              !roles.isTraegerschaft &&
              !roles.isDozent &&
              !roles.isArbeitgeber &&
              !roles.isJobCoach &&
              !roles.isPraxisbildner &&
              !roles.isIVBerater &&
              !roles.isNotfallkontakt &&
              $CONST('CONFIG').APPTYPE == 'Education'
            "
            class="row"
          >
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.lehrberuf"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                  :tabindex="202"
                />
                <label>{{ $t("global.apprenticeship") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.lehrbetrieb"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                  :tabindex="203"
                />
                <label>{{ $t("global.Company") }}</label>
              </div>
            </div>
          </div>
          <div
            class="row"
            v-if="
              ((!roles.isLieferant &&
                !roles.isTraegerschaft &&
                !roles.isArbeitgeber &&
                !roles.isJobCoach &&
                !roles.isPraxisbildner &&
                !roles.isIVBerater &&
                !roles.isNotfallkontakt) ||
                roles.isDozent) &&
              $CONST('CONFIG').APPTYPE == 'Education'
            "
          >
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.beruf"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                  :tabindex="204"
                />
                <label>{{ $t("global.professioneducation") }}</label>
              </div>
            </div>

            <div class="col-xl-6">
              <div
                v-if="
                  roles.isDozent || roles.isDiplomarbeiten || roles.isReferent
                "
                class="form-group"
              >
                <v-select
                  v-model="person.anstellung"
                  label="bezeichnung"
                  :options="anstellungen"
                  :disabled="!editable"
                  :reduce="(an) => an.id"
                  :placeholder="$t('global.employment')"
                  :tabindex="206"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.employment") }}</label>
              </div>
            </div>
          </div>
          <div v-if="roles.isDozent || roles.isReferent" class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="person.fach"
                  multiple
                  label="titel"
                  :options="faecher"
                  :disabled="!editable"
                  :reduce="(uf) => uf.id"
                  :placeholder="$t('global.subjects')"
                  :tabindex="208"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.subjects") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_FAECHER } from "@/store/noten/actions.type";
import Api from "@/Api";

export default {
  name: "Geschaeftspartner-BerufUndAusbildung",
  components: { ContainerHeadline },
  mixins: [],
  props: {
    person: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {},
    },
  },
  data() {
    return {
      nationalitaet: null,
      einsatzmoeglichkeiten: [],
      firmenListe: [],
      relationstypen: [],
    };
  },
  computed: {
    anstellungen: {
      get() {
        return this.$store.state.geschaeftspartner.anstellungsarten;
      },
    },
    funktionsbereiche: {
      get() {
        return this.$store.state.geschaeftspartner.funktionsbereiche;
      },
    },
    faecher: {
      get() {
        return this.$store.state.noten.faecher;
      },
    },
  },
  created() {
    if (this.firmenListe.length == 0) this.ladeFirmen();
    if (this.relationstypen.length == 0) this.ladeRelationstyp();
  },
  watch: {},
  mounted: function () {
    /*if (this.anstellungen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );
*/
    if (this.faecher.length == 0) this.$store.dispatch(`noten/${LADE_FAECHER}`);
  },
  methods: {
    ladeFirmen() {
      /*this.loading = true;

      Api.get("/geschaeftspartner/", {
        params: { typ: "17EF88469AE" },
      })
        .then((response) => {
          this.firmenListe = [];
          response.data.forEach((geschaeftspartner) => {
            this.firmenListe.push({
              id: geschaeftspartner.id,
              firmaid: geschaeftspartner.firma.id,
              name: geschaeftspartner.firma.name,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    ladeRelationstyp() {
      /*this.loading = true;

      var params = null;

      Api.get("/geschaeftspartner/relationstyp/", {
        params: params,
      })
        .then((response) => {
          this.relationstypen = [];
          response.data.forEach((relationstyp) => {
            this.relationstypen.push({
              id: relationstyp.id,
              bezeichnung: relationstyp.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    relationLoeschen(relation) {
      Api.delete("geschaeftspartner/relation/", {
        data: [this.id],
        params: { id: relation },
      }).then(() => {
        this.$router.push({ name: "personen-liste" });
      });
    },
  },
};
</script>

<style>
</style>

