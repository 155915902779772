<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span style="color: #c8d9e2" class="fa fa-times"></span>
          </button>
          <h4 class="modal-title">
            {{
              neuerBeitrag
                ? $t("global.newsubsidycontribution")
                : $t("global.editsubsidycontribution")
            }}
          </h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      :headline="$t('global.masterdata')"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <input
                                  class="form-control"
                                  placeholder=" "
                                  v-model="subventionsbeitrag.bezeichnung"
                                />
                                <label>{{ $t("global.designation") }}</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="subventionsbeitrag.subventionskanton"
                                  label="bezeichnung"
                                  :options="subventionskantoneAuswahl"
                                  :placeholder="$t('global.canton')"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>{{ $t("global.canton") }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="
                                    subventionsbeitrag.subventionsbeitragstatus
                                  "
                                  label="bezeichnung"
                                  :options="beitragstati"
                                  :placeholder="$t('global.contributionstatus')"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>{{ $t("global.contributionstatus") }}</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="subventionsbeitrag.beitragsintervall"
                                  label="bezeichnung"
                                  :options="beitragsintervalle"
                                  :placeholder="$t('global.interval')"
                                  :clearable="true"
                                >
                                </v-select>
                                <label>{{ $t("global.interval") }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-3">
                              <div class="form-group">
                                <input
                                  class="form-control"
                                  placeholder=" "
                                  v-model.number="subventionsbeitrag.betrag"
                                />
                                <label>{{ $t("global.amount") }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="speichern"
              :disabled="invalid"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "SubventionsbeitragModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
  },
  props: {
    subvention: {
      type: String,
      default: null,
    },
    beitrag: {
      type: Object,
    },
    subventionskantone: {
      type: Array,
    },
    beitragstati: {
      type: Array,
    },
    beitragsintervalle: {
      type: Array,
    },
  },
  data() {
    return {
      beitragId: null,
      subventionsbeitrag: {},
    };
  },
  computed: {
    neuerBeitrag() {
      return this.subventionsbeitrag === null;
    },
    subventionskantoneAuswahl: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
  },
  watch: {
    beitrag(val) {
      if (val) {
        this.inititalizeBeitrag(val);
      } else this.clear();
    },
  },
  async created() {},
  methods: {
    inititalizeBeitrag(beitrag) {
      if (beitrag) {
        this.beitragId = beitrag.id;
        this.subventionsbeitrag = beitrag;
      } else this.clear();
    },
    clear() {
      console.log("clear");
      this.subventionsbeitrag = {};
    },

    beitragToJson() {
      return this.subventionsbeitrag;
    },

    speichern() {
      if (this.beitragId) {
        Api.put("bildung/subvention/beitrag/", this.beitragToJson(), {
          params: { id: this.beitragId, sid: this.subvention },
        }).then(() => {
          this.closeModal();
        });
      } else {
        Api.post("bildung/subvention/beitrag/", this.beitragToJson(), {
          params: { sid: this.subvention },
        }).then(() => {
          this.closeModal();
        });
      }
    },

    closeModal() {
      this.shown = false;
      this.clear();
      this.$emit("beitragChanged");
      $("#subventionsbeitrag-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>
