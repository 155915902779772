<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!--<modal-close-button @confirmed="closeModal" />-->
          <h4 class="modal-title">Korrekturbuchung</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.belegtyp"
                  label="bezeichnung"
                  :options="belegtypen"
                  :get-option-label="(e) => `${e.bezeichnung}`"
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Belegtyp</label>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.habenkonto"
                  label="bezeichnung"
                  :options="konten"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung && k.kontonummer
                        ? k.kontonummer + ' / ' + k.bezeichnung
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Habenkonto</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  v-model.number="beleg.betrag"
                  class="form-control"
                  placeholder=" "
                />
                <label>Betrag</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.sollkonto"
                  label="bezeichnung"
                  :options="konten"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung && k.kontonummer
                        ? k.kontonummer + ' / ' + k.bezeichnung
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Sollkonto</label>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <datepicker
                  :initial="beleg.datum"
                  @update="(val) => (beleg.datum = val)"
                  show-icon
                  date-format="dd.mm.yy"
                ></datepicker>
                <label>Datum</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-select
                  v-model="beleg.ertragskostenstelle"
                  :reduce="(eks) => eks.id"
                  label="bezeichnung"
                  :options="kostenstellen"
                  :get-option-label="
                    (k) =>
                      k && k.bezeichnung
                        ? k.kostenstellennummer + ' / ' + k.bezeichnung
                        : ''
                  "
                  placeholder=" "
                  :clearable="false"
                >
                </v-select>
                <label>Kostenstelle</label>
              </div>
            </div>
            <div class="col-12 mt-5">
              <div class="form-group">
                <textarea
                  v-model="beleg.buchungstext"
                  class="form-control"
                  rows="4"
                />
                <label>Buchungstext</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="korrekturbuchungErstellen"
          >
            Korrekturbuchung erstellen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ModalCloseButton from "@/components/global/ModalCloseButton";
import Datepicker from "@/components/Datepicker.vue";
import { LADE_AUSWAHLWERTE_BELEG } from "@/store/beleg/actions.type";

export default {
  name: "KorrekturbuchungModal",
  props: {
    id: {
      type: String,
      required: false,
      default: "korrektur-buchung-modal",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    debitorid: {
      type: String,
      required: true,
    },
    auftragid: {
      type: String,
    },
  },
  data() {
    return {
      shown: false,
      multiText: false,
      beleg: {
        datum: new Date().toGermanDateString(),
        sollkonto: null,
        habenkonto: {
          id: "17923332B21",
          bezeichnung: "Debitoren",
          kontonummer: "1100",
        },
        betrag: null,
        netto: null,
        brutto: null,
        buchungstext: "K / ",
        debitor: this.debitorid,
        auftrag: this.auftragid,
        belegtyp: { id: "17926E2017D", bezeichnung: "Korrekturbuchung" },
        waehrung: { id: "174E4563A31" },
        isKorrekturbuchung: true,
      },
    };
  },
  components: {
    //ModalCloseButton,
    Datepicker,
  },
  computed: {
    konten: {
      get() {
        return this.$store.state.beleg.konten;
      },
    },
    belegtypen: {
      get() {
        return this.$store.state.beleg.belegtypen;
      },
    },
    kostenstellen: {
      get() {
        return this.$store.state.beleg.kostenstellen;
      },
    },
  },
  created() {
    if (this.konten.length == 0) {
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`);
    }
  },
  mounted() {
    this.$bus.$on("openLoeschenModal", (id) => {
      if (this.selectedIds.length > 1) this.multiText = true;
      else if (this.multiple == true) this.multiText = true;

      if (!this.shown) this.openModal(id);
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    korrekturbuchungErstellen() {
      if (
        this.beleg.sollkonto == null ||
        this.beleg.habenkonto == null ||
        this.beleg.betrag == "" ||
        this.beleg.belegtyp == null
      ) {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionfailed"),
          text: "Fehlende Angaben im Beleg.",
        });
      } else if (
        this.beleg.belegtyp.bezeichnung === "Ausgleich Mahnspesen" &&
        !(
          this.beleg.habenkonto.bezeichnung === "Debitoren" ||
          this.beleg.habenkonto.bezeichnung === "Forderungen Subventionen"
        )
      ) {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionfailed"),
          text: "Kontierung überprüfen",
        });
      } else if (
        this.beleg.habenkonto.bezeichnung === this.beleg.sollkonto.bezeichnung
      ) {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionfailed"),
          text: "Kontierung überprüfen",
        });
      } else {
        var json = Object.assign({}, this.beleg);
        json.auftrag = { id: this.auftragid };
        json.debitor = { id: this.debitorid };
        json.netto = json.betrag;
        json.brutto = json.betrag;
        this.$emit("confirmedKorrekturbuchung", json);
        this.closeModal();
      }
    },
  },
};
</script>

<style>
</style>