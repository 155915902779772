var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('container-headline',{attrs:{"headline":_vm.$t('global.content'),"col":3}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.betreff),expression:"korrespondenz.betreff",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":" "},domProps:{"value":(_vm.korrespondenz.betreff)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.subject")))])])]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.ort),expression:"korrespondenz.ort",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":" "},domProps:{"value":(_vm.korrespondenz.ort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "ort", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.city")))])])]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"person.personName","options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature')},on:{"input":_vm.unterschrift1Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift1),callback:function ($$v) {_vm.unterschrift1=$$v},expression:"unterschrift1"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("notification.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature1")))])],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"person.personName","options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature')},on:{"input":_vm.unterschrift2Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift2),callback:function ($$v) {_vm.unterschrift2=$$v},expression:"unterschrift2"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("notification.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature2")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"}),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.beilagen),expression:"korrespondenz.beilagen",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":" "},domProps:{"value":(_vm.korrespondenz.beilagen)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "beilagen", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.$t("global.Attachments")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.korrespondenz.inhalt),callback:function ($$v) {_vm.$set(_vm.korrespondenz, "inhalt", $$v)},expression:"korrespondenz.inhalt"}})],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }