<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.lecturerdeployment')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    v-if="editable"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon
                      icon="fa-solid fa-list"
                      class="mr-2"
                    />{{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.b_bildung_klasse.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Verknüpfung -->
          <container-headline
            :headline="$t('global.link')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-veranstaltung
                      id="dozenteneinsatz-veranstaltung"
                      :veranstaltung="dozenteneinsatz.termin.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="berechtigungen.b_bildung_klasse.read"
                      :editable="false"
                    />
                  </div>
                  <div class="col-xl-6">
                    <span class="small">{{ $t("global.appointment") }}</span>
                    <div v-on:click="openTermin" style="cursor: pointer">
                      {{ dozenteneinsatz.termin.von }}
                      {{ dozenteneinsatz.termin.von_uhrzeit }} -
                      {{ dozenteneinsatz.termin.bis }}
                      {{ dozenteneinsatz.termin.bis_uhrzeit }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="row">
                      <div class="col-12">
                        <span class="small">{{ $t("global.lecturerbooking") }}</span>
                        <div
                          v-on:click="openDozentenbuchung"
                          style="cursor: pointer"
                        >
                          {{
                            dozenteneinsatz.dozentenbuchung
                              ? dozenteneinsatz.dozentenbuchung.person
                                ? dozenteneinsatz.dozentenbuchung.person
                                    .personName
                                : ""
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6"></div>
                </div>
              </div>
            </div>
          </div>
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="dozenteneinsatz.planungsstatus"
                        label="bezeichnung"
                        :options="planungsstati"
                        :reduce="(psl) => psl.id"
                        :disabled="!editable"
                        :placeholder="$t('global.statusplanning')"
                      >
                        <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                      </v-select>
                      <label>{{ $t("global.statusplanning") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="dozenteneinsatz.durchfuehrungsstatus"
                        label="bezeichnung"
                        :options="durchfuehrungsstati"
                        :reduce="(dsl) => dsl.id"
                        :disabled="!editable"
                        :placeholder="$t('global.statusexecution')"
                      >
                        <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                      </v-select>
                      <label>{{ $t("global.statusexecution") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="dozenteneinsatz.dozentenkommentar"
                        class="form-control"
                        :disabled="!editable"
                        placeholder=" "
                      />
                      <label>{{ $t("global.commentlecturer") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="dozenteneinsatz.bemerkung"
                        :disabled="!editable"
                        class="form-control"
                        placeholder=" "
                        rows="4"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Verlauf -->
          <container-headline :headline="$t('global.process')" :col="4"></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.open") }}</span>
                    <div>{{ dozenteneinsatz.offen }}</div>
                  </div>
                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.carriedout") }}</span>
                    <div>{{ dozenteneinsatz.durchgefuehrt }}</div>
                  </div>

                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.notcarriedout") }}</span>
                    <div>{{ dozenteneinsatz.nichtdurchgefuehrt }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Verlauf -->
        </div>
        <!-- ENDE Rechte Seite -->

        <div v-if="id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item" v-if="berechtigungen.m_journal.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'dozenteneinsatz',
                      params: { id: id, anzeige: 0 },
                    }"
                    >Journal</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="berechtigungen.b_finanzen_honorar.read"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'dozenteneinsatz',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.fee") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div
                  v-show="anzeige === 0"
                  v-if="berechtigungen.m_journal.read"
                >
                  <!-- Start Journal -->
                  <journal-eintrag-liste
                    :fkey="id"
                    ftable="dozenteneinsatz"
                    :editable="editable"
                    :shown="anzeige == 0"
                  />
                  <!-- Ende Journal -->
                </div>
                <div
                  v-show="anzeige === 1"
                  v-if="berechtigungen.b_finanzen_honorar.read"
                  :key="lohnreiterkey"
                >
                  <!-- Start Honorar -->
                  <honorarliste
                    :dozenteneinsatz="id"
                    :person="dozenteneinsatz.person"
                    :shown="anzeige == 1"
                  />
                  <!-- Ende Honorar -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <!-- MODALS -->

    <loeschen-modal
      id="dozenteneinsatz-loeschen-modal"
      @confirmed="dozenteneinsatzLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Honorarliste from "@/components/Reiter/Honorarliste";
import JournalEintragListe from "@/components/Reiter/JournalEintragListe";

import store from "@/store";
import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";

export default {
  name: "Dozenteneinsatz",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    Honorarliste,
    JournalEintragListe,
    LoeschenModal,
    SingleSelectVeranstaltung,
  },
  metaInfo() {
    const dozenteneinsatz = this.dozenteneinsatz;
    return {
      titleTemplate: () => {
        if (!dozenteneinsatz) {
          return this.$t("global.newlecturerdeployment");
        }
        return this.$t("global.lecturerdeployment");
      },
    };
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dozenteneinsatz: {
        termin: { veranstaltung: {} },
        veranstaltung: {},
        dozentenbuchung: {},
      },
      loading: false,
      editable: false,
      lohnreiterkey: 1,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.dozenteneinsatz) {
        return this.$t("global.lecturerdeployment");
      } else {
        return this.$t("global.newlecturerdeployment");
      }
    },
    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },
  },
  watch: {},
  created() {
    if (this.id) {
      this.loading = true;

      Api.get("dozenteneinsatz/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeDozenteneinsatz(response.data);
        }
      );
    } else this.editable = true;
  },
  mounted: function () {
    if (this.planungsstati.length == 0)
      this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "Dozenteneinsatzliste" });
    },

    openTermin() {
      this.$router.replace({
        name: "termin",
        params: {
          id: this.dozenteneinsatz.termin
            ? this.dozenteneinsatz.termin.id
            : null,
          anzeige: 0,
        },
      });
    },

    openDozentenbuchung() {
      this.$router.replace({
        name: "dozentenbuchung",
        params: {
          id: this.dozenteneinsatz.dozentenbuchung
            ? this.dozenteneinsatz.dozentenbuchung.id
            : null,
          anzeige: 0,
        },
      });
    },

    initializeDozenteneinsatz(dozenteneinsatz) {
      if (dozenteneinsatz) {
        this.dozenteneinsatz = dozenteneinsatz;

        if (!this.dozenteneinsatz.termin)
          this.dozenteneinsatz.termin = { veranstaltung: {} };

        if (this.dozenteneinsatz.durchfuehrungsstatus)
          this.editable =
            this.dozenteneinsatz.durchfuehrungsstatus.id ==
            this.$CONST("DOZENTENDURCHFUEHRUNGSTATI").EXECUTED
              ? false
              : true;

        if (!this.berechtigungen.m_dozierende.update) this.editable = false;

        this.initChangeWatcher(this.dozenteneinsatz);
      } else {
        console.error("dozenteneinsatz ist null");
      }

      this.loading = false;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.dozenteneinsatz);

      if (
        this.dozenteneinsatz.durchgefuehrt == null &&
        this.dozenteneinsatz.durchfuehrungsstatus ==
          this.$CONST("DOZENTENDURCHFUEHRUNGSTATI").EXECUTED
      ) {
        let curDate = new Date();
        json.durchgefuehrt =
          curDate.getDate() +
          "." +
          (curDate.getMonth() + 1) +
          "." +
          curDate.getFullYear();
      } else if (
        this.dozenteneinsatz.nichtdurchgefuehrt == null &&
        this.dozenteneinsatz.durchfuehrungsstatus ==
          this.$CONST("DOZENTENDURCHFUEHRUNGSTATI").NOTEXECUTED
      ) {
        let curDate = new Date();
        json.nichtdurchgefuehrt =
          curDate.getDate() +
          "." +
          (curDate.getMonth() + 1) +
          "." +
          curDate.getFullYear();
      }

      if (!this.dozenteneinsatz) {
        Api.post("dozenteneinsatz/", json)
          .then((response) => {
            this.postSave(response.data, true);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("dozenteneinsatz/", json, {
          params: { id: this.dozenteneinsatz.id },
        })
          .then((response) => {
            this.postSave(response.data, false);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    postSave(response, isNewRecord) {
      this.initializeDozenteneinsatz(response);

      if (
        this.dozenteneinsatz.durchfuehrungsstatus.id ==
        this.$CONST("DOZENTENDURCHFUEHRUNGSTATI").EXECUTED
      ) {
        Api.post(
          "finanzen/honorar/",
          {},
          { params: { id: this.dozenteneinsatz.id } }
        )
          .then(() => {
            this.$notify({
              type: "success",
              title: this.$t('notification.actionsuccessful'),
              text: this.$t('notification.lecturerdeploymentsavedsuccessfully'),
            });

            if (isNewRecord)
              this.$router.replace({
                name: "dozenteneinsatz",
                params: { id: response.id, anzeige: 0 },
              });
            else this.lohnreiterkey += 1;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$notify({
          type: "success",
         title: this.$t('notification.actionsuccessful'),
              text: this.$t('notification.lecturerdeploymentsavedsuccessfully'),
        });

        if (isNewRecord)
          this.$router.replace({
            name: "dozenteneinsatz",
            params: { id: response.id, anzeige: 0 },
          });
      }
    },

    dozenteneinsatzLoeschen() {
      Api.delete("dozenteneinsatz/", { data: [this.id] }).then(() => {
        this.$router.push({ name: "Dozenteneinsatzliste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "dozenteneinsatz-loeschen-modal");
    },
  },
};
</script>

<style>
</style>
