<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <container-headline
      :headline="$t('global.receiversender')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="mail.bezeichnung"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.designation") }}</label>
              </div>
            </div>
            <!--<div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="mail.absendername"
                  class="form-control"
                  placeholder=" "
                />
                <label>Absendername</label>
              </div>
            </div>-->
            <div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="mail.absenderadresse"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.senderemail") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="mail.antwortan"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.answerto") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <v-select
                  v-model="mail.mailvorlagentyp"
                  label="bezeichnung"
                  :options="mailvorlagetypen"
                  :disabled="!editable"
                  :placeholder="$t('dashboard.type')"
                  :reduce="(mvt) => mvt.id"
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("dashboard.type") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";

import page from "@/mixins/Page";

import { LADE_AUSWAHLWERTE_MAIL } from "@/store/korrespondenz/actions.type";

export default {
  name: "MailVorlageStammdaten",
  components: {
    ContainerHeadline,
  },
  mixins: [page],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    mailvorlagetypen: {
      get() {
        return this.$store.state.korrespondenz.mailvorlagetypen;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.mailvorlagetypen.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_MAIL}`);
  },

  methods: {},
};
</script>

<style>
</style>
