<template>
  <div id="termin-dozenteneinsatz-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>{{ $t("global.createlecturerassignment") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="dozentenbuchungliste"
                  label="dozent"
                  :options="dozenten"
                  :reduce="(d) => d.id"
                  :placeholder="$t('global.lecturer')"
                  multiple
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.lecturerbooking") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="planungsstatus"
                  class="mt-2 mb-3"
                  label="bezeichnung"
                  :options="planungsstati"
                  :reduce="(p) => p.id"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.planningstatus") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="durchfuehrungsstatus"
                  class="mt-2 mb-3"
                  label="bezeichnung"
                  :options="durchfuehrungsstati"
                  :reduce="(d) => d.id"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.executionstatus") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button class="btn btn-primary" @click="speichern">
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import store from "@/store";

import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "TerminDozenteneinsatzAendernModal",
  components: {
    ModalCloseButton,
  },
  metaInfo() {},
  mixins: [],
  props: {
    termine: {
      type: Array,
      required: true,
    },
    dozentenbuchungen: {
      type: Array,
      required: true,
      default: function () {
        return [{ person: { personName: "" } }];
      },
    },
  },
  store,
  data() {
    return {
      shown: false,
      dozentenbuchungliste: [],
      planungsstatus: {},
      durchfuehrungsstatus: {},
    };
  },
  computed: {
    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },
    dozenten: function () {
      let dozArr = [];
      this.dozentenbuchungen.forEach((element) => {
        dozArr.push({ id: element.id, dozent: element.person.personName });
      });
      return dozArr;
    },
  },
  watch: {},
  mounted: function () {
    this.$bus.$on("openTerminDozenteneinsatzModal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    speichern() {
      let json = {
        dozentenbuchungungen: this.dozentenbuchungliste,
        planungsstatus: this.planungsstatus,
        durchfuehrungsstatus: this.durchfuehrungsstatus,
        termine: this.termine,
      };

      Api.post("dozenteneinsatz/update/", json)
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t(
              "notification.lecturerassignmentssuccessfullycreated"
            ),
          });
          this.$emit("updated");
        })
        .catch(() => {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.lecturerassignmentswerenotcreated"),
          });
        });

      this.closeModal();
    },

    openModal() {
      $("#termin-dozenteneinsatz-modal").modal({
        backdrop: "static",
        keyboard: false,
      });

      this.shown = false;
    },

    closeModal() {
      $("#termin-dozenteneinsatz-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>
