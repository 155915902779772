<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.presencejournal')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="col-xl-3">
            <div class="form-group" v-if="person">
              <v-select
                v-model="fach"
                label="bezeichnung"
                :options="faecher"
                :placeholder="$t('global.Subject')"
                :clearable="true"
              >
                <span slot="no-options">{{
                  $t("notification.taptosearch")
                }}</span>
              </v-select>
              <label>{{ $t("global.Subject") }}</label>
            </div>
            <div class="form-group" v-if="fachid">
              <v-select
                v-model="anmeldung"
                label="personName"
                :options="anmeldungen"
                :placeholder="$t('global.registrations')"
                :clearable="true"
              >
                <span slot="no-options">{{
                  $t("notification.taptosearch")
                }}</span>
              </v-select>
              <label>{{ $t("global.registration") }}</label>
            </div>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="liste"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(unterrichtsinhalt)="row">
                  <span
                    v-for="(inhalt, index) in row.item.unterrichtsinhalt"
                    :key="index"
                    >{{ inhalt.bezeichnung }} <br />
                  </span>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  name: "Praesenzjournalliste",
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    fachid: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      praesenzen: [],
      liste: [],
      faecher: [],
      anmeldungen: [],
      fach: null,
      anmeldung: null,
      fields: [
        { key: "selected", label: "" },
        {
          key: "praesenzstatus.bezeichnung",
          sortable: false,
          label: this.$t("global.presencestatus"),
        },
        {
          key: "termin.von",
          sortable: false,
          label: this.$t("global.date"),
          formatter: (value) => {
            if (value) {
              return new Date(value).toLocaleDateString();
            } else {
              return "-";
            }
          },
        },
        {
          key: "note",
          sortable: true,
          label: this.$t("global.grade"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "fach.bezeichnung",
          sortable: true,
          label: this.$t("global.subject"),
        },
        {
          key: "unterrichtsinhalt",
          sortable: true,
          label: this.$t("global.lessoncontent"),
        },
        {
          key: "fachkompetenzen",
          sortable: true,
          label: this.$t("global.subjectprofessionalcompetencies"),
        },
        {
          key: "sozialverhalten",
          sortable: true,
          label: this.$t("global.worksocialbehavior"),
        },
        {
          key: "termin.bemerkung_unterricht",
          sortable: true,
          label: this.$t("global.comment"),
        },
      ],
      selected: [],
      selectedAnmeldungIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.praesenzen.length == 0) this.get();
    },
    fach(val) {
      if (val)
        this.liste = this.praesenzen.filter((el) => el.fach.id == val.id);
      else this.liste = this.praesenzen;
    },
    anmeldung(val) {
      if (val)
        this.liste = this.praesenzen.filter((el) => el.anmeldung.id == val.id);
      else this.liste = this.praesenzen;
    },
  },
  computed: {},
  mounted() {
    if (this.shown && this.praesenzen.length == 0) this.get();
    this.setHeaders("global", [
      "",
      "presencestatus",
      "date",
      "grade",
      "subject",
      "lessoncontent",
      "subjectprofessionalcompetencies",
      "worksocialbehavior",
      "comment",
    ]);
  },
  created() {
    if (this.fachid)
      this.fields.push({
        key: "anmeldung.personName",
        sortable: true,
        label: "Anmeldung",
      });
  },
  methods: {
    get() {
      this.isBusy = true;

      Api.get("geschaeftspartner/praesenzen/", {
        params: {
          fachId: this.fachid,
          personId: this.person,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.praesenzen = response.data.praesenzen;
          this.liste = this.praesenzen;
          this.faecher = response.data.faecher;
          this.anmeldungen = response.data.anmeldungen;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(praesenz) {
      this.$router.push({
        name: "anwesenheit",
        params: {
          vid: praesenz.fach.id,
          tid: praesenz.termin.id,
        },
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedAnmeldungIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedAnmeldungIds.push(row.item.id);
      } else {
        this.selectedAnmeldungIds = this.selectedAnmeldungIds.filter(
          (id) => id != row.item.id
        );
      }
    },
  },
};
</script>