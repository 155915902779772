<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- AKTIONEN -->

      <container-headline
        class="col-xl-12"
        :headline="$t('global.examgrades')"
        :col="3"
      >
        <div class="row">
          <!--
          <div class="ml-4 mr-2">
            <button
              class="btn btn-primary"
              @click="addPruefung"
              v-if="editable"
            >
             <font-awesome-icon icon="fa-regular fa-plus"/>
              Prüfung
            </button>
          </div>
           -->
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="notenSpeichern('speichern')"
              v-if="editable"
            >
              {{ $t("global.savegrades") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-success"
              @click="notenSpeichern('durchschnittAktualisieren')"
              v-if="editable"
            >
              {{ $t("global.updateaverage") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-success"
              @click="teilnehmerAktualisieren"
              v-if="editable"
            >
              {{ $t("global.updateparticipantlist") }}
            </button>
          </div>

          <button class="btn btn-primary" @click="erstelleExcel">
            <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
            <em>{{ $t("global.createexcel") }}</em>
          </button>
        </div>
      </container-headline>

      <!-- INHALT -->

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="notenerfassungtable"
              tbody-tr-class="item"
              :items="notenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              small
            >
              <template #thead-top>
                <b-tr>
                  <b-th colspan="5"
                    ><span class="sr-only">{{
                      $t("global.nameandid")
                    }}</span></b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 0"
                    >{{ pruefungLabel[0] }}</b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 1"
                    >{{ pruefungLabel[1] }}</b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 2"
                    >{{ pruefungLabel[2] }}</b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 3"
                    >{{ pruefungLabel[3] }}</b-th
                  >
                  <b-th
                    class="text-center border-left"
                    colspan="2"
                    v-if="pruefungCount > 4"
                    >{{ pruefungLabel[4] }}</b-th
                  >
                </b-tr>
              </template>

              <template v-slot:cell(korrektur)="row">
                <b-form-input
                  v-model="row.item.note.korrektur"
                  :on-change="checkKorrektur(row.index)"
                  :state="row.item.note.invalid"
                  :readonly="!editable"
                  :tabindex="1 + row.index"
                  @blur.native="formatKorrektur(row.index)"
                  size="sm"
                ></b-form-input>
              </template>

              <template v-slot:cell(dispensiert)="row">
                <b-button
                  class="mr-2"
                  :variant="
                    row.item.note.dispensiert == true ? 'primary' : 'light'
                  "
                  size="sm"
                  style="width: 150px"
                  :disabled="!editable"
                  :tabindex="100 + row.index"
                  @click="setDispensiert(row.index)"
                  >{{
                    row.item.note.dispensiert
                      ? $t("global.dispensed")
                      : $t("global.notdispensed")
                  }}</b-button
                >
              </template>

              <template v-slot:cell(gewichtung1)="row">
                {{ row.item.details[0].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 1" v-slot:cell(gewichtung2)="row">
                {{ row.item.details[1].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 2" v-slot:cell(gewichtung3)="row">
                {{ row.item.details[2].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 3" v-slot:cell(gewichtung4)="row">
                {{ row.item.details[3].pruefung.gewichtung }}
              </template>
              <template v-if="pruefungCount > 4" v-slot:cell(gewichtung5)="row">
                {{ row.item.details[4].pruefung.gewichtung }}
              </template>

              <template v-slot:cell(pruefung1)="row">
                <b-form-input
                  v-if="row.item.details[0]"
                  v-model="row.item.details[0].bewertung.bewertung"
                  :on-change="checkBewertung(1, row.index)"
                  :state="row.item.details[0].invalid"
                  :tabindex="1000 + row.index"
                  :readonly="
                    !row.item.details[0].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(1, row.index)"
                  required
                  size="sm"
                ></b-form-input>
              </template>
              <template v-slot:cell(pruefung2)="row">
                <b-form-input
                  v-if="row.item.details[1]"
                  v-model="row.item.details[1].bewertung.bewertung"
                  :on-change="checkBewertung(2, row.index)"
                  :state="row.item.details[1].invalid"
                  :tabindex="2000 + row.index"
                  :readonly="
                    !row.item.details[1].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(2, row.index)"
                  required
                  size="sm"
                ></b-form-input>
              </template>
              <template v-slot:cell(pruefung3)="row">
                <b-form-input
                  v-if="row.item.details[2]"
                  v-model="row.item.details[2].bewertung.bewertung"
                  :on-change="checkBewertung(3, row.index)"
                  :state="row.item.details[2].invalid"
                  :tabindex="3000 + row.index"
                  :readonly="
                    !row.item.details[2].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(3, row.index)"
                  required
                  size="sm"
                ></b-form-input>
              </template>
              <template v-slot:cell(pruefung4)="row">
                <b-form-input
                  v-if="row.item.details[3]"
                  v-model="row.item.details[3].bewertung.bewertung"
                  :on-change="checkBewertung(4, row.index)"
                  :state="row.item.details[3].invalid"
                  :tabindex="4000 + row.index"
                  :readonly="
                    !row.item.details[3].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(4, row.index)"
                  required
                  size="sm"
                ></b-form-input>
              </template>
              <template v-slot:cell(pruefung5)="row">
                <b-form-input
                  v-if="row.item.details[4]"
                  v-model="row.item.details[4].bewertung.bewertung"
                  :on-change="checkBewertung(5, row.index)"
                  :state="row.item.details[4].invalid"
                  :tabindex="5000 + row.index"
                  :readonly="
                    !row.item.details[4].pruefung.editable || !editable
                  "
                  @blur.native="formatNote(5, row.index)"
                  required
                  size="sm"
                ></b-form-input>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

import ContainerHeadline from "@/components/ContainerHeadline";

import FileDownload from "js-file-download";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      isBusy: false,
      ladeFehler: false,
      sortBy: "person.personName",
      sortDesc: false,
      invalidFields: {},
      notenliste: [],
      pruefungCount: 0,
      pruefungLabel: [],
      origFields: [
        {
          key: "person.personName",
          sortable: true,
          label: "Teilnehmer",
        },
        {
          key: "note.notendurchschnitt_def",
          sortable: true,
          class: "text-center",
          label: "Semesterfachnote",
        },
        {
          key: "korrektur",
          sortable: true,
          class: "text-center",
          label: "Korrektur",
        },
        {
          key: "dispensiert",
          sortable: true,
          class: "text-center",
          label: "Dispensiert",
        },
        {
          key: "note.notendurchschnitt_prov",
          class: "text-center",
          sortable: true,
          label: "Noten-Ø-prov.",
        },
      ],
      fields: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.notenliste.length == 0) this.getNoten();
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;
      return params;
    },
    planned: function () {
      return this.$CONST("PRUEFUNGSTATI").PLANNED;
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.notenliste.length == 0) this.getNoten();
    if (this.person)
      this.fields[1] = {
        key: "noteneingabe_titel",
        sortable: true,
        label: "Noteneingabe",
      };

    this.$bus.$on("addPruefungRemote", (id) => {
      /*** Reiter Prüfung soll eine Prüfung erstellen können, dafür wird allerdings benötigt, dass die Daten schon geladen sind ***/
      /*** Hier getNoten ausführen, welches über den Parameter nach dem Laden der Noten eine Erstellung einer Prüfung triggert  ***/

      /* 
        SWE: Check dass Noteneingabe auch wirklich die ist, die momentan bearbeitet wird. Wir vermuten Fehler im Vue bei den Emits wenn Tabs geklont werden.
      */
      if (id != this.noteneingabe) return;
      this.getNoten(true);
    });

    this.$bus.$on("deletePruefungRemote", () => {
      this.getNoten();
      this.notenSpeichern();
    });
  },
  methods: {
    checkKorrektur(index) {
      let val = this.notenliste[index].note.korrektur;
      if (val == undefined || val == "") {
        this.invalidFields["note" + index] = null;
      } else {
        if (val < 1 || val > 6) {
          this.notenliste[index].note.invalid = false;
          this.invalidFields["note" + index] = true;
        } else {
          this.notenliste[index].note.invalid = true;
          this.invalidFields["note" + index] = null;
        }
      }
    },

    checkBewertung(pNo, index) {
      if (this.notenliste[index].details[pNo - 1]) {
        let val = this.notenliste[index].details[pNo - 1].bewertung.bewertung;

        if (val == undefined || val == "") {
          this.notenliste[index].details[pNo - 1].invalid = true;
          this.invalidFields["pruefung" + pNo + index] = null;
        } else {
          if (val < 1 || val > 6) {
            this.notenliste[index].details[pNo - 1].invalid = false;
            this.invalidFields["pruefung" + pNo + index] = true;
          } else {
            this.invalidFields["pruefung" + pNo + index] = null;
            this.notenliste[index].details[pNo - 1].invalid = true;
          }
        }
      }
    },

    formatNotenWert(value) {
      return value == undefined || value == ""
        ? ""
        : parseFloat(value).toFixed(1);
    },

    formatKorrektur(index) {
      this.notenliste[index].note.korrektur = this.formatNotenWert(
        this.notenliste[index].note.korrektur
      );
    },

    formatNote(pNo, index) {
      this.notenliste[index].details[pNo - 1].bewertung.bewertung =
        this.formatNotenWert(
          this.notenliste[index].details[pNo - 1].bewertung.bewertung
        );
    },

    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },

    updateTable() {
      let count = 1;
      this.fields = this.origFields;
      this.pruefungLabel = [];

      this.notenliste[0].details.forEach((item) => {
        this.fields.push({
          key: "pruefung" + count,
          sortable: false,
          class: "text-center border-left",
          label: "Note",
        });

        this.fields.push({
          key: "gewichtung" + count,
          sortable: false,
          class: "text-center",
          label: "Gew.",
        });

        if (item.pruefung && item.pruefung.bezeichnung)
          this.pruefungLabel.push(item.pruefung.bezeichnung);

        count++;
      });

      this.pruefungCount = count - 1;
      this.isBusy = false;
    },

    getNoten(addPruefungRemote) {
      this.isBusy = true;

      Api.get("noten/notenerfassung/", {
        params: {
          noteneingabe: this.noteneingabe,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.notenliste = response.data;
          let scope = this;

          if (this.notenliste[0]) {
            let plannedId = this.planned;
            if (this.notenliste[0].details) {
              this.notenliste.forEach(function (benotung) {
                benotung.note.korrektur = scope.formatNotenWert(
                  benotung.note.korrektur
                );

                benotung.note.notendurchschnitt_def = scope.formatNotenWert(
                  benotung.note.notendurchschnitt_def
                );

                benotung.note.notendurchschnitt_prov = scope.formatNotenWert(
                  benotung.note.notendurchschnitt_prov
                );

                benotung.details.forEach(function (detail) {
                  if (!detail.pruefung.status) {
                    detail.pruefung.status = {
                      id: plannedId,
                    };
                  }

                  //detail.pruefung.status.id == plannedId ? true : false;
                  detail.pruefung.editable =
                    detail.pruefung.status.id == plannedId ? true : false;

                  detail.bewertung.bewertung = scope.formatNotenWert(
                    detail.bewertung.bewertung
                  );
                });
              });

              this.updateTable();
            }
          }
          if (addPruefungRemote) {
            this.addPruefung();
          }
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    addPruefung() {
      this.isBusy = true;

      if (this.notenliste.length == 0) {
        this.strukturenErstellen();
      } else if (this.notenliste[0].details.length < 5) {
        this.notenliste.forEach((item) => {
          item.details.push({
            pruefung: {
              pruefung: {
                status: { id: this.$CONST("PRUEFUNGSTATI").PLANNED },
              },
              nummer: this.notenliste[0].details.length + 2, // Neue Prüfungsnummer setzen
            },
            bewertung: {},
          });

          this.pruefungLabel.push("Neue Prüfung");
        });

        this.notenSpeichern("neuePrüfung");
      }

      this.isBusy = false;
      this.$emit("updatePruefungliste"); //reload
    },

    setDispensiert(index) {
      this.notenliste[index].note.dispensiert = this.notenliste[index].note
        .dispensiert
        ? false
        : true;
    },

    strukturenErstellen() {
      this.isBusy = true;
      Api.post(
        "noten/notenerfassung/",
        {},
        {
          params: { noteneingabe: this.noteneingabe },
        }
      )
        .then(() => {
          this.getNoten();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Neue Noten erfolgreich hinzugefügt.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.notenSpeichern("neuePrüfung");
          this.$emit("updatePruefungliste");
          this.isBusy = false;
        });
    },

    notenSpeichern(aktion) {
      if (!this.validateForm()) return;

      this.isBusy = true;

      Api.put("noten/notenerfassung/", this.notenliste, {
        params: { noteneingabe: this.noteneingabe },
      })
        .then(() => {
          this.$emit("pruefungUpdated");

          this.getNoten();

          if (aktion == "speichern") {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "Noten erfolgreich gespeichert.",
            });
          } else if (aktion == "durchschnittAktualisieren") {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "Durchschnitt erfolgreich aktualisiert.",
            });
          } else if (aktion == "neuePrüfung") {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: "Prüfung erfolgreich hinzugefügt.",
            });
          }
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    erstelleExcel() {
      let pruefungen = this.notenliste[0].details.map((el) => el.pruefung);

      Api.post(
        "/noten/notenerfassung/notenexport/",
        {
          noteneingabe: this.noteneingabe,
          pruefungen: pruefungen,
          notenliste: this.notenliste,
        },
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `Noteneingabe-${
              this.noteneingabe
            }-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("notification.actionfailed"),
              text: "Fehler beim Erstellen der Excel-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },
    teilnehmerAktualisieren() {
      Api.put(
        "noten/teilnehmeraktualisieren/",
        {},
        {
          params: { noteneingabeId: this.noteneingabe },
        }
      )
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Teilnehmerliste erfolgreich aktualisiert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.getNoten();
        });
    },
  },
};
</script>
