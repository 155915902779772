<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="fach.kuerzel"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.shortdesignation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-8">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="fach.bezeichnung"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.educationdesignation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.veranstaltungsstatus"
                  label="bezeichnung"
                  :options="veranstaltungsstati"
                  :disabled="!editable"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.status") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.lehrmethode"
                  label="bezeichnung"
                  :options="lehrmethoden"
                  :disabled="!editable"
                  :placeholder="$t('global.teachingmethod')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.teachingmethod") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.niveau"
                  label="bezeichnung"
                  :options="niveaus"
                  :disabled="!editable"
                  :placeholder="$t('global.level')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.level") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="fach.fach"
                  label="titel"
                  :options="faecher"
                  :disabled="!editable"
                  :placeholder="$t('global.module')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.module") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="fach.beschreibung"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.description") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "FachStammdaten",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    fach: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    veranstaltungsstati: {
      type: Array,
    },
    lehrmethoden: {
      type: Array,
    },
    niveaus: {
      type: Array,
    },
    faecher: {
      type: Array,
    },
  },
  watch: {
    veranstaltungsstati: function (val) {
      if (val && !this.fach.veranstaltungsstatus) {
        let veranstaltungStatusOffen = this.$CONST("VERANSTALTUNGSSTATI").OPEN;

        this.fach.veranstaltungsstatus = this.veranstaltungsstati.find(
          ({ id }) => id == veranstaltungStatusOffen
        );
      }
    },
  },
  computed: {},
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss">
</style>
