var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('container-headline',{attrs:{"headline":_vm.$t('global.communicationdetails'),"col":6}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('vue-tel-input',{attrs:{"id":"phone-business","disabled":!_vm.editable,"tabindex":300},model:{value:(_vm.telefon_g),callback:function ($$v) {_vm.telefon_g=$$v},expression:"telefon_g"}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.businessphone")))])],1)]),(_vm.isPerson)?_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('vue-tel-input',{attrs:{"id":"phone-private","disabled":!_vm.editable,"tabindex":301},model:{value:(_vm.telefon_p),callback:function ($$v) {_vm.telefon_p=$$v},expression:"telefon_p"}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.privatephone")))])],1)]):_vm._e(),(_vm.isPerson)?_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('vue-tel-input',{attrs:{"id":"phone-mobile","disabled":!_vm.editable,"tabindex":302},model:{value:(_vm.telefon_m),callback:function ($$v) {_vm.telefon_m=$$v},expression:"telefon_m"}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.mobilephone")))])],1)]):_vm._e(),_c('div',{staticClass:"col-xl-6"},[(
                !_vm.isPerson ||
                _vm.roles.isTraegerschaft ||
                _vm.roles.isLieferant ||
                _vm.roles.isArbeitgeber ||
                _vm.roles.isJobCoach ||
                _vm.roles.isPraxisbildner
              )?_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.person.webseite),expression:"person.webseite",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":" ","readonly":!_vm.editable,"tabindex":303},domProps:{"value":(_vm.person.webseite)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.person, "webseite", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.website")))])]):_vm._e()])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }