  <template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_lead.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-firma
                      id="aktivitaetFirma"
                      :firma="aktivitaet.firma"
                      :editable="editable"
                      :allowOpen="true"
                      :label="$t('global.contactcompany')"
                      @confirmed="setFirma"
                    />
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="ansprechpartner"
                      :firma="aktivitaet.firma"
                      :person="aktivitaet.person"
                      :label="$t('global.contactperson')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setPerson"
                      :tabindex="1"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <input
                        v-model="aktivitaet.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <multi-select-person
                      id="verantwortlicher"
                      :personen="verantwortlicher"
                      :label="$t('global.Responsible')"
                      editable
                      :allowOpen="true"
                      required="true"
                      @confirmed="setVerantwortlicher"
                      :tabindex="6"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="aktivitaet.geplantdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (aktivitaet.geplantdatum = val)"
                      />
                      <label>{{ $t("global.planneddate")}}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <time-field
                      :label="$t('global.plannedtime')"
                      placeholder=""
                      :initial="aktivitaet.geplantzeit"
                      :tabindex="3"
                      @update="(val) => (aktivitaet.geplantzeit = val)"
                    />
                  </div>

                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="aktivitaet.effektivdatum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (aktivitaet.effektivdatum = val)"
                      />
                      <label>{{ $t("global.effectivedate") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <time-field
                      :label="$t('global.effectivetime')"
                      placeholder=""
                      :initial="aktivitaet.effektivzeit"
                      :tabindex="5"
                      @update="(val) => (aktivitaet.effektivzeit = val)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Zordnung -->

          <container-headline
            :headline="$t('global.categorization')"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="aktivitaet.typ"
                        label="bezeichnung"
                        :options="typen"
                        :tabindex="6"
                        :placeholder="$t('dashboard.type')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("dashboard.type") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="aktivitaet.status"
                        label="bezeichnung"
                        :options="stati"
                        :tabindex="7"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Zuordnung -->
          </div>

          <!-- ENDE Rechte Seite -->
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.description')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="aktivitaet.beschreibung"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                        :readonly="!editable"
                      ></textarea>
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="aktivitaet-loeschen-modal"
      @confirmed="aktivitaetloeschen"
    />

    <change-watcher-alert />
  </div>
</template>

  <script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";

export default {
  name: "Lead",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    TimeField,
    LoeschenModal,
    SingleSelectPerson,
    MultiSelectPerson,
    SingleSelectFirma,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      verantwortlicher: [{}],
      aktivitaet: {
        person: {},
        verantwortlicher: [{}],
        firma: {},
        typ: {},
        status: {},
      },
      verantwortliche: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    typen() {
      if (this.$store) return this.$store.state.crm.aktivitaettypen;
      return [];
    },
    stati() {
      if (this.$store) return this.$store.state.crm.aktivitaetstati;
      return [];
    },
    navbarTitel: function () {
      if (this.id) {
        return `Aktivität`;
      } else {
        return this.$t("global.newactivity");
      }
    },
  },
  watch: {},
  created() {
    this.loading = true;

    this.loadAktivitaet();
  },
  mounted: function () {
    if (this.typen.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);
  },
  methods: {
    loadAktivitaet() {
      if (this.id) {
        Api.get("crm/aktivitaet/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeAktivitaet(response.data);

            this.loading = false;
          }
        );
      } else {
        this.lead.verantwortlicher = this.angemeldeterMitarbeiter.person;

        this.lead.stage = this.stati.find(
          (stage) => stage.id === this.$CONST("CONFIG").AKTIVITAETSTATUSGEPLANT
        );
        this.editable = true;
        this.loading = false;
      }
    },

    abbrechen() {
      this.$router.push({ name: "aktivitaet-liste" });
    },

    initializeAktivitaet(aktivitaet) {
      if (aktivitaet) {
        this.aktivitaet = aktivitaet;
        this.verantwortlicher = this.aktivitaet.verantwortlicher;

        this.editable = true;

        if (this.berechtigungen.m_lead.update == false) this.editable = false;

        this.initChangeWatcher(this.lead);
      } else {
        console.error("aktivitaet ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;

      this.aktivitaet.verantwortlicher = this.verantwortlicher;
      var json = this.aktivitaet;

      if (!this.id) {
        Api.post("crm/aktivitaet/", json)
          .then((response) => {
            this.initializeAktivitaet(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.activitysavedsuccessfully"),
            });

            this.$router.replace({
              name: "aktivitaet",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("crm/aktivitaet/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeAktivitaet(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.activitysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "aktivitaet-loeschen-modal");
    },

    aktivitaetloeschen() {
      Api.delete("crm/aktivitaet/", { data: [this.id] }).then(() => {
        this.$router.push({ name: "aktivitaet-liste" });
      });
    },

    setVerantwortlicher(person) {
      this.verantwortlicher = person;
    },

    setPerson(person) {
      this.aktivitaet.person = person;
    },

    setFirma(firma) {
      this.aktivitaet.firma = firma;
    },
  },
};
</script>

<style></style>
