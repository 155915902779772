<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <container-headline :headline="$t('global.content')" :col="3"></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model.trim="mail.empfaenger"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.receiveremail") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-model.trim="mail.betreff"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.subject") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="mail.mailtext"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";

import page from "@/mixins/Page";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "MailVorlageStammdaten",
  components: {
    ContainerHeadline,
  },
  mixins: [page],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {},
  watch: {},

  created() {},

  mounted: function () {},

  methods: {},
};
</script>

<style>
</style>
