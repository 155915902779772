<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.correspondence')"
        :col="3"
      >
        <div class="row d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="neueKorrespondenz"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-typewriter" class="mr-2" />
              <span>{{ $t("global.new") }}</span>
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              style="max-height: 300px"
              :items="korrespondenzliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              small
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="korrespondenz-loeschen-modal"
      @confirmed="korrespondenzLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    anmeldung: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false,
    },
    firma: {
      type: String,
      required: false,
    },
    gptyp: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      korrespondenzliste: [],

      fields: [
        { key: "selected", label: "" },
        {
          key: "korrespondenznummer",
          sortable: true,
          label: this.$t("global.number"),
        },
        {
          key: "korrespondenzadresse",
          sortable: true,
          label: this.$t("global.correspondenceaddress"),
        },
        { key: "betreff", sortable: true, label: this.$t("global.subject") },
        {
          key: "versand.datum",
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "verarbeitungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.korrespondenzliste.length == 0) this.getKorrespondez();
    },
  },
  mounted() {
    if (this.shown && this.korrespondenzliste.length == 0)
      this.getKorrespondez();
      
    this.setHeaders("global", [
      "",
      "number",
      "correspondenceaddress",
      "subject",
      "date",
      "status",
    ]);
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  methods: {
    getKorrespondez() {
      this.isBusy = true;

      Api.get("/korrespondenz/", {
        params: {
          anmeldung: this.anmeldung,
          person: this.person,
          firma: this.firma,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.korrespondenzliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(korrespondenzliste) {
      this.$router.push({
        path:
          "/kommunikation/korrespondenz/" + korrespondenzliste.id + "/" + "0",
      });
    },
    /*
    infiniteHandler($state) {
      if (this.shown) {
        Api.get("korrespondenz/", {
          params: this.queryParams,
        }).then((response) => {
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.korrespondenzliste.push(...response.data);
            $state.loaded();
          } else {
            this.korrespondenzliste.push(...response.data);
            $state.complete();
          }
        });
      }
    },*/
    neueKorrespondenz() {
      let json = {
        versand: {
          erstelltvon: this.angemeldeterMitarbeiter.person,
          datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          unterschrift1: { person: { personName: textNotLinked } },
          unterschrift2: { person: { personName: textNotLinked } },
          inhalt: "",
        },
        betreff: "Neue Korrespondenz",
        anmeldung: this.anmeldung,
      };

      if (this.gptyp == "firma") {
        json.empfaenger = [this.firma];
        json.firma = [this.firma];
      } else {
        json.empfaenger = [this.person];
        json.person = [this.person];
      }

      Api.post("korrespondenz/", json).then((response) => {
        this.$router.push({
          name: "korrespondenz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "korrespondenz-loeschen-modal");
    },

    korrespondenzLoeschen() {
      Api.delete("korrespondenz/", {
        params: { ids: this.selectedIds.join(",") },
      }).finally(() => {
        this.getKorrespondez();
      });
    },
  },
};
</script>
