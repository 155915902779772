<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              item-text="key"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        ref="bezeichnungInput"
                        v-model="filterBezeichnung"
                        class="form-control input"
                        type="text"
                        field="bezeichnung"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="bereich"
                        label="bezeichnung"
                        :options="bereiche"
                        :placeholder="$t('global.Section')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.Section") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('navbar.settings') + ': ' + this.lastValidBereich"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      class="btn btn-success"
                      @click="oeffneEinstellungenModal"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="list"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy="isBusy"
                    show-empty
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="oeffneEinstellungenModal"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>

                    <template #empty>
                      <div
                        v-if="ladeFehler"
                        class="text-center text-danger my-2"
                      >
                        <strong>{{ $t("global.errorwhileloading") }}</strong>
                      </div>
                      <div
                        v-if="!ladeFehler"
                        class="text-center text-primary my-2"
                      >
                        <strong>{{ $t("global.nodataavailable") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="isBusy"></loading-overlay>

    <einstellungen-modal
      id="artikel-einstellungen-modal"
      :lastValidBereich="this.lastValidBereich"
      :selectedItem="selectedItem"
      :path="this.path"
      @confirmed="updateListData"
    />
    <loeschen-modal
      id="artikel-loeschen-modal"
      @confirmed="einstellungLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import EinstellungenModal from "@/components/Modals/EinstellungenModal";
import { BSpinner } from "bootstrap-vue";
import store from "@/store";
import { LADE_AUSWAHLWERTE_ARTIKEL } from "@/store/artikel/actions.type";

export default {
  id: "EinstellungenArtikel",
  components: {
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    EinstellungenModal,
    BSpinner,
    Navbar,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.item");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      filterBezeichnung: null,
      list: [],
      listData: [],
      selected: [],
      selectedIds: [],
      selectedItem: {},

      path: "artikel/auswahlwerte/",
      lastValidTableKey: "artikeltypen",
      lastValidBereich: null,
      lastValidTableName: null,

      bereich: [],
      bereiche: [],

      tableFilter: null,

      sortBy: "bezeichnung",
      sortDesc: false,

      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
      ],
    };
  },
  computed: {
    initialData: {
      get() {
        return this.$store.state.artikel[this.lastValidTableKey];
      },
    },
  },
  watch: {
    "$i18n.locale"() {
      this.aktualisiereSprachVariablen();
    },
    filterBezeichnung(val) {
      this.filterBezeichnungFunction(val);
    },
    bereich(val) {
      if (val != null) {
        this.clearSelectedCells();
        this.clearSelectedRows();
        this.listData = this.$store.state.artikel[val.key];
        this.lastValidBereich = val.bezeichnung;
        this.lastValidTableKey = val.key;
        this.lastValidTableName = val.tableName;
      }
    },
    initialData(val) {
      if (val.length > 0) {
        this.listData = val;
        this.list = val;
        this.isBusy = false;
        this.filterBezeichnungFunction(this.$refs.bezeichnungInput._value);
        if (this.bereiche.length === 0 && this.listData.length > 0) {
          this.fillDropDownArray();
          this.aktualisiereSprachVariablen();
        }
      }
    },
  },
  created() {
    this.isBusy = true;
    this.$store.dispatch(`artikel/${LADE_AUSWAHLWERTE_ARTIKEL}`);
  },
  mounted() {},
  methods: {
    aktualisiereSprachVariablen() {
      this.setHeaders("global", ["", "designation"]);
      this.setBereiche("global", "itemtype", [
        "itemtype",
        "itemunit",
      ]);
    },

    fillDropDownArray() {
        var dataForDropDownArr = this.$store.state.artikel;
        var tabelleErlaubt = true;
        var defaultValue = true;

        for (const TABELLEKEY in dataForDropDownArr) {
          if (dataForDropDownArr[TABELLEKEY].length !== 0) {
            for (const SPALTEKEY in dataForDropDownArr[TABELLEKEY][0]) {
              if (
                SPALTEKEY !== "bezeichnung" &&
                SPALTEKEY !== "id" &&
                SPALTEKEY !== "tblName" &&
                SPALTEKEY !== "kuerzel"
              ) {
                tabelleErlaubt = false;
              }
            }

            if (tabelleErlaubt) {
              this.bereiche.push({
                bezeichnung: "",
                key: TABELLEKEY,
                tableName: dataForDropDownArr[TABELLEKEY][0].tblName,
              });
              if (defaultValue) {
                this.bereich = this.bereiche[0];
                this.lastValidBereich = this.bereich.bezeichnung;
                this.lastValidTableName = this.bereich.tblName;
                defaultValue = false;
              }
            }
            tabelleErlaubt = true;
          }
        }
    },
    clearSelectedCells() {
      this.list.forEach((data) => {
        if (data.selected) {
          data.selected = false;
        }
      });
    },
    updateListData(data) {
      this.clearSelectedRows();
      this.selectedIds = [];
      this.selectedItem = {};
      this.selectedObjects = [];
      this.$store.state.artikel[this.lastValidTableKey] = data[this.lastValidTableKey];
    },

    filterBezeichnungFunction(val) {
      if (val)
        this.list = this.listData.filter((el) =>
          el.bezeichnung.toLowerCase().includes(val.toLowerCase())
        );
      else this.list = this.listData;
    },
    oeffneLoeschenModal() {
      if (this.selectedIds.length !== this.listData.length) {
      var standartText = " " +
                (this.$i18n.locale == "en"
                  ? this.lastValidBereich.toLowerCase()
                  : this.lastValidBereich) +
                " " +
                this.$t("notification.Selected")
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "artikel-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text:
            this.lastValidBereich == "Artikeleinheit"
              ? this.$t("notification.No")
              + standartText
              : this.$t("notification.no")
              + standartText
        });
      }
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.oneselectionvaluemustremain"),
        });
      }
    },

    einstellungLoeschen() {
      this.isBusy = true;
      Api.delete(this.path, {
        params: {
          tblName: this.lastValidTableName,
          ids: this.selectedIds.join(","),
        },
      }).then((response) => {
        this.updateListData(response.data);
        this.ladeFehler = false;
        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.lastValidBereich + " " + this.$t("notification.deleted"),
        });
      })
      .catch((e) => {
          this.ladeFehler = true;
          this.isBusy = false;
        });
    },

    oeffneEinstellungenModal(ausgewaehlteZeile) {
      ausgewaehlteZeile.tblName = this.lastValidTableName;
      this.selectedItem = Object.assign({}, ausgewaehlteZeile);
      this.$bus.$emit(
        "openEinstellungenModal",
        "artikel-einstellungen-modal"
      );
    },
  },
};
</script>