<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  v-if="editable"
                  class="btn btn-success mr-2"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>

                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4">
                <button
                  class="btn btn-danger"
                  @click="oeffneLoeschenModal"
                  v-if="berechtigungen.b_bildung_bildungsportfolio.delete"
                >
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <stammdaten :subvention="subvention" :editable="editable" />
      </div>

      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <subventionsdaten
          :subvention="subvention"
          :editable="editable"
          @kantoneChanged="setKantone"
        />
      </div>

      <div v-if="subventionid" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'subvention',
                    params: { subventionid: subventionid, anzeige: 0 },
                  }"
                  >{{ $t("dashboard.subsidycontributions") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 1 }"
                  :to="{
                    name: 'subvention',
                    params: { subventionid: subventionid, anzeige: 1 },
                  }"
                  >{{ $t("dashboard.settlements") }}</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <Subventionsbeitraege
                  :subvention="subventionid"
                  :kantone="kantone"
                  :shown="anzeige == 0 ? true : false"
                  :editable="editable"
                  :key="beitragReiterKey"
                  @updateBeitraege="beitragReiterKey++"
                />
              </div>

              <div v-show="anzeige === 1">
                <Subventionsabrechnungen
                  :subvention="subventionid"
                  :shown="anzeige == 1 ? true : false"
                  :editable="editable"
                  :key="beitragReiterKey"
                  @updateBeitraege="beitragReiterKey++"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="subvention-loeschen-modal"
      @confirmed="subventionLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Stammdaten from "@/components/Subvention/Stammdaten.vue";
import Subventionsdaten from "@/components/Subvention/Subventionsdaten.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import Subventionsbeitraege from "@/components/Subvention/Subventionsbeitraege.vue";
import Subventionsabrechnungen from "@/components/Subvention/Subventionsabrechnungen.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "Subvention",
  components: {
    HeadMenu,
    Navbar,
    Stammdaten,
    Subventionsdaten,
    Subventionsbeitraege,
    Subventionsabrechnungen,
    LoeschenModal,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    subventionid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      subvention: {
        person: {},
        subventionskanton: {},
        subventionsstatus: {},
        ausbildungstatus: {},
        ausbildung: { abschluss: {}, ausbildungtyp: {}, ausbildungdauer: {} },
        subventionsbeitrag: {},
      },
      kantone: [],
      beitragReiterKey: 0,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.subvention) {
        return this.subvention?.portfolionummer
          ? `${this.$t("global.subsidy")} ${this.subvention.portfolionummer}`
          : this.$t("global.subsidy");
      } else {
        return this.$t("global.newsubsidy");
      }
    },
  },

  created() {
    this.loading = true;

    if (this.subventionid) {
      Api.get("bildung/subvention/", { params: { id: this.subventionid } })
        .then((response) => {
          this.initializeSubvention(response.data);
        })
        .finally(() => (this.loading = false));
    } else {
      this.loading = false;
      this.editable = true;
    }
  },

  mounted: function () {},

  methods: {
    abbrechen() {
      this.$router.push({ name: "subventionsliste" });
    },

    setKantone(val) {
      this.kantone = val;
    },

    initializeSubvention(subvention) {
      if (subvention) {
        this.subvention = subvention;

        if (!this.subvention.ausbildung) {
          this.subvention.ausbildung = {
            abschluss: {},
            ausbildungtyp: {},
            ausbildungdauer: {},
          };
        }

        this.initChangeWatcher(this.subvention);
      } else {
        console.error("subvention ist null");
      }

      this.editable = this.berechtigungen.b_bildung_bildungsportfolio.update;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.subvention);

      if (!this.subventionid) {
        Api.post("bildung/subvention/", json)
          .then((response) => {
            this.initializeSubvention(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.subsidysavedsuccessfully"),
            });

            this.$router.replace({
              name: "subvention",
              params: { subventionid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("bildung/subvention/", json, {
          params: { id: this.subventionid },
        })
          .then((response) => {
            this.initializeSubvention(response.data);
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.subsidysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    subventionLoeschen() {
      Api.delete("bildung/subvention/", {
        params: { ids: [this.subventionid].join(",") },
      }).then(() => {
        this.$router.push({ name: "subventionsliste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "subvention-loeschen-modal");
    },
  },
};
</script>

<style>
</style>
