<template>
  <div class="tile-container">
    <div id="aktivitaet-bearbeiten-modal" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{
                aktivitaet.id
                  ? $t("global.editactivity")
                  : $t("global.addactivity")
              }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-6">
                <single-select-firma
                  id="aktivitaet-firma"
                  :firma="kontaktFirma"
                  :editable="true"
                  :allowOpen="true"
                  :label="$t('global.contactcompany')"
                  @confirmed="setFirma"
                />
              </div>
              <div class="col-xl-6">
                <single-select-person
                  id="aktivitaet-person"
                  :person="kontaktPerson"
                  :firma="kontaktFirma"
                  :label="$t('global.contactperson')"
                  editable
                  :allowOpen="false"
                  @confirmed="setPerson"
                  :tabindex="1"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="aktivitaet.geplantdatum"
                    placeholder=" "
                    :show-icon="true"
                    :tabindex="2"
                    @update="(val) => (aktivitaet.geplantdatum = val)"
                  />
                  <label>{{ $t("global.planneddate") }}</label>
                </div>
              </div>
              <div class="col-xl-3">
                <time-field
                  :label="$t('global.plannedtime')"
                  placeholder=""
                  :initial="aktivitaet.geplantzeit"
                  :tabindex="3"
                  @update="(val) => (aktivitaet.geplantzeit = val)"
                />
              </div>

              <div class="col-xl-3">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="aktivitaet.effektivdatum"
                    placeholder=" "
                    :show-icon="true"
                    :tabindex="4"
                    @update="(val) => (aktivitaet.effektivdatum = val)"
                  />
                  <label>{{ $t("global.effectivedate") }}</label>
                </div>
              </div>
              <div class="col-xl-3">
                <time-field
                  :label="$t('global.effectivetime')"
                  placeholder=""
                  :initial="aktivitaet.effektivzeit"
                  :tabindex="5"
                  @update="(val) => (aktivitaet.effektivzeit = val)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="aktivitaet.typ"
                    label="bezeichnung"
                    :options="typen"
                    :tabindex="6"
                    :placeholder="$t('dashboard.type')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("dashboard.type") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="aktivitaet.status"
                    label="bezeichnung"
                    :options="stati"
                    :tabindex="7"
                    :placeholder="$t('global.status')"
                  >
                    <span slot="no-options">{{
                      $t("notification.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.status") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    v-model.trim="aktivitaet.bezeichnung"
                    class="form-control"
                  />
                  <label>{{ $t("dashboard.topic") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <textarea
                    v-model.trim="aktivitaet.beschreibung"
                    type="text"
                    class="form-control"
                    placeholder=" "
                    rows="5"
                  />
                  <label>{{ $t("global.descriptiondocumentation") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12" style="padding: 0px !important">
                <multi-select-person
                  id="aktivitaet-verantwortlicher"
                  :personen="verantwortlicher"
                  :label="$t('global.Responsible')"
                  editable
                  :allowOpen="false"
                  @confirmed="setVerantwortlicher"
                  :tabindex="8"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button type="button" class="btn btn-primary" @click="uebernehmen">
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";

export default {
  name: "AktivitaetModal",
  components: {
    ModalCloseButton,
    SingleSelectPerson,
    SingleSelectFirma,
    MultiSelectPerson,
    DatePicker,
    TimeField,
  },
  props: {
    angemeldeterMitarbeiter: { type: Object },
  },
  data() {
    return {
      kontaktPerson: {},
      kontaktFirma: {},
      verantwortlicher: [{}],
      aktivitaet: {
        person: {},
        verantwortlicher: [{}],
        firma: {},
        typ: {},
        status: {},
      },
      verantwortliche: [],
      neueAktivitaet: false,
      shown: false,
    };
  },
  computed: {
    typen() {
      if (this.$store) return this.$store.state.crm.aktivitaettypen;
      return [];
    },
    stati() {
      if (this.$store) return this.$store.state.crm.aktivitaetstati;
      return [];
    },
  },
  watch: {},
  mounted() {
    if (this.typen.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    this.$bus.$on("aktivitaet-modal-show", (data) => {
      if (data.neu) {
        data.status = this.stati.find(
          (status) => status.id == this.$CONST("CONFIG").DEFAULTACTIVITYSTATUS
        );

        data.typ = this.typen.find(
          (typ) => typ.id == this.$CONST("CONFIG").DEFAULTACTIVITYTYPE
        );
      }

      this.aktivitaet = Object.assign({}, data);
      this.kontaktPerson = this.aktivitaet.person;
      this.kontaktFirma = this.aktivitaet.firma;
      this.verantwortlicher = this.aktivitaet.verantwortlicher;
      this.neueAktivitaet = data.neu ? true : false;

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    setPerson(person) {
      this.kontaktPerson = person;
    },

    setFirma(firma) {
      this.kontaktFirma = firma;
    },

    setVerantwortlicher(person) {
      // this.verantwortliche.push(person)
      this.verantwortlicher = person;
    },

    openModal() {
      this.shown = true;

      $("#aktivitaet-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#aktivitaet-bearbeiten-modal").modal("hide");
    },

    loeschen() {
      this.$emit("aktivitaetDeleted", this.aktivitaet);
      this.shown = false;
      $("#aktivitaet-bearbeiten-modal").modal("hide");
    },
    uebernehmen() {
      this.aktivitaet.person = this.kontaktPerson;
      this.aktivitaet.firma = this.kontaktFirma;
      this.aktivitaet.verantwortlicher = this.verantwortlicher;

      if (this.neueAktivitaet) {
        this.$emit("aktivitaetCreated", this.aktivitaet);
      } else {
        this.$emit("aktivitaetChanged", this.aktivitaet);
      }

      this.closeModal();
    },
  },
};
</script>

<style scoped>
/* Scoped Style, da die Paddings der .col-* ansonsten von den Tiles der Startseite ueberschrieben werden */
[class^="col-"],
[class*="col-"] {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
</style>
