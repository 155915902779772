 <template>
  <div class="col-xl-12 pr-4 mb-3">
    <container-headline
      class="col-xl-12 pl-0"
      :headline="$t('global.receiver') + ' (' + teilnehmer.length + ')'"
      :col="3"
    >
      <div class="row mt-2 d-flex justify-content-between">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            @click="neuerTeilnehmer"
            v-if="editable"
          >
            <span class="mr-2">
              <font-awesome-icon icon="fa-solid fa-user-check" />
            </span>
            {{ $t("global.newreceiver") }}
          </button>

          <button
            class="btn btn-primary mr-2"
            @click="selectAll(true)"
            v-if="editable"
          >
            <span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-ballot-check" />
            </span>
            {{ $t("global.selectall") }}
          </button>

          <button
            class="btn btn-primary mr-2"
            @click="selectAll(false)"
            v-if="editable && selectedIds.length > 0"
          >
            <span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-ballot" />
            </span>
            {{ $t("global.deselectall") }}
          </button>
        </div>
        <div>
          <div class="form-group" style="padding-top: 0px">
            <input
              v-model.trim="filterFirma"
              class="form-control"
              :readonly="!editable"
              placeholder=" "
              @keyup.enter="resetLoading()"
            />
            <label>{{ $t("global.company") }}</label>
          </div>
        </div>
        <div>
          <div class="form-group" style="padding-top: 0px">
            <input
              v-model.trim="filterPerson"
              class="form-control"
              :readonly="!editable"
              placeholder=" "
              @keyup.enter="resetLoading()"
            />
            <label>{{ $t("global.person") }}</label>
          </div>
        </div>
        <div class="mr-4">
          <button
            class="btn btn-danger"
            @click="oeffneLoeschenModal"
            v-if="editable && selectedIds.length > 0"
          >
            <font-awesome-icon icon="fa-duotone fa-trash" />
            <span>{{ $t("global.delete") }} ({{ selectedIds.length }})</span>
          </button>
        </div>
      </div>
    </container-headline>
    <div class="row">
      <div class="col-xl-12 block belegliste br-t-l-0">
        <div
          class="row ml-2"
          style="max-height: 400px; width: 99%; overflow-y: auto"
          :key="teilnehmerKey"
        >
          <div
            v-for="(item, index) in teilnehmer"
            v-bind:key="`teilnehmer-${index}`"
            class="col-xl-2 mt-2"
          >
            <div
              :style="
                (item.selected
                  ? 'background-color: lightgreen; color:white; font-weight: bold'
                  : item.id) +
                ';cursor: pointer; font-size: 10pt; background-color: rgba(0, 0, 255, 0.1);'
              "
            >
              <div class="row">
                <div class="col-xl-2" style="text-align: left">
                  <span @click="selectTeilnehmer(item)" class="ml-2">
                    <font-awesome-icon
                      icon="fa-solid fa-check"
                      size="sm"
                    /> </span
                  ><br />
                  <span @click="teilnehmerBearbeiten(item)" class="ml-2">
                    <font-awesome-icon icon="fa-regular fa-pencil" size="sm" />
                  </span>
                </div>
                <div class="col-xl-10">
                  {{ getFirma(item) }}<br />
                  {{ getPerson(item) }}<br />
                  {{ getAdresse(item) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gPundFirmaSuche
      id="teilnehmerHinzufuegenModal"
      @confirmed="teilnehmerHinzufuegen"
      @updated="teilnehmerUpdaten"
    ></gPundFirmaSuche>

    <loeschen-modal
      id="teilnehmer-loeschen-modal"
      @confirmed="teilnehmerLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import GPundFirmaSuche from "@/components/Geschaeftspartner/GPundFirmaSuche";
import { v4 as uuid } from "uuid";
import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCalendarClock } from "@fortawesome/pro-duotone-svg-icons";
import {
  faBallot,
  faUserCheck,
  faBallotCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faXmark,
  faPersonRunning,
  faArrowRotateRight,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faCalendarClock,
  faCheck,
  faXmark,
  faPersonRunning,
  faArrowRotateRight,
  faBallot,
  faUserCheck,
  faBallotCheck
);

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,

    GPundFirmaSuche,
  },
  mixins: [],
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      teilnehmerKey: uuid(),
      teilnehmer: [],
      selectedIds: [],
      filterPerson: "",
      filterFirma: "",
    };
  },
  watch: {
    shown(val) {
      if (val && this.teilnehmer.length == 0) this.getTeilnehmer();
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
    kampagneteilnehmerstati: {
      get() {
        return this.$store.state.crm.kampagneteilnehmerstati;
      },
    },
  },
  mounted() {
    if (this.kampagneteilnehmerstati.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    this.getTeilnehmer();
  },
  created() {},
  methods: {
    resetLoading() {
      this.getTeilnehmer();
    },

    getFirma(item) {
      return item.firma ? item.firma.name : "<ohne Firma>";
    },

    getPerson(item) {
      return item.person
        ? (item.person.vorname ? item.person.vorname + " " : "") +
            item.person.name
        : "<ohne Person>";
    },

    getAdresse(item) {
      if (!item.adresse) return "";

      return (
        item.adresse.strasse_nr +
        ", " +
        item.adresse.plz +
        " " +
        item.adresse.ort
      );
    },

    getTeilnehmer() {
      this.loading = true;
      Api.get("korrespondenz/empfaenger/", {
        params: {
          korrespondenz: this.korrespondenz.id,
          firma: this.filterFirma,
          person: this.filterPerson,
        },
      }).then((response) => {
        this.teilnehmer = response.data;
        this.$emit("korrespondenz-teilnehmer-update", this.teilnehmer);
        //this.$emit("updatedTeilnehmer", this.teilnehmer);
      });
    },

    statusSetzen(statusId) {
      let json = [];

      this.selectedIds.forEach((id) => {
        var foundIndex = this.teilnehmer.findIndex(
          (teilnehmer) => teilnehmer.id == id
        );

        json.push({
          id: this.teilnehmer[foundIndex].id,
          person: this.teilnehmer[foundIndex].person,
          status: statusId,
          kampagne: this.kampagne,
        });

        this.teilnehmer[foundIndex].status = this.kampagneteilnehmerstati.find(
          (status) => status.id == statusId
        );
      });

      this.selectedIds = [];
      this.teilnehmer.forEach((teilnehmer) => {
        teilnehmer.selected = false;
      });

      Api.put("korrespondenz/empfaenger/", {
        json,
        params: {
          kampagne: this.kampagne,
        },
      });

      this.$bus.$emit("korrespondenz-teilnehmer-update", this.teilnehmer);
    },

    selectAll(status) {
      this.teilnehmer.forEach((teilnehmer) => {
        teilnehmer.selected = status;
      });

      this.selectedIds = this.teilnehmer
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.teilnehmerKey = uuid();
    },

    selectTeilnehmer(teilnehmer) {
      let realItem = this.teilnehmer.find((item) => item.id == teilnehmer.id);
      realItem.selected = teilnehmer.selected ? false : true;

      this.selectedIds = this.teilnehmer
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.teilnehmerKey = uuid();
    },

    neuerTeilnehmer() {
      this.$bus.$emit(
        "openGeschaeftspartnerModal",
        "teilnehmerHinzufuegenModal"
      );
    },
    teilnehmerBearbeiten(empfaenger) {
      console.log(empfaenger);
      this.$bus.$emit("empfaenger-editieren", empfaenger);
    },

    teilnehmerHinzufuegen(empfaenger) {
      let json = {
        korrespondenz: this.korrespondenz.id,
        person: empfaenger.person?.id,
        firma: empfaenger.firma?.id,
      };

      Api.post("korrespondenz/empfaenger/", json)
        .then(() => {
          this.getTeilnehmer();

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.campaignparticipantssavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },

    teilnehmerUpdaten(empfaenger) {
      let json = {
        id: empfaenger.id,
        korrespondenz: this.korrespondenz.id,
        person: empfaenger.person?.id,
        firma: empfaenger.firma?.id,
      };

      Api.put("korrespondenz/empfaenger/", json, {
        params: { id: json.id },
      })
        .then(() => {
          this.getTeilnehmer();

          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.campaignparticipantssavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "teilnehmer-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noparticipantsselected"),
        });
      }
    },

    teilnehmerLoeschen() {
      Api.delete("korrespondenz/empfaenger/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getTeilnehmer();
      });
    },
  },
};
</script>
