<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <container-headline
      :headline="$t('global.receiversender')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="korrespondenz.kuerzel"
                  class="form-control"
                  :readonly="!editable"
                />
                <label>{{ $t("global.shortdesignation") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="korrespondenz.bezeichnung"
                  class="form-control"
                  :readonly="!editable"
                />
                <label>{{ $t("global.designation") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <v-select
                  v-model="korrespondenz.korrespondenzkategorie"
                  label="bezeichnung"
                  :options="kategorien"
                  :disabled="!editable"
                  :placeholder="$t('global.category')"
                  :reduce="(kk) => kk.id"
                >
                  <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.category") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <button
                  class="btn"
                  @click="setBriefpapier"
                  v-bind:class="{
                    'btn-primary': korrespondenz.briefpapier,
                  }"
                >
                  <span>{{ $t("global.stationery") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import store from "@/store";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

import page from "@/mixins/Page";

export default {
  name: "KorrespondenzVorlageStammdaten",
  components: {
    ContainerHeadline,
  },
  mixins: [page],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  computed: {
    kategorien: {
      get() {
        return this.$store.state.korrespondenz.kategorien;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.kategorien.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    setBriefpapier() {
      this.korrespondenz.briefpapier = !this.korrespondenz.briefpapier;
    },
  },
};
</script>

<style>
</style>
