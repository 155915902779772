<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        headline="Subventionsabrechnungen"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="oeffneSubventionsabrechnungErstellenModal()"
              v-if="editable"
            >
              <font-awesome-icon
                icon="fa-duotone fa-dollar-sign"
                class="mr-2"
              />
              {{ $t("global.newsettlement") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="abrechnungen"
                :fields="fields"
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <subventionsabrechnung-erstellen-modal
      id="subventionsabrechnung-erstellen-modal"
      :editable="editable"
      :subvention="subvention"
      @abrechnungErstellt="getAbrechnung()"
    />

    <loeschen-modal
      id="subventionsabrechnung-loeschen-modal"
      @confirmed="subventionsAbrechnungLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import numeral from "numeral";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import SubventionsabrechnungErstellenModal from "@/components/Modals/SubventionsabrechnungErstellenModal";

import reiter from "@/mixins/Reiter";

export default {
  name: "subventionsabrechnungen",
  components: {
    ContainerHeadline,
    LoeschenModal,
    SubventionsabrechnungErstellenModal,
  },
  mixins: [reiter],
  props: {
    subvention: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      abrechnungen: [],

      fields: [
        { key: "selected", label: "" },
        {
          key: "abrechnungsnummer",
          sortable: true,
          label: this.$t("global.settlementnumber"),
        },
        {
          key: "subventionsbeitrag.bezeichnung",
          sortable: true,
          label: this.$t("global.subsidycontribution"),
        },
        {
          key: "subvention.subventionskanton.bezeichnung",
          sortable: true,
          label: this.$t("global.subsidycanton"),
        },
        {
          key: "antrag",
          sortable: true,
          label: this.$t("global.request"),
        },
        {
          key: "abrechnung",
          sortable: true,
          label: this.$t("global.settlement"),
        },
        {
          key: "periode.bezeichnung",
          sortable: true,
          label: this.$t("global.period"),
        },
        {
          key: "antragstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.requeststatus"),
        },
        {
          key: "abrechnungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.settlementstatus"),
        },
        {
          key: "betrag",
          sortable: true,
          label: this.$t("global.amount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
      ],
    };
  },
  watch: {
    shown(val) {
      if (val && this.abrechnungen.length == 0) this.getAbrechnung();
    },
  },
  computed: {},
  mounted() {
    if (this.shown) this.getAbrechnung();
    this.setHeaders("global", [
      "",
      "settlementnumber",
      "subsidycontribution",
      "subsidycanton",
      "request",
      "settlement",
      "period",
      "requeststatus",
      "settlementstatus",
      "amount",
    ]);
  },
  created() {},
  methods: {
    details(abrechnung) {
      this.$router.push({
        name: "subventionsabrechnung",
        params: {
          subventionsabrechnungid: abrechnung.id,
        },
      });
    },
    getAbrechnung() {
      Api.get("subvention/abrechnung/", {
        params: {
          subvention: this.subvention,
        },
      }).then((response) => {
        this.abrechnungen = response.data;
      });
    },
    oeffneSubventionsabrechnungErstellenModal() {
      this.$bus.$emit("open-modal", "subventionsabrechnung-erstellen-modal");
    },
    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit(
          "openLoeschenModal",
          "subventionsabrechnung-loeschen-modal"
        );
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nosubsidysettlementsselectedtodelete"),
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((abr) => {
          abr.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((abr) => {
          abr.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
    subventionsAbrechnungLoeschen() {
      Api.delete("subvention/abrechnung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.selectedIds = [];
        this.getAbrechnung();
      });
    },
  },
};
</script>
