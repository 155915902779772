<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-fluid">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Abschluss {{ abschluss.designation }}</h4>
        </div>
        <div class="modal-body">
          <div class="row px-2">
            <div
              class="block block-kopf clickable"
              v-for="(promotionsblock, index) in promotionsbloecke"
              :key="'promotionsblock-' + index"
              @click="setPromotionsblock(index)"
              :style="
                aktiverPromotionsblock == index
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0"
                >{{ promotionsblock.designation }} ({{
                  anzahlStudentenproBlock(promotionsblock.id)
                }})</span
              >
            </div>
          </div>
          <div class="row px-2">
            <div class="col-12 block br-t-l-0 belegliste w-100">
              <b-table
                class="clickable"
                no-border-collapse
                responsive
                ref="selectableTable"
                small
                tbody-tr-class="item"
                show-empty
                :items="torliste"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                @row-clicked="details"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>

                <template v-slot:cell(chevron)="row">
                  <div v-if="row.detailsShowing">
                    <font-awesome-icon icon="fa-solid fa-chevron-up" />
                  </div>
                  <div v-if="!row.detailsShowing">
                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                  </div>
                </template>

                <template #row-details="row">
                  <div class="container-fluid cursor-default">
                    <div class="row border border-primary font-weight-bold">
                      <div class="col-2 border-right border-primary">
                        Promotionsgruppe
                      </div>
                      <div class="col-2 border-right border-primary">Modul</div>
                      <div class="col-1 border-right border-primary">CP</div>
                      <div class="col-2 border-right border-primary">
                        QV [0.1]
                      </div>
                      <div class="col-2 border-right border-primary">
                        Ø Promotionsgruppen [0.1]
                      </div>
                      <div class="col-1 border-right border-primary">CP</div>
                      <div class="col-2">CP (Modulgruppe)</div>
                    </div>

                    <div
                      class="row border border-top-0 border-primary"
                      v-for="(promotionsgruppe, index) in row.item
                        .promotionblocks[aktiverPromotionsblock]
                        .promotiongroups"
                      :key="'tor-' + index"
                    >
                      <!-- PROMOTIONSGRUPPE -->
                      <div
                        class="col-2 border-right border-primary p-2"
                        :class="getPromotionsgruppenfarbe(index)"
                      >
                        {{ promotionsgruppe.designation }}
                      </div>

                      <!-- MODUL -->
                      <div class="col-2 border-right border-primary">
                        <div
                          class="p-2"
                          v-for="(modul, index2) in promotionsgruppe.subjects"
                          :key="'modulkuerzel-' + index2"
                        >
                          {{ modul.short }}
                        </div>
                      </div>

                      <!-- MODUL CP -->
                      <div
                        class="
                          col-1
                          border-right border-primary
                          p-0
                          text-center
                        "
                      >
                        <div
                          class="p-2"
                          v-for="(modul, index3) in promotionsgruppe.subjects"
                          :key="'modulcp-' + index3"
                        >
                          {{ modul.cp }}
                        </div>
                      </div>

                      <!-- MODUL NOTEN -->
                      <div
                        class="
                          col-2
                          border-right border-primary
                          p-0
                          text-center
                        "
                      >
                        <div
                          v-for="(modul, index4) in promotionsgruppe.subjects"
                          :key="'modulnote-' + index4"
                          :class="setTornotenStyle(modul)"
                        >
                          <div
                            v-for="(bewertung, index5) in modul.tor_grades"
                            :key="'bewertung-' + index5"
                          >
                            <div
                              class="
                                d-flex
                                position-relative
                                justify-content-center
                              "
                              v-if="bewertung.credited"
                            >
                              <div class="ml-auto mr-auto">Angerechnet</div>

                              <div
                                class="clickable position-absolute right-5"
                                data-toggle="tooltip"
                                title="Anrechnung entfernen"
                                @click="bewertung.credited = false"
                              >
                                <font-awesome-icon icon="fa-duotone fa-trash" />
                              </div>
                            </div>

                            <!-- MANUELLE NOTE VORHANDEN -->
                            <div
                              class="
                                d-flex
                                position-relative
                                justify-content-center
                              "
                              v-if="
                                istHoechsteNote(modul, index5) &&
                                !bewertung.credited
                              "
                            >
                              <div
                                class="position-absolute"
                                :class="
                                  modul.tor_grades.length > 1
                                    ? 'left-25'
                                    : 'left-5'
                                "
                                v-if="
                                  !bewertung.input_active &&
                                  bewertung.manual_grade &&
                                  bewertung.manual_grade != 0 &&
                                  bewertung.manual_grade != 'NaN' &&
                                  bewertung.manual_grade != undefined &&
                                  bewertung.tor_grade != bewertung.manual_grade
                                "
                              >
                                <font-awesome-icon
                                  v-tooltip.hover
                                  :title="
                                    bewertung.tor_grade &&
                                    bewertung.tor_grade != 0 &&
                                    bewertung.tor_grade != 'NaN' &&
                                    bewertung.tor_grade != undefined
                                      ? 'Diese Note wurde manuell angepasst. Ursprüngliche Note: ' +
                                        bewertung.tor_grade
                                      : 'Diese Note wurde manuell angepasst. Die ursprüngliche Note war nicht gesetzt.'
                                  "
                                  icon="fa-duotone fa-circle-info"
                                  class="mx-auto text-primary"
                                  style="font-size: 16px"
                                />
                              </div>

                              <!-- MEHRERE NOTEN VORHANDEN -->

                              <div
                                class="position-absolute left-5"
                                :class="
                                  bewertung.input_active ? 'left-9 top-4' : ''
                                "
                                v-if="
                                  istRepetend(modul) && !bewertung.input_active
                                "
                              >
                                <font-awesome-icon
                                  v-tooltip.hover
                                  :title="
                                    getTiefsteNote(modul) != 0 &&
                                    getTiefsteNote(modul) != 'NaN' &&
                                    getTiefsteNote(modul) != undefined
                                      ? 'Dieses Modul wurde repetiert. Die tiefere Note war: ' +
                                        getTiefsteNote(modul)
                                      : 'Dieses Modul wurde repetiert. Die tiefere Note war nicht gesetzt'
                                  "
                                  icon="fa-duotone fa-exclamation-triangle"
                                  class="mx-auto text-primary"
                                  style="font-size: 16px"
                                />
                              </div>

                              <!-- DEFINITIVE NOTE ANZEIGEN (höchste Manuelle Note falls vorhanden, ansonsten höchste Note)-->

                              <div
                                class="ml-auto mr-auto"
                                v-if="
                                  !bewertung.input_active &&
                                  istHoechsteNote(modul, index5)
                                "
                              >
                                {{
                                  bewertung.manual_grade &&
                                  bewertung.manual_grade != 0 &&
                                  bewertung.manual_grade != "NaN" &&
                                  bewertung.manual_grade != undefined
                                    ? bewertung.manual_grade
                                    : bewertung.definitive_grade &&
                                      bewertung.definitive_grade != 0 &&
                                      bewertung.definitive_grade != "NaN" &&
                                      bewertung.definitive_grade != undefined
                                    ? bewertung.definitive_grade
                                    : "-"
                                }}
                              </div>

                              <!-- NOTE MANUELL ANPASSEN ICON -->

                              <div
                                v-if="
                                  !bewertung.input_active &&
                                  istHoechsteNote(modul, index5)
                                "
                                class="clickable position-absolute right-5"
                                data-toggle="tooltip"
                                title="Note bearbeiten"
                                @click="bewertung.input_active = true"
                              >
                                <font-awesome-icon
                                  icon="fa-regular fa-pencil"
                                />
                              </div>

                              <!-- NOTE MANUELL ANPASSEN -->

                              <div
                                v-if="bewertung.input_active"
                                class="
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                  position-relative
                                "
                              >
                                <div class="col-3 form-group m-0 p-0">
                                  <input
                                    v-model="bewertung.manual_grade"
                                    class="form-control text-center"
                                    :placeholder="bewertung.definitive_grade"
                                  />
                                </div>
                                <button
                                  class="btn btn-sm btn-success btn-tiny ml-1"
                                  data-toggle="tooltip"
                                  :title="
                                    isNaN(Number(bewertung.manual_grade))
                                      ? 'Eingegebener Wert ist keine Zahl'
                                      : 'Eingabe bestätigen'
                                  "
                                  :disabled="
                                    isNaN(Number(bewertung.manual_grade))
                                  "
                                  @click="bewertung.input_active = false"
                                >
                                  <font-awesome-icon icon="fa-solid fa-check" />
                                </button>

                                <div
                                  class="btn btn-sm btn-primary btn-tiny ml-1"
                                  data-toggle="tooltip"
                                  title="Modul anrechnen"
                                  @click="
                                    (bewertung.credited = true),
                                      (bewertung.input_active = false)
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-duotone fa-file-certificate"
                                  />
                                </div>

                                <div
                                  class="btn btn-sm btn-danger btn-tiny ml-1"
                                  data-toggle="tooltip"
                                  title="Manuelle Note entfernen"
                                  @click="resetManuelleNote(modul, index5)"
                                >
                                  <font-awesome-icon
                                    icon="fa-duotone fa-trash"
                                  />
                                </div>

                                <div
                                  class="clickable position-absolute right-2"
                                  data-toggle="tooltip"
                                  title="Schliessen"
                                  @click="bewertung.input_active = false"
                                >
                                  <font-awesome-icon
                                    icon="fa-regular fa-xmark"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- KEINE TORNOTEN FÜR DAS MODUL VORHANDEN -->
                          <div
                            class="d-flex position-relative bg-grey"
                            v-if="modul.tor_grades.length == 0"
                          >
                            <div class="ml-auto mr-auto">-</div>
                            <div
                              class="clickable position-absolute right-5"
                              data-toggle="tooltip"
                              title="Manuelle Note setzen."
                              @click="noteHinzufuegen(modul)"
                            >
                              <font-awesome-icon icon="fa-regular fa-plus" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- PROMOTIONSGRUPPE NOTENDURCHSCHNITT -->
                      <div
                        class="
                          col-2
                          border-right border-primary
                          p-0
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        :class="
                          promotionsgruppe.average_grade &&
                          promotionsgruppe.average_grade != 0 &&
                          promotionsgruppe.average_grade != 'NaN' &&
                          promotionsgruppe.average_grade != undefined
                            ? promotionsgruppe.passed
                              ? 'bg-success-light'
                              : 'bg-danger-light'
                            : 'bg-grey'
                        "
                      >
                        {{
                          promotionsgruppe.average_grade &&
                          promotionsgruppe.average_grade != 0 &&
                          promotionsgruppe.average_grade != "NaN" &&
                          promotionsgruppe.average_grade != undefined
                            ? promotionsgruppe.average_grade
                            : "-"
                        }}
                      </div>

                      <!-- ERREICHTE CP PRO MODUL -->
                      <div
                        class="
                          col-1
                          border-right border-primary
                          p-0
                          text-center
                        "
                      >
                        <div
                          class="p-2"
                          v-for="(modul, index6) in promotionsgruppe.subjects"
                          :key="'modulcp-' + index6"
                        >
                          <div v-if="modul.tor_grades.length > 0">
                            <div
                              v-for="(bewertung, index7) in modul.tor_grades"
                              :key="'cp_bewertung-' + index7"
                            >
                              <div
                                class="text-center"
                                v-if="istHoechsteNote(modul, index7)"
                              >
                                {{ bewertung.cp }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="text-center"
                            v-if="modul.tor_grades.length == 0"
                          >
                            0
                          </div>
                        </div>
                      </div>

                      <!-- TOTAL CP DER PROMOTIONSGRUPPE -->
                      <div
                        class="
                          col-2
                          p-0
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                      >
                        {{ promotionsgruppe.cp }}
                      </div>
                    </div>

                    <!-- BEWERTUNG -->

                    <!-- ABSCHLUSS -->
                    <div class="row border border-primary font-weight-bold">
                      <div
                        class="
                          col-2
                          border-right border-primary
                          font-weight-bold
                          p-2
                        "
                      >
                        Abschluss
                      </div>
                      <div class="col-2 border-right border-primary p-2"></div>
                      <div
                        class="
                          col-1
                          border-right border-primary
                          text-center
                          p-2
                        "
                      >
                        {{
                          berechneTotaMoeglichelCP(
                            row.item.promotionblocks[aktiverPromotionsblock]
                              .promotiongroups
                          )
                        }}
                      </div>
                      <div class="col-2 border-right border-primary p-2"></div>
                      <div
                        class="
                          col-2
                          border-right border-primary
                          text-center
                          p-2
                        "
                      ></div>
                      <div class="col-1 border-right border-primary p-2"></div>
                      <div
                        class="col-2 font-weight-bold text-center p-2"
                        :class="
                          getPromotionsfarbe(
                            'erreichteCP',
                            row.item.promotionblocks[aktiverPromotionsblock]
                          )
                        "
                      >
                        {{
                          row.item.promotionblocks[aktiverPromotionsblock].cp
                        }}
                      </div>
                    </div>

                    <!-- PROMOTION -->
                    <div
                      class="
                        row
                        border border-primary border-top-0
                        font-weight-bold
                      "
                    >
                      <div
                        class="
                          col-2
                          border-right border-primary
                          font-weight-bold
                          p-2
                        "
                      >
                        Promotion
                      </div>
                      <div
                        class="
                          col-2
                          border-right border-primary
                          p-2
                          text-center
                        "
                        :class="
                          getPromotionsfarbe(
                            'auflagen',
                            row.item.promotionblocks[aktiverPromotionsblock]
                          )
                        "
                      >
                        {{
                          getPromotion(
                            row.item.promotionblocks[aktiverPromotionsblock]
                          )
                        }}
                      </div>
                      <div class="col-1 border-right border-primary p-2"></div>
                      <div class="col-2 border-right border-primary p-2"></div>
                      <div
                        class="
                          col-2
                          border-right border-primary
                          text-center
                          p-2
                        "
                        :class="
                          getPromotionsfarbe(
                            'promotionsgruppen',
                            row.item.promotionblocks[aktiverPromotionsblock]
                          )
                        "
                      >
                        {{
                          row.item.promotionblocks[aktiverPromotionsblock]
                            .average_grade == 0
                            ? "-"
                            : row.item.promotionblocks[aktiverPromotionsblock]
                                .average_grade
                        }}
                      </div>
                      <div class="col-1 border-right border-primary p-2"></div>
                      <div
                        class="col-2 font-weight-bold text-center p-2"
                        :class="
                          getPromotionsfarbe(
                            'modulgruppen',
                            row.item.promotionblocks[aktiverPromotionsblock]
                          )
                        "
                      >
                        {{
                          row.item.promotionblocks[aktiverPromotionsblock]
                            .cp_passed
                            ? "Bestanden "
                            : "Nicht bestanden "
                        }}
                        <span class="font-italic"
                          >(minimale CP:
                          {{
                            row.item.promotionblocks[aktiverPromotionsblock]
                              .min_cp
                          }})</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" @click="close">
            Schliessen
          </button>
          <button type="button" class="btn btn-success" @click="speichern">
            Alle Änderungen speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
	
  <script>
import { modal } from "@/mixins/Modal";
import Tor from "@/Tor";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "TorAbschlussSimulierenModal",
  components: { BSpinner },
  mixins: [modal],
  data() {
    return {
      sortBy: "lastname",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      styleHeadlineSelected: {
        filter: "none",
      },
      styleHeadline: {
        filter: "brightness(0.9)",
      },
      aktiverPromotionsblock: 0,
      promotionsgruppenFarben: [
        "light-blue",
        "light-pink",
        "light-mint",
        "light-lila",
        "light-green",
        "light-brown",
        "light-orange",
        "light-red",
      ],
      fields: [
        {
          key: "student.number",
          sortable: true,
          searchable: false,
          label: "Personennummer",
        },
        {
          key: "student.lastname",
          sortable: true,
          searchable: false,
          label: "Name",
        },
        {
          key: "student.name",
          sortable: true,
          searchable: false,
          label: "Vorname",
        },
        {
          key: "Promotionsentscheid",
          sortable: true,
          searchable: false,
          label: "Promotionsentscheid",
          formatter: (value, key, item) => {
            return this.getPromotionsentscheid(item);
          },
          tdClass: (value, key, item) => {
            return this.getPromotionsentscheidFarbe(item);
          },
        },
        {
          key: "Notendurchschnitt",
          sortable: true,
          searchable: false,
          label: "Notendurchschnitt",
          formatter: (value, key, item) => {
            return this.getAverageGrade(item);
          },
          tdClass: (value, key, item) => {
            return this.getAverageGradeFarbe(item);
          },
        },
        {
          key: "ErreichteCP",
          sortable: true,
          searchable: false,
          label: "Erreichte CP",
          formatter: (value, key, item) => {
            return this.getCP(item);
          },
          tdClass: (value, key, item) => {
            return this.getCPfarbe(item);
          },
        },
        {
          key: "chevron",
          label: "",
          tdClass: () => {
            return "d-flex justify-content-center";
          },
        },
      ],
      torliste: [],
      promotionsbloecke: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "tor-abschluss-simulieren-modal",
    },
    abschluss: {
      type: Object,
      required: true,
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("openAbschlussSimulierenModal", (ids) => {
      if (ids.modalId == this.id && !this.shown) {
        this.openModal();
      }
    });
  },
  watch: {
    abschluss: function (val) {
      if (val && this.shown) {
        this.getSimulationsdaten();
      }
    },
  },
  computed: {},
  methods: {
    getSimulationsdaten() {
      this.isBusy = true;

      Tor.get("simulation/", { params: { degree: this.abschluss.id } })
        .then((response) => {
          this.simulation = response.data;
          this.torliste = this.simulation.torlist;
          this.promotionsbloecke = this.simulation.promotionblocks;
          this.notenAnzeigeFormatieren();
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    close() {
      this.closeModal();
      this.$router.push({ name: "Startseite" });
    },
    async speichern() {
      this.isBusy = true;

      await this.notenZuZahlenFormatieren();

      Tor.post("simulation/", this.simulation)
        .then((response) => {
          this.simulation = response.data;
          this.torliste = this.simulation.torlist;
          this.promotionsbloecke = this.simulation.promotionblocks;
          this.notenAnzeigeFormatieren();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Noten erfolgreich gesetzt.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    notenAnzeigeFormatieren() {
      //Noten mit Nachkommastellen anzeigen (Konvertiert Number zu String)

      this.torliste.forEach((tor) => {
        tor.promotionblocks.forEach((block) => {
          block.average_grade = block.average_grade.toFixed(1);

          block.promotiongroups.forEach((group) => {
            group.average_grade = group.average_grade.toFixed(1);
            group.subjects.forEach((subject) => {
              subject.tor_grades.forEach((torGrade) => {
                torGrade.tor_grade = torGrade.tor_grade.toFixed(1);
                torGrade.manual_grade = torGrade.manual_grade.toFixed(1);
                torGrade.definitive_grade =
                  torGrade.definitive_grade.toFixed(1);
              });
            });
          });
        });
      });
    },
    notenZuZahlenFormatieren() {
      //Noten von String zu Number konvertieren, damit diese im Backend korrekt verarbeitet werden können
      this.torliste.forEach((tor) => {
        tor.promotionblocks.forEach((block) => {
          block.average_grade = Number(block.average_grade);

          block.promotiongroups.forEach((group) => {
            group.average_grade = Number(group.average_grade);
            group.subjects.forEach((subject) => {
              subject.tor_grades.forEach((torGrade) => {
                torGrade.tor_grade = Number(torGrade.tor_grade);
                torGrade.manual_grade = Number(torGrade.manual_grade);
                torGrade.definitive_grade = Number(torGrade.definitive_grade);
              });
            });
          });
        });
      });
    },
    setPromotionsblock(index) {
      this.aktiverPromotionsblock = index;
    },
    details(item) {
      this.$set(item, "_showDetails", !item._showDetails);
    },
    getPromotionsgruppenfarbe(index) {
      if (this.promotionsgruppenFarben[index + this.aktiverPromotionsblock]) {
        return (
          "bg-" +
          this.promotionsgruppenFarben[index + this.aktiverPromotionsblock]
        );
      }
      return "tile bg-light";
    },
    resetManuelleNote(modul, index) {
      if (modul.tor_grades[index].id) {
        modul.tor_grades[index].manual_grade = null;
        modul.tor_grades[index].definitive_grade =
          modul.tor_grades[index].tor_grade;
        modul.tor_grades[index].input_active = false;
      } else {
        modul.tor_grades = [];
      }
    },
    setTornotenStyle(modul) {
      let bClass = "bg-grey";

      let hoechsteNote = 0;

      if (modul.tor_grades.length == 1) {
        // Wurde die Note manuell angepasst, soll nicht die definitive note genommen werden, sondern die manuelle
        if (
          modul.tor_grades[0].manual_grade &&
          modul.tor_grades[0].manual_grade != 0 &&
          modul.tor_grades[0].manual_grade != "NaN" &&
          modul.tor_grades[0].manual_grade != undefined
        ) {
          hoechsteNote = modul.tor_grades[0].manual_grade;
        } else {
          hoechsteNote = modul.tor_grades[0].definitive_grade;
        }
      } else if (modul.tor_grades.length > 1) {
        let tmp = modul.tor_grades.map(function (grade) {
          // Wurde die Note manuell angepasst, soll nicht die definitive note genommen werden, sondern die manuelle
          if (
            grade.manual_grade &&
            grade.manual_grade != 0 &&
            grade.manual_grade != "NaN" &&
            grade.manual_grade != undefined
          ) {
            return grade.manual_grade;
          } else {
            return grade.definitive_grade;
          }
        });
        hoechsteNote = Math.max.apply(Math, tmp);
      }

      if (
        hoechsteNote != 0 &&
        hoechsteNote != "NaN" &&
        hoechsteNote != undefined
      ) {
        if (hoechsteNote >= 3.8) {
          bClass = "bg-success-light";
        } else {
          bClass = "bg-danger-light";
        }
      }

      let eingabeAktiv = false;

      modul.tor_grades.forEach((grade) => {
        if (grade.input_active == true) {
          eingabeAktiv = true;
        }
      });

      if (modul.tor_grades[0]?.credited == true) {
        bClass = "bg-success-light";
      }

      if (eingabeAktiv) {
        bClass += " p-1";
      } else {
        bClass += " p-2";
      }

      return bClass;
    },
    istHoechsteNote(modul, index) {
      if (modul.tor_grades.length == 1) {
        return true;
      } else if (modul.tor_grades.length > 1) {
        let tmp = modul.tor_grades.map(function (grade) {
          // Wurde die Note manuell angepasst, soll nicht die definitive note genommen werden, sondern die neue, manuelle
          if (grade.definitive_grade >= grade.manual_grade) {
            return grade.definitive_grade;
          } else {
            return grade.manual_grade;
          }
        });

        let maxValue = Math.max.apply(Math, tmp);

        if (tmp.indexOf(maxValue) == index) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    istRepetend(modul) {
      if (modul.tor_grades.length == 1) {
        return false;
      } else {
        return true;
      }
    },
    getTiefsteNote(modul) {
      let tmp = modul.tor_grades.map(function (grade) {
        // Wurde die Note manuell angepasst, soll nicht die definitive note genommen werden, sondern die neue, manuelle
        if (grade.definitive_grade <= grade.manual_grade) {
          return grade.definitive_grade;
        } else {
          return grade.manual_grade;
        }
      });
      let minValue = Math.min.apply(Math, tmp);

      return minValue;
    },
    noteHinzufuegen(modul) {
      modul.tor_grades.push({
        id: null,
        tor_grade: 0,
        subject: {
          id: modul.id,
          short: modul.short,
          designation: modul.designation,
          cp: modul.cp,
          tor_grades: [],
        },
        manual_grade: 0,
        cp: 2,
        passed: false,
        input_active: true,
        definitive_grade: 0,
        credited: false,
      });
    },
    getPromotionsfarbe(typ, promotionsblock) {
      if (typ == "erreichteCP") {
        if (promotionsblock.cp >= promotionsblock.min_cp) {
          return "bg-success-light";
        } else {
          return "bg-danger-light";
        }
      } else if (typ == "auflagen") {
        if (promotionsblock.average_passed && promotionsblock.cp_passed) {
          return "bg-success-light";
        } else if (
          !promotionsblock.average_passed &&
          !promotionsblock.cp_passed
        ) {
          return "bg-danger-light";
        } else if (
          (promotionsblock.average_passed && !promotionsblock.cp_passed) ||
          (!promotionsblock.average_passed && promotionsblock.cp_passed)
        ) {
          return "bg-warning-light";
        }
      } else if (typ == "promotionsgruppen") {
        if (
          !promotionsblock.average_grade ||
          promotionsblock.average_grade == 0 ||
          promotionsblock.average_grade == "Nan" ||
          promotionsblock.average_grade == undefined
        ) {
          return "bg-grey";
        } else if (!promotionsblock.average_passed) {
          return "bg-danger-light";
        } else if (promotionsblock.average_passed) {
          return "bg-success-light";
        }
      } else if (typ == "modulgruppen") {
        if (promotionsblock.cp_passed) {
          return "bg-success-light";
        } else if (!promotionsblock.cp_passed) {
          return "bg-danger-light";
        }
      }
      return "bg-grey";
    },
    berechneTotaMoeglichelCP(promotionsgruppen) {
      let totalCP = 0;

      promotionsgruppen.forEach((gruppe) => {
        gruppe.subjects.forEach((modul) => {
          totalCP += modul.cp;
        });
      });

      return totalCP;
    },
    getPromotion(promotionsblock) {
      if (promotionsblock.average_passed && promotionsblock.cp_passed) {
        return "Weiterstudieren ohne Auflagen";
      } else if (
        !promotionsblock.average_passed &&
        !promotionsblock.cp_passed
      ) {
        return "Nicht weiterstudieren";
      } else if (
        (promotionsblock.average_passed && !promotionsblock.cp_passed) ||
        (!promotionsblock.average_passed && promotionsblock.cp_passed)
      ) {
        return "Weiterstudieren mit Auflagen";
      } else {
        return "-";
      }
    },

    anzahlStudentenproBlock(blockID) {
      let count = 0;

      this.torliste.filter((tor) => {
        count += tor.promotionblocks.filter((block) => {
          if (block.id == blockID) {
            return true;
          }
          return false;
        })?.length;
      });

      return count;
    },

    getPromotionsentscheid(item) {
      let promotion = item.promotionblocks[this.aktiverPromotionsblock];

      if (promotion.cp_passed && promotion.average_passed) {
        return "Weiterstudieren ohne Auflagen";
      } else if (!promotion.cp_passed && !promotion.average_passed) {
        return "Nicht weiterstudieren";
      } else if (
        (promotion.cp_passed && !promotion.average_passed) ||
        (!promotion.cp_passed && promotion.average_passed)
      ) {
        return "Weiterstudieren mit Auflagen";
      }
    },

    getPromotionsentscheidFarbe(item) {
      let promotion = item.promotionblocks[this.aktiverPromotionsblock];

      if (promotion.cp_passed && promotion.average_passed) {
        return "bg-success-light";
      } else if (!promotion.cp_passed && !promotion.average_passed) {
        return "bg-danger-light";
      } else if (
        (promotion.cp_passed && !promotion.average_passed) ||
        (!promotion.cp_passed && promotion.average_passed)
      ) {
        return "bg-warning-light";
      }
    },

    getCP(item) {
      return item.promotionblocks[this.aktiverPromotionsblock].cp;
    },

    getCPfarbe(item) {
      let promotion = item.promotionblocks[this.aktiverPromotionsblock];

      if (promotion.cp >= promotion.min_cp) {
        return "bg-success-light";
      } else {
        return "bg-danger-light";
      }
    },

    getAverageGrade(item) {
      return item.promotionblocks[this.aktiverPromotionsblock].average_grade;
    },

    getAverageGradeFarbe(item) {
      let promotion = item.promotionblocks[this.aktiverPromotionsblock];

      if (promotion.average_grade >= 3.8) {
        return "bg-success-light";
      } else {
        return "bg-danger-light";
      }
    },
  },
};
</script>
	
	<style>
</style>