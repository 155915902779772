import Dashboard from "@/pages/Einstellungen/Dashboard";
import Perioden from "@/pages/Einstellungen/Perioden";
import Faecher from "@/pages/Einstellungen/Faecher";
import Sperrtage from "@/pages/Einstellungen/Sperrtage";
import KompetenzrasterListe from "@/pages/Einstellungen/Kompetenzrasterliste";
import Kompetenzraster from "@/pages/Einstellungen/Kompetenzraster";
import EinstellungenAnmeldungen from "@/pages/Einstellungen/Liste/EinstellungenAnmeldungen";
import EinstellungenArtikel from "@/pages/Einstellungen/Liste/EinstellungenArtikel";
import EinstellungenAuftrag from "@/pages/Einstellungen/Liste/EinstellungenAuftrag";
import EinstellungenBelege from "@/pages/Einstellungen/Liste/EinstellungenBelege";
import EinstellungenCRM from "@/pages/Einstellungen/Liste/EinstellungenCRM";
import EinstellungenDebitor from "@/pages/Einstellungen/Liste/EinstellungenDebitor";
import EinstellungenDozent from "@/pages/Einstellungen/Liste/EinstellungenDozent";
import EinstellungenGeschaeftspartner from "@/pages/Einstellungen/Liste/EinstellungenGeschaeftspartner";
import EinstellungenJournal from "@/pages/Einstellungen/Liste/EinstellungenJournal";
import EinstellungenKorrespondenz from "@/pages/Einstellungen/Liste/EinstellungenKorrespondenz";
import EinstellungenLohn from "@/pages/Einstellungen/Liste/EinstellungenLohn";
import EinstellungenNoten from "@/pages/Einstellungen/Liste/EinstellungenNoten";
import EinstellungenNoteneingabe from "@/pages/Einstellungen/Liste/EinstellungenNoteneingabe";
import EinstellungenPraesenz from "@/pages/Einstellungen/Liste/EinstellungenPraesenz";
import EinstellungenRessourcen from "@/pages/Einstellungen/Liste/EinstellungenRessourcen";
import EinstellungenSubventionen from "@/pages/Einstellungen/Liste/EinstellungenSubventionen";
import EinstellungenSubventionsbeitraege from "@/pages/Einstellungen/Liste/EinstellungenSubventionsbeitraege";
import EinstellungenVeranstaltungen from "@/pages/Einstellungen/Liste/EinstellungenVeranstaltungen";
import EinstellungenZahlungen from "@/pages/Einstellungen/Liste/EinstellungenZahlungen";




export default [
  {
    path: "/Einstellungen/Dashboard",
    name: "einstellungen-dashboard",
    component: Dashboard,
  },
  {
    path: "/Einstellungen/Perioden",
    name: "einstellungen-perioden",
    component: Perioden,
  },

  {
    path: "/Einstellungen/Faecher",
    name: "einstellungen-faecher",
    component: Faecher,
  },
  {
    path: "/Einstellungen/Sperrtage",
    name: "einstellungen-sperrtage",
    component: Sperrtage,
  },
  {
    path: "/Einstellungen/Kompetenzrasterliste",
    name: "einstellungen-kompetenzrasterliste",
    component: KompetenzrasterListe,
  },
  {
    path: "/Einstellungen/Anmeldungen",
    name: "einstellungen-anmeldungen",
    component: EinstellungenAnmeldungen
  },
  {
    path: "/Einstellungen/Artikel",
    name: "einstellungen-artikel",
    component: EinstellungenArtikel
  },
  {
    path: "/Einstellungen/Auftrag",
    name: "einstellungen-auftrag",
    component: EinstellungenAuftrag
  },
  {
    path: "/Einstellungen/Belege",
    name: "einstellungen-belege",
    component: EinstellungenBelege
  },
  {
    path: "/Einstellungen/CRM",
    name: "einstellungen-crm",
    component: EinstellungenCRM
  },
  {
    path: "/Einstellungen/Debitor",
    name: "einstellungen-debitor",
    component: EinstellungenDebitor
  },
  {
    path: "/Einstellungen/Dozent",
    name: "einstellungen-dozent",
    component: EinstellungenDozent
  },
  {
    path: "/Einstellungen/Geschaeftspartner",
    name: "einstellungen-geschaeftspartner",
    component: EinstellungenGeschaeftspartner
  },
  {
    path: "/Einstellungen/Journal",
    name: "einstellungen-journal",
    component: EinstellungenJournal
  },
  {
    path: "/Einstellungen/Korrespondenz",
    name: "einstellungen-korrespondenz",
    component: EinstellungenKorrespondenz
  },
  {
    path: "/Einstellungen/Lohn",
    name: "einstellungen-lohn",
    component: EinstellungenLohn
  },
  {
    path: "/Einstellungen/Noten",
    name: "einstellungen-noten",
    component: EinstellungenNoten
  },
  {
    path: "/Einstellungen/Noteneingabe",
    name: "einstellungen-noteneingabe",
    component: EinstellungenNoteneingabe
  },
  {
    path: "/Einstellungen/Praesenz",
    name: "einstellungen-praesenz",
    component: EinstellungenPraesenz
  },
  {
    path: "/Einstellungen/Ressourcen",
    name: "einstellungen-ressourcen",
    component: EinstellungenRessourcen
  },
  {
    path: "/Einstellungen/Subventionen",
    name: "einstellungen-subventionen",
    component: EinstellungenSubventionen
  },
  {
    path: "/Einstellungen/Subventionsbeitraege",
    name: "einstellungen-subventionsbeitraege",
    component: EinstellungenSubventionsbeitraege
  },
  {
    path: "/Einstellungen/Veranstaltungen",
    name: "einstellungen-veranstaltungen",
    component: EinstellungenVeranstaltungen
  },
  {
    path: "/Einstellungen/Zahlungen",
    name: "einstellungen-zahlungen",
    component: EinstellungenZahlungen
  },
  {
    path: "/Einstellungen/Kompetenzraster/:id?/:anzeige",
    name: "einstellungen-kompetenzeinstellungen-crmraster",
    component: Kompetenzraster,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
];
