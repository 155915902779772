<template>
  <div class="col-xl-12 pr-4">
    <!--Start PERSON -->
    <container-headline
      :headline="
        $t('global.receiver') + ' (' + korrespondenz.person.length + ')'
      "
      :col="3"
    >
      <div class="row mt-2 d-flex">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneGPSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2">
          <button class="btn btn-danger" @click="oeffneLoeschenModal">
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>
      </div>
    </container-headline>
    <div
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="receiverKey"
    >
      <div
        v-for="(item, index) in korrespondenz.person"
        v-bind:key="`empfaenger-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @click="selectReceiver(item)"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
          class="p-1"
        >
          {{ item.personName }}
        </div>
      </div>
    </div>
    <!--ENDE PERSON -->
    <!--Start FIRMA -->
    <container-headline
      :headline="$t('global.company') + ' (' + korrespondenz.firma.length + ')'"
      :col="3"
    >
      <div class="row mt-2 d-flex">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneFirmaSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2">
          <button class="btn btn-danger" @click="oeffneFirmaLoeschenModal">
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>
      </div>
    </container-headline>
    <div
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="firmaKey"
    >
      <div
        v-for="(item, index) in korrespondenz.firma"
        v-bind:key="`empfaenger-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @click="selectReceiverFirma(item)"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
          class="p-1"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!--ENDE FIRMA -->
    <container-headline :headline="$t('global.sender')" :col="3" />
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <single-select-person
                id="ersteller"
                :person="korrespondenz.versand.erstelltvon"
                :label="$t('global.creator')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                @confirmed="setErsteller"
              />
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <v-select
                  v-model="korrespondenz.verarbeitungsstatus"
                  label="bezeichnung"
                  :options="verarbeitungsstati"
                  :disabled="!editable"
                  :placeholder="$t('global.processingstatus')"
                  :reduce="(vs) => vs.id"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.processingstatus") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gpSucheModal :id="modalId" @confirmed="onGPResult"></gpSucheModal>
    <loeschen-modal
      id="empfaenger-loeschen-modal"
      @confirmed="personLoeschen"
      :multiple="true"
    />

    <firmaSucheModal
      :id="firmaModalId"
      @confirmed="onFirmaResult"
    ></firmaSucheModal>
    <loeschen-modal
      id="firma-loeschen-modal"
      @confirmed="firmaLoeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import firmaSucheModal from "@/components/Geschaeftspartner/firmaSucheModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";
import { v4 as uuid } from "uuid";

import store from "@/store";

export default {
  name: "MassenKorrespondenzEmpfaengerAbsender",
  components: {
    ContainerHeadline,
    gpSucheModal,
    SingleSelectPerson,
    LoeschenModal,
    firmaSucheModal,
  },
  mixins: [],
  store,
  props: {
    korrespondenz: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      sortBy: "",
      sortDesc: false,
      selected: [],
      selectedIds: [],
      modalId: "PersonHinzufuegen",
      firmaModalId: "FirmaHinzufuegen",
      receiverKey: uuid(),
      firmaKey: uuid(),

      fields: [
        { key: "selected", label: "" },
        { key: "personName", sortable: true, label: this.$t("global.person") },
        {
          key: "adressen[0].adresseKomplett",
          sortable: true,
          label: this.$t("global.address"),
        },
      ],
    };
  },
  computed: {
    verarbeitungsstati: {
      get() {
        return this.$store.state.korrespondenz.verarbeitungsstati;
      },
    },
  },

  watch: {},

  created() {},

  mounted() {
    if (this.verarbeitungsstati.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    selectReceiver(receiver) {
      let realItem = this.korrespondenz.person.find(
        (item) => item.id == receiver.id
      );
      realItem.selected = receiver.selected ? false : true;

      this.selectedIds = this.korrespondenz.person
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.receiverKey = uuid();

      this.$emit("updated", this.selectedIds);
    },

    selectReceiverFirma(receiver) {
      let realItem = this.korrespondenz.firma.find(
        (item) => item.id == receiver.id
      );
      realItem.selected = receiver.selected ? false : true;

      this.selectedIds = this.korrespondenz.firma
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.firmaKey = uuid();

      this.$emit("updated", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "empfaenger-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noreceiverselected"),
        });
      }
    },

    oeffneFirmaLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "firma-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noreceiverselected"),
        });
      }
    },

    personLoeschen() {
      this.selectedIds.forEach((id) => {
        this.korrespondenz.person = this.korrespondenz.person.filter(
          (person) => person.id != id
        );
      });

      this.receiverKey = uuid();
    },

    firmaLoeschen() {
      this.selectedIds.forEach((id) => {
        this.korrespondenz.firma = this.korrespondenz.firma.filter(
          (firma) => firma.id != id
        );
      });

      this.firmaKey = uuid();
    },

    oeffneGPSucheModal() {
      this.$bus.$emit("openGeschaeftspartnerModal", this.modalId);
    },

    oeffneFirmaSucheModal() {
      this.$bus.$emit("openFirmaModal", this.firmaModalId);
    },

    onGPResult(selectedPerson) {
      if (selectedPerson) {
        this.korrespondenz.person.push(selectedPerson[0]);

        this.korrespondenz.person.sort((a, b) => {
          return a.personName > b.personName;
        });

        this.receiverKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("notification.receiversuccessfullyadded"),
        });
      }
    },

    onFirmaResult(selectedFirma) {
      if (selectedFirma) {
        this.korrespondenz.firma.push(selectedFirma[0]);

        this.korrespondenz.firma.sort((a, b) => {
          return a.name > b.name;
        });

        this.firmaKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("notification.receiversuccessfullyadded"),
        });
      }
    },

    setErsteller(person) {
      this.korrespondenz.versand.erstelltvon = person;
      /*this.$emit("updated");*/
    },
  },
};
</script>

<style>
</style>
