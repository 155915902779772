var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4"},[_c('container-headline',{attrs:{"headline":_vm.$t('global.receiver') + ' (' + _vm.korrespondenz.person.length + ')',"col":3}},[_c('div',{staticClass:"row mt-2 d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneGPSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1)])])]),_c('div',{key:_vm.receiverKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.korrespondenz.person),function(item,index){return _c('div',{key:("empfaenger-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"click":function($event){return _vm.selectReceiver(item)}}},[_vm._v(" "+_vm._s(item.personName)+" ")])])}),0),_c('container-headline',{attrs:{"headline":_vm.$t('global.company') + ' (' + _vm.korrespondenz.firma.length + ')',"col":3}},[_c('div',{staticClass:"row mt-2 d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneFirmaSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneFirmaLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1)])])]),_c('div',{key:_vm.firmaKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.korrespondenz.firma),function(item,index){return _c('div',{key:("empfaenger-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"click":function($event){return _vm.selectReceiverFirma(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('container-headline',{attrs:{"headline":_vm.$t('global.sender'),"col":3}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"},[_c('single-select-person',{attrs:{"id":"ersteller","person":_vm.korrespondenz.versand.erstelltvon,"label":_vm.$t('global.creator'),"allowOpen":_vm.berechtigungen.m_geschaeftspartner.read,"editable":_vm.editable,"required":false},on:{"confirmed":_vm.setErsteller}})],1),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.verarbeitungsstati,"disabled":!_vm.editable,"placeholder":_vm.$t('global.processingstatus'),"reduce":function (vs) { return vs.id; }},model:{value:(_vm.korrespondenz.verarbeitungsstatus),callback:function ($$v) {_vm.$set(_vm.korrespondenz, "verarbeitungsstatus", $$v)},expression:"korrespondenz.verarbeitungsstatus"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("notification.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.processingstatus")))])],1)])])])])]),_c('gpSucheModal',{attrs:{"id":_vm.modalId},on:{"confirmed":_vm.onGPResult}}),_c('loeschen-modal',{attrs:{"id":"empfaenger-loeschen-modal","multiple":true},on:{"confirmed":_vm.personLoeschen}}),_c('firmaSucheModal',{attrs:{"id":_vm.firmaModalId},on:{"confirmed":_vm.onFirmaResult}}),_c('loeschen-modal',{attrs:{"id":"firma-loeschen-modal","multiple":true},on:{"confirmed":_vm.firmaLoeschen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }