<template>
  <div>
    <container-headline
      :headline="$t('global.companydetails')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <!-- Felder für Gesch#ftspartner vom Typ Firma -->
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="firma.name"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="1"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.name")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-if="auswahlwerte.firma_partnerrollen"
                            v-model="firma.rolle"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="auswahlwerte.firma_partnerrollen"
                            :disabled="!editable"
                            placeholder="Typ"
                            :reduce="(r) => r.id"
                            multiple
                            :tabindex="2"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("dashboard.type")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="firma.branche"
                          label="bezeichnung"
                          :options="auswahlwerte.branchen"
                          :reduce="(s) => s.id"
                          :disabled="!editable && !isAdmin"
                          :placeholder="$t('dashboard.industry')"
                          :tabindex="3"
                        >
                          <span slot="no-options">{{
                            $t("dashboard.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("dashboard.industry") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="firma.aktivitaetsstatus"
                          label="bezeichnung"
                          :options="auswahlwerte.aktivitaetsstati"
                          :reduce="(s) => s.id"
                          :disabled="!editable && !isAdmin"
                          :placeholder="$t('global.status')"
                          :tabindex="4"
                        >
                          <span slot="no-options">{{
                            $t("dashboard.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.status") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ende Felder für Gesch#ftspartner vom Typ Firma -->
                <!--<div class="col-xl-2">
                  <div class="form-group d-flex" style="flex-wrap: wrap">
                    <label>{{ $t("global.profilepicture") }}</label>

                    <button
                      class="btn btn-success btn-sm"
                      @click="oeffneDokumentModal"
                      v-if="!firma.bildUrl && editable"
                      style="width: 60px; height: 30px"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-1"
                      />
                      <span>{{ $t("global.image") }}</span>
                    </button>

                    <button
                      v-if="firma.bild"
                      class="btn btn-danger btn-sm"
                      @click="fotoLoeschen"
                      style="width: 30px; height: 30px; margin-left: auto"
                    >
                      <font-awesome-icon icon="fa-solid fa-ban" size="sm" />
                    </button>

                    <img
                      v-if="firma.bild && firma.bildUrl"
                      class="profilbild mt-2"
                      style="
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        object-fit: contain;
                        object-position: center;
                      "
                      :src="firma.bildUrl"
                    />
                  </div>
                </div>

                <bild-modal
                  :gid="this.id"
                  id="bild-modal"
                  @bildUploaded="ladeGeschaeftspartner"
                ></bild-modal>
                -->
                <!--
                <div class="col-xl-2">
                  <div :key="key">
                    <div
                      v-if="!person.bild && editable"
                      class="form-group text-center"
                    >
                      <vue-2-dropzone
                        id="dropzone"
                        ref="dokumenteUpload"
                        class="dropzone-white dropzone-small"
                        :options="dropzoneOptions"
                        :disabled="!editable"
                        @vdropzone-success="fileUploaded"
                      ></vue-2-dropzone>
                      <button
                        v-if="this.id"
                        class="btn btn-primary"
                        @click="uploadFiles"
                      >
                        Upload
                      </button>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div v-if="person.bild">
                          <button class="btn btn-primary btn-sm">
                            <span
                              class="editIcon"
                              @click="setVisibility(isHidden)"
                            >
                              <font-awesome-icon icon="fa-duotone fa-pencil-alt" size="lg"/>
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div v-if="!isHidden">
                          <button
                            class="btn btn-danger btn-sm"
                            @click="fotoLoeschen"
                          >
                            <span class="editIcon">
                             <font-awesome-icon icon="fa-duotone fa-trash" size="lg"/></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <img
                      v-if="person.bild"
                      class="profilbild"
                      style="width: 100%; height: auto"
                      :src="person.bildUrl"
                    />
                    <div v-if="!isHidden" class="form-group text-center">
                      <vue-2-dropzone
                        id="dropzone"
                        ref="dokumenteUpload"
                        class="dropzone-white dropzone-small"
                        :options="dropzoneOptions"
                        @vdropzone-success="fileUploaded"
                      ></vue-2-dropzone>
                      <button class="btn btn-primary" @click="uploadFiles">
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import vue2Dropzone from "vue2-dropzone";
import { dropzoneOptionsBild } from "@/config/Dropzone";
import ContainerHeadline from "@/components/ContainerHeadline";
//import BildModal from "@/components/Geschaeftspartner/BildModal";

import Api from "@/Api";

export default {
  name: "Firmenangaben",
  components: {
    //vue2Dropzone,
    ContainerHeadline,
    //BildModal,
  },
  mixins: [],
  props: {
    id: {
      type: String,
      required: false,
    },
    firma: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {
        return {};
      },
    },
    auswahlwerte: {
      type: Object,
    },
  },
  data() {
    return {
      dropzoneOptions: dropzoneOptionsBild(Api.defaults.baseURL, this.id),
      isHidden: true,
      key: 0,
      bildKey: 0,
      isAdmin: false,
      firmenListe: [],
      relationstypen: [],
    };
  },
  computed: {},
  created() {},
  watch: {
    ["firma.rolle"]: {
      handler: function (val) {
        if (val && this.auswahlwerte.firma_partnerrollen.length > 0) {
          this.$emit("rolesChanged", val);
        }
      },
      immediate: true,
    },
  },
  mounted: function () {
    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    ladeGeschaeftspartner() {
      this.$emit("bildUpdated", this.id);
    },

    oeffneDokumentModal() {
      $("#bild-modal").modal("show");
    },

    fotoLoeschen() {
      Api.delete("bildupload/", { params: { id: this.firma.id } }).then(() => {
        this.$emit("bildUpdated");
      });
    },
  },
};
</script>

<style>
</style>

